import { Middleware } from 'redux';
import { ActionTypes } from 'app/redux/actionTypes';
import { retryRecordingUpload, toggleEditScreenshot } from 'app/redux/actions';
import { getActiveRecording } from 'app/redux/reducers/activeRecording';
import { StoreModel, UploadingModel, UploadingStatus } from 'app/models/StoreModel';
import { getRecordingMimeType } from 'app/redux/reducers/recordingSelector';
import { get } from 'lodash';
import { Recorder } from 'app/utils/asyncActions';

/**
 * Intercept recording complete and initiate storage file upload
 */
export const recordCompleteInterceptor: Middleware = (store) => (next) => (action) => {
  const { dispatch, getState } = store;
  const state = getState();
  const activeRecording = getActiveRecording(state);

  const isVideoRecordingDone = action.type === ActionTypes.STOP_RECORD.COMPLETED;
  const isScreenShotDone = action.type === ActionTypes.TAKE_SCREENSHOT.COMPLETED;
  const isCanceledRecording = action.type === ActionTypes.CANCEL_RECORD.STARTED;

  /** 1. Recording canceled */
  if (isCanceledRecording) {
    // reset active recordings
    dispatch({
      type: ActionTypes.RESET_ACTIVE_RECORDING
    });
    next(action);
    return;
  }

  /** 2. Recording upload retry clicked */
  const retryUpload = action.type === ActionTypes.RETRY_UPLOAD;
  if (retryUpload) {
    const { dispatch } = store;

    dispatch(retryRecordingUpload(action.payload.data.id) as any);
    return;
  }

  /** 3. Snapshot editing finished */
  const justFinishedEditingTheScreenshot =
    action.type === ActionTypes.UPDATE_SCREENSHOT && !action.payload.data.show;
  if (justFinishedEditingTheScreenshot) {
    next(action);
    // trigger the actual file upload
    dispatch(retryRecordingUpload(activeRecording.id) as any);
    return;
  }

  /** 4. Nothing related: skip! */
  if (!isVideoRecordingDone && !isScreenShotDone) {
    next(action);
    return;
  }

  /** 5. Snapshot taken */
  if (isScreenShotDone) {
    const thumbnail: any = get(action, 'payload.data.snapshot');
    dispatch(toggleEditScreenshot(true, thumbnail));
    return;
  }

  // trigger the actual file upload
  dispatch(retryRecordingUpload(activeRecording.id) as any);
  next(action);
};

export async function createUpdateFromActiveRecording(
  state: StoreModel,
  dispatch: Function,
):Promise< UploadingModel | null> {

  const activeRecording = getActiveRecording(state);

  if (!activeRecording.id) {
    return null;
  }

  console.time('GetThumbnail');
  const { thumbnail }: any = await Recorder.IndexedDB.getHandlerData(activeRecording.id);
  console.timeEnd('GetThumbnail');

  const uploading = {
    url: '',
    size: 0,
    name: activeRecording.name,
    id: activeRecording.id,
    progress: 0,
    status: UploadingStatus.PENDING,
    error: null,
    thumbnail: get(activeRecording, 'thumbnail') || thumbnail,
    duration: activeRecording.time,
    mimeType: getRecordingMimeType(state),
    createdAt: new Date().toUTCString(),
    meta: { ...activeRecording }
  };
  console.time('UpdateHandlerUploading');
  Recorder.IndexedDB.updateHandler(activeRecording.id, uploading);
  console.timeEnd('UpdateHandlerUploading');

  // first create the upload request
  dispatch({
    type: ActionTypes.UPLOAD_RECORDING,
    payload: {
      data: {
        uploading,
      }
    }
  });

  // reset active recordings
  dispatch({
    type: ActionTypes.RESET_ACTIVE_RECORDING
  });

  return uploading;
}
