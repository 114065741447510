import * as React from 'react';
import { connect } from 'react-redux';
import ReactGA from 'react-ga';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import {
  googleRefreshAccessToken,
  toggleOfflineMode,
  getNumberOfDisplays,
  getLocalServerPort,
} from 'app/redux/actions';
import { getClientId } from 'app/redux/reducers/googleSignin';
import TitleBar from 'app/components/organisms/TitleBar';
import { getGoogleAnalyticsId, isWebBuild } from 'app/config/index';
import EVENTS from 'app/config/tracking';

interface IMapStateToProps {}

interface IMapDispatchToProps {
  googleRefreshAccessToken?: () => void;
  toggleOfflineMode?: (mode: boolean) => void;
  getNumberOfDisplays?: () => void;
  getLocalServerPort?: Function;
}
interface IOwnProps {
  clientId?: string;
}

type Props = IMapStateToProps & IMapDispatchToProps & IOwnProps & RouteComponentProps;

@connect<IMapStateToProps, IMapDispatchToProps, IOwnProps>(
  (state) => ({
    clientId: getClientId(state)
  }),
  (dispatch) => ({
    getNumberOfDisplays: () => dispatch(getNumberOfDisplays()),
    toggleOfflineMode: (mode: boolean) => dispatch(toggleOfflineMode(mode)),
    googleRefreshAccessToken: () => dispatch(googleRefreshAccessToken()),
    getLocalServerPort: () => dispatch(getLocalServerPort()),
  })
)
class RefreshAccessToken extends React.Component<Props, {}> {
  componentWillMount() {
    const {
      googleRefreshAccessToken = () => {},
      toggleOfflineMode = () => {},
      clientId = 'unknown!'
    } = this.props;
    const isOffline = !navigator.onLine;
    if (!isOffline) {
      googleRefreshAccessToken();
    }

    toggleOfflineMode(isOffline);
    window.addEventListener('online', () => toggleOfflineMode(false));
    window.addEventListener('offline', () => toggleOfflineMode(true));

    const isDevMode = process.env.NODE_ENV !== 'production';
    if (isDevMode) {
      return; // skip analytics if in dev mode
    }

    // initialize google analytics
    ReactGA.initialize(getGoogleAnalyticsId(), {
      gaOptions: {
        userId: clientId,
        cookieDomain: 'auto'
      }
    });

    // fire application open event
    ReactGA.event({
      category: EVENTS.CATEGORIES.APPLICATION,
      action: EVENTS.ACTIONS.OPEN
      // nonInteraction: true
    });
  }

  render() {
    const { children } = this.props;
    return (
      <React.Fragment>
        {!isWebBuild && <TitleBar />}
        {children}
      </React.Fragment>
    );
  }
}

export default withRouter(RefreshAccessToken);
