import * as React from 'react';
import { connect } from 'react-redux';
import { getRecordingsField } from 'app/redux/reducers/recordings';
import { isRecordingNow } from 'app/redux/reducers/activeRecording';

import { lightTheme, darkTheme, ThemeProvider as SCThemeProvider } from 'app/utils/theme';
import { GlobalStyle } from 'app/utils/globalStyles';

interface ThemeProviderProps {
  children: JSX.Element;
  isLightMode?: boolean;
  isRecordingNow?: boolean;
}

const ThemeProvider = ({ children, isLightMode, isRecordingNow }: ThemeProviderProps) => {
  return (
    <SCThemeProvider theme={isLightMode ? lightTheme : darkTheme}>
      <React.Fragment>
        {children}
        <GlobalStyle isRecordingNow={isRecordingNow} />
      </React.Fragment>
    </SCThemeProvider>
  );
};

export const ConnectedThemeProvider = connect((state) => ({
  isLightMode: getRecordingsField('isLightMode', state),
  isRecordingNow: isRecordingNow(state)
}))(ThemeProvider);

export default ConnectedThemeProvider;
