import * as React from 'react';
import styled from 'app/utils/theme';

interface TextAreaProps {
  value?: string;
  onChange?: Function;
  placeholder?: string;
}

const TextArea = ({ value, placeholder, onChange = () => {} }: TextAreaProps) => (
  <TextArea.Wrapper>
    <textarea
      value={value}
      placeholder={placeholder}
      onChange={(e: any) => onChange(e.currentTarget.value)}
    />
  </TextArea.Wrapper>
);

TextArea.Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  textarea,
  p {
    width: 100%;
    height: 100%;
  }
  textarea {
    background: ${(props) => props.theme.dropdown.backgroundColor};
    border: 1px solid ${(props) => props.theme.dropdown.borderColor};
    box-sizing: border-box;
    border-radius: 4px;
    padding: 15px;

    overflow: auto;
    outline: none;

    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;

    resize: none; /*remove the resize handle on the bottom right*/
    color: ${(props) => props.theme.text.colors.active};

    ::placeholder {
      color: rgba(191, 191, 191, 0.87);
    }
  }
`;

export default TextArea;
