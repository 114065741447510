import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import ReduxToastr from 'react-redux-toastr';

import { ConnectedThemeProvider } from 'app/components/organisms/ThemeProvider';
import { configureStore } from 'app/store';
import { Router } from 'react-router';
import { App } from './app';
import { createUpdateFromActiveRecording } from 'app/middleware/recordCompleteInterceptor';

const history = createBrowserHistory();
const { store } = configureStore();

recoverDeadActiveRecording(store);

ReactDOM.render(
  <Provider store={store}>
    <ConnectedThemeProvider>
      <React.Fragment>
        <ReduxToastr
          timeOut={4000}
          newestOnTop={false}
          preventDuplicates
          position="top-center"
          transitionIn="fadeIn"
          transitionOut="fadeOut"
          progressBar
        />
        <Router history={history}>
          <App />
        </Router>
      </React.Fragment>
    </ConnectedThemeProvider>
  </Provider>,
  document.getElementById('root')
);

function recoverDeadActiveRecording(store: any) {
  const { getState, dispatch } = store;

  setTimeout(() => {
    createUpdateFromActiveRecording(getState(), dispatch);
  }, 5000);
}
