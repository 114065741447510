import { css } from 'app/utils/theme';

const fonts = {
  bold: require('../../assets/fonts/montserrat/montserrat-bold-webfont.woff'),
  bold2: require('../../assets/fonts/montserrat/montserrat-bold-webfont.woff2'),
  extrabold: require('../../assets/fonts/montserrat/montserrat-extrabold-webfont.woff'),
  extrabold2: require('../../assets/fonts/montserrat/montserrat-extrabold-webfont.woff2'),
  extralight: require('../../assets/fonts/montserrat/montserrat-extralight-webfont.woff'),
  extralight2: require('../../assets/fonts/montserrat/montserrat-extralight-webfont.woff2'),
  italic: require('../../assets/fonts/montserrat/montserrat-italic-webfont.woff'),
  italic2: require('../../assets/fonts/montserrat/montserrat-italic-webfont.woff2'),
  light: require('../../assets/fonts/montserrat/montserrat-light-webfont.woff'),
  light2: require('../../assets/fonts/montserrat/montserrat-light-webfont.woff2'),
  lightitalic: require('../../assets/fonts/montserrat/montserrat-lightitalic-webfont.woff'),
  lightitalic2: require('../../assets/fonts/montserrat/montserrat-lightitalic-webfont.woff2'),
  medium: require('../../assets/fonts/montserrat/montserrat-medium-webfont.woff'),
  medium2: require('../../assets/fonts/montserrat/montserrat-medium-webfont.woff2'),
  mediumitalic: require('../../assets/fonts/montserrat/montserrat-mediumitalic-webfont.woff'),
  mediumitalic2: require('../../assets/fonts/montserrat/montserrat-mediumitalic-webfont.woff2'),
  regular: require('../../assets/fonts/montserrat/montserrat-regular-webfont.woff'),
  regular2: require('../../assets/fonts/montserrat/montserrat-regular-webfont.woff2'),
  semibold: require('../../assets/fonts/montserrat/montserrat-semibold-webfont.woff'),
  semibold2: require('../../assets/fonts/montserrat/montserrat-semibold-webfont.woff2'),
  thinitalic: require('../../assets/fonts/montserrat/montserrat-thinitalic-webfont.woff'),
  thinitalic2: require('../../assets/fonts/montserrat/montserrat-thinitalic-webfont.woff2'),
  thin: require('../../assets/fonts/montserrat/montserrat-thin-webfont.woff'),
  thin2: require('../../assets/fonts/montserrat/montserrat-thin-webfont.woff2')
};

export default css`
  ${Object.keys(fonts)
    .map(
      (type) => `
    @font-face {
    font-family: 'montserrat${type}';
    src: url(${fonts[type]})
        format('woff2'),
      url(${fonts[`${type}2`]}) format('woff');
    font-weight: normal;
    font-style: normal;
  }
  `
    )
    .join(' ')}
`;
