import { ActionTypes } from 'app/redux/actionTypes/index';
import get from 'lodash/get';
import { Dispatch } from 'react';

export * from './startRecordingUpload';

interface PayloadModel {
  request?: {
    url: string;
    data?: Object;
    body?: Object;
    method?: string;
    headers?: Object;
    withCredentials?: boolean;
    crossdomain?: boolean;
  };
  client?: string;
}

interface ActionModel {
  type: string | Object;
  payload?: PayloadModel;
  data?: any;
  error?: any;
}

export const googleSignOut = () => (dispatch: Dispatch<any>) => {
  setTimeout(() => {
    const gapi = get(window, 'gapi', {});
    gapi.auth2.getAuthInstance().signOut(() => console.log('User has signed out'));
    location.href = `${location.origin}/login`;
  }, 1000);

  dispatch({ type: ActionTypes.GOOGLE_SIGN_OUT });
};

export const googleSignIn = ({ error, details, code, redirectUri }: any): ActionModel => {
  if (error) {
    return {
      type: `${ActionTypes.GOOGLE_SIGN_IN}_FAIL`,
      error: {
        error,
        details
      }
    };
  }

  return {
    type: ActionTypes.GOOGLE_SIGN_IN,
    payload: {
      client: 'api',
      request: {
        url: '/auth/google/code',
        method: 'POST',
        data: { code, redirectUri }
      }
    },
    data: {
      code: code
    }
  };
};

export const getUserProfile = () => ({
  type: ActionTypes.GET_USER_PROFILE,
  payload: {
    request: { method: 'GET', url: `oauth2/v1/userinfo?alt=json` }
  }
});

export const unsubscribeRecording = (fileId: string) => async (dispatch: any) => {
  // get user about
  // const userAbout = await dispatch({
  //   type: 'UNKNOWN',
  //   payload: {
  //     request: { method: 'GET', url: `/drive/v3/about?fields=*` }
  //   }
  // });

  // const permissionId = get(userAbout, 'payload.data.user.permissionId');
  // const filePermision = await dispatch({
  //   type: 'UNKNOWN',
  //   payload: {
  //     request: {
  //       method: 'GET',
  //       url: `/drive/v3/files/${fileId}/permissions/${permissionId}`
  //     }
  //   }
  // });

  // const filePermisionId = get(filePermision, 'payload.data.id');
  // return dispatch({
  //   type: ActionTypes.REMOVE_RECORDING,
  //   payload: {
  //     request: {
  //       method: 'DELETE',
  //       url: `/drive/v2/files/${fileId}/permissions/${filePermisionId}`
  //     },
  //     data: {
  //       fileId
  //     }
  //   }
  // });

  return dispatch({
    type: ActionTypes.UNSUBSCRIBE_RECORDING,
    payload: {
      client: 'api',
      request: {
        method: 'PUT',
        url: `/files/${fileId}/unsubscribe`
      },
      data: {
        fileId
      }
    }
  });
};

