import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Accordion, Icon } from 'semantic-ui-react';
import groupBy from 'lodash/groupBy';
import debounce from 'lodash/debounce';
import { toastr } from 'react-redux-toastr';
import Logo from 'app/components/molecules/Logo';

import { PRODUCT_NAME, SELECTED_RECORDING_OPTIONS } from 'app/config';

import Text from 'app/components/atoms/Text';

import WithInfiniteScroll from 'app/components/atoms/WithInfiniteScroll';
import ActionButtons from 'app/components/organisms/ActionButtons';
import SearchInput from 'app/components/molecules/SearchInput';
import NavBar, { NavBarOption } from 'app/components/molecules/NavBar';
import Dropdown, { DropdownOption } from 'app/components/molecules/Dropdown';
import Card from 'app/components/molecules/Card';
import IconButton from 'app/components/molecules/IconButton';
import MessageBox, { MessageBoxOptionProps } from 'app/components/molecules/MessageBox';
import Settings from 'app/components/organisms/Settings';

import styled from 'app/utils/theme';
import {
  getRecordings,
  getSortOption,
  getMyRecordings,
  getSharedRecordings,
  getRecordingsField
} from 'app/redux/reducers/recordings';
import {
  deleteRecording,
  toggleVideoPlayback,
  updateRecordingName,
  onSearchRecordings,
  setRecordingsSortOption,
  toggleShareRecordModal,
  unsubscribeRecording,
  getRecordings as _getRecordings,
  cancelUpload,
  retryUpload,
  stopUpload,
  toggleRecordingFavorite,
  toggleLightMode,
  toggleFavoritesFilter,
  selectVisibleRecordingsOption
} from 'app/redux/actions';
import { StoreModel, UploadingStatus } from 'app/models/StoreModel';
import { SORT_OPTIONS } from 'app/config';

const showWarning = ({ message }: any) => {
  toastr.info(`${PRODUCT_NAME} notification`, message, {
    icon: <Logo />,
    timeOut: 1000
  });
};

const TopBarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  padding: 15px 30px 0 30px;
`;

const TopArea = styled(TopBarWrapper)`
  background-color: ${(props) => props.theme.topArea.backgroundColor};
`;

const Wrapper = styled(WithInfiniteScroll)``;

const ContentWrapper = styled.div`
  padding: 15px 30px 0 30px;
`;

const SORT_BY = SORT_OPTIONS.map(({ label, icon, type }) => ({
  text: (
    <React.Fragment>
      {label}
      <IconButton size="sm" icon={icon as any} />
    </React.Fragment>
  ),
  meta: { type }
}));

export interface RecordingListProps {
  myRecordings?: Array<StoreModel.RecordingModel>;
  sharedRecordings?: Array<StoreModel.RecordingModel>;
  selectedSortOption?: number | null;
  deleteRecording?: Function;
  toggleVideoPlayback?: Function;
  updateRecordingName?: Function;
  onSearchRecordings?: Function;
  setRecordingsSortOption?: Function;
  toggleShareRecordModal?: Function;
  unsubscribeRecording?: Function;
  getRecordings?: Function;
  downloadFile?: Function;
  cancelUpload?: Function;
  retryUpload?: Function;
  stopUpload?: Function;
  toggleRecordingFavorite?: Function;
  toggleLightMode?: Function;
  toggleFavoritesFilter?: Function;
  isFavoritesFilter?: boolean;
  isLightMode?: boolean;
  isLoading?: boolean;
  totalRecordings?: number;
  selectVisibleRecordingsOption?: Function;
  selectedVisibleRecordingOption?: string;
  history?: any;
}

@connect(
  (state) => ({
    recordings: getRecordings(state),
    selectedSortOption: getSortOption(state),
    myRecordings: getMyRecordings(state),
    sharedRecordings: getSharedRecordings(state),
    isLightMode: getRecordingsField('isLightMode', state),
    isFavoritesFilter: getRecordingsField('isFavoritesFilter', state),
    isLoading: getRecordingsField('isLoading', state),
    totalRecordings: getRecordingsField('total', state),
    selectedVisibleRecordingOption: getRecordingsField('selectedVisibleRecordingOption', state)
  }),
  (dispatch) => ({
    cancelUpload: (id: string) => dispatch(cancelUpload(id)),
    retryUpload: (id: string) => dispatch(retryUpload(id)),
    stopUpload: (id: string) => dispatch(stopUpload(id)),
    setRecordingsSortOption: (sortOption: number) => dispatch(setRecordingsSortOption(sortOption)),
    onSearchRecordings: (text: string) => dispatch(onSearchRecordings(text)),
    updateRecordingName: ({ id, name }: any) => {
      dispatch(updateRecordingName({ id, name }));
    },
    unsubscribeRecording: (fileId: string) => dispatch(unsubscribeRecording(fileId) as any),
    deleteRecording: (fileId: string) => dispatch(deleteRecording(fileId)),
    toggleRecordingFavorite: (fileId: string, isFavorite: boolean) =>
      dispatch(toggleRecordingFavorite(fileId, isFavorite)),
    toggleVideoPlayback: (playRecording: StoreModel.RecordingModel | null) =>
      dispatch(toggleVideoPlayback(playRecording)),
    toggleShareRecordModal: (fileId: string | null) => dispatch(toggleShareRecordModal(fileId)),
    getRecordings: (reset: boolean) => dispatch(_getRecordings(reset) as any),
    downloadFile: ({ url, fileName }: any) => window.open(url, '_blank'),
    toggleFavoritesFilter: () => dispatch(toggleFavoritesFilter()),
    toggleLightMode: () => dispatch(toggleLightMode()),
    selectVisibleRecordingsOption: (option: string) =>
      dispatch(selectVisibleRecordingsOption(option) as any)
  })
)
class RecordingsList extends React.Component<RecordingListProps> {
  state = {
    deleteRecordingId: null,
    options: SELECTED_RECORDING_OPTIONS,
    sort: SORT_BY,
    sharedRecordings: []
  };

  selectOption = (option: NavBarOption) => {
    this.setState({
      options: this.state.options.map((o) => ({
        ...o,
        selected: o.text === option.text
      }))
    });
  };

  selectSort = (option: DropdownOption) => {
    const { setRecordingsSortOption = () => {} } = this.props;

    setRecordingsSortOption(option.meta.type);
  };

  getSelectedSortLabel = () => {
    const { selectedSortOption } = this.props;
    const { sort } = this.state;

    const option = sort.find((option) => option.meta.type === selectedSortOption);
    if (!option) {
      return '';
    }

    return (option as DropdownOption).text;
  };

  componentDidMount() {
    const { sharedRecordings = [] } = this.props;

    this.resetSharedRecordings(sharedRecordings);
    this.updateScreenSize = debounce(this.updateScreenSize, 500);

    window.addEventListener('resize', this.updateScreenSize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateScreenSize);
  }

  updateScreenSize = () => {
    this.setState({ width: document.body.clientWidth });
  };

  componentWillReceiveProps(nextProps: any) {
    const { sharedRecordings } = nextProps;
    this.resetSharedRecordings(sharedRecordings);
  }

  getRecordingTitle(recording: StoreModel.RecordingModel) {
    if (!recording.uploading) {
      return recording.name;
    }

    if (recording.uploading.status === UploadingStatus.IN_PROGRESS) {
      return `${recording.name} (Uploading ${recording.uploading.progress}%...)`;
    }

    return `${recording.name} - Upload ${recording.uploading.status.toLowerCase()};`;
  }

  renderMyRecordings = () => {
    const {
      myRecordings = [],
      updateRecordingName = () => {},
      toggleShareRecordModal = () => {},
      downloadFile = () => {},
      retryUpload = () => {},
      stopUpload = () => {},
      toggleRecordingFavorite = () => {},
      totalRecordings = 0,
      deleteRecording = () => {}
    } = this.props;

    if (!myRecordings.length) {
      return <Text italic>No recordings found.</Text>;
    }

    return (
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {myRecordings.map((recording) => (
          <div style={{ padding: '10px', paddingRight: '15px', paddingLeft: 0 }} key={recording.id}>
            <Card
              {...recording}
              name={this.getRecordingTitle(recording)}
              onTitleChange={(name: string) => updateRecordingName({ id: recording.id, name })}
              onDelete={() => {
                deleteRecording(recording.id);
                showWarning({
                  message: `Recording "${recording.name}" has bee permanently removed.`
                });
              }}
              onFavorite={() => toggleRecordingFavorite(recording.id, !recording.isFavorite)}
              onPlay={() => {
                this.props.history?.push(`/details/${recording.id}`);
              }}
              onShare={recording.sharedUrl ? () => toggleShareRecordModal(recording.id) : undefined}
              onDownload={
                recording.sharedUrl
                  ? () =>
                      downloadFile({
                        url: recording.downloadUrl,
                        fileName: recording.name
                      })
                  : undefined
              }
              onStopUpload={
                recording.uploading &&
                (recording.uploading.status === UploadingStatus.IN_PROGRESS ||
                  recording.uploading.status === UploadingStatus.PENDING)
                  ? () => stopUpload(recording.id)
                  : undefined
              }
              onRetryUpload={
                recording.uploading &&
                (recording.uploading.status === UploadingStatus.CANCELED ||
                  recording.uploading.status === UploadingStatus.FAILED)
                  ? () => retryUpload(recording.id)
                  : undefined
              }
            />
          </div>
        ))}
        <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
          <Text italic fs={16} light pasive>
            {' '}
            Viewing {myRecordings.length} out of {totalRecordings}
          </Text>
        </div>
      </div>
    );
  };

  resetSharedRecordings = (sharedRecordings: Array<StoreModel.RecordingModel>) => {
    const groupedBySender = groupBy(sharedRecordings || [], 'ownerEmail');
    this.setState({
      sharedRecordings: Object.entries(groupedBySender).map(([senderEmail, recordings]) => ({
        senderEmail,
        recordings,
        isActive: true
      }))
    });
  };

  toggleSharedRecording = ({
    toggleEmail,
    isActive
  }: {
    toggleEmail: string;
    isActive: boolean;
  }) => {
    const { sharedRecordings } = this.state;
    this.setState({
      sharedRecordings: sharedRecordings.map(({ senderEmail, recordings, ...rest }: any) => ({
        senderEmail,
        recordings,
        isActive: toggleEmail === senderEmail ? isActive : !!rest.isActive
      }))
    });
  };

  renderSharedRecordings = () => {
    const {
      unsubscribeRecording = () => {},
      toggleVideoPlayback = () => {},
      downloadFile = () => {}
    } = this.props;

    const { sharedRecordings } = this.state;

    if (!sharedRecordings.length) {
      return <Text italic>No shared recordings found.</Text>;
    }

    return (
      <div>
        {sharedRecordings.map((sharedData, index) => {
          const { senderEmail, recordings, isActive } = sharedData as any;
          return (
            <Accordion>
              <Accordion.Title
                index={index}
                active={isActive}
                onClick={() => {
                  this.toggleSharedRecording({
                    toggleEmail: senderEmail,
                    isActive: !isActive
                  });
                }}
              >
                <Text>
                  <Icon name="dropdown" />
                  {senderEmail}
                </Text>
              </Accordion.Title>
              <Accordion.Content active={isActive}>
                <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                  {recordings.map((recording: any) => (
                    <div
                      style={{ padding: '10px', paddingRight: '15px', paddingLeft: 0 }}
                      key={recording.id}
                    >
                      <Card
                        {...recording}
                        // onTitleChange={(name: string) =>
                        //   updateRecordingName({ id: recording.id, name })
                        // }
                        // onFavorite={() => {
                        //   console.log('toggling favorite on ', recording);
                        //   toggleRecordingFavorite(recording.id);
                        // }}
                        onDelete={() => unsubscribeRecording(recording.id)}
                        onPlay={() => toggleVideoPlayback(recording)}
                        onDownload={() =>
                          downloadFile({
                            url: recording.downloadUrl,
                            fileName: recording.name
                          })
                        }
                      />
                    </div>
                  ))}
                  <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                    <Text italic fs={16} light pasive>
                      {' '}
                      Viewing {recordings.length} out of {recordings.length}
                    </Text>
                  </div>
                </div>
              </Accordion.Content>
            </Accordion>
          );
        })}
      </div>
    );
  };

  renderDeleteRecordingModal = () => {
    const { deleteRecordingId } = this.state;
    const { myRecordings = [], deleteRecording = () => {}, cancelUpload = () => {} } = this.props;

    const options = [{ text: 'Cancel' }, { text: 'Yes', active: true }];

    if (!deleteRecordingId) {
      return null;
    }

    const recording = myRecordings.find(
      (r: StoreModel.RecordingModel) => r.id === deleteRecordingId
    );
    if (!recording) {
      return null;
    }

    return (
      <MessageBox
        message="Are you sure you want to delete this recording?"
        options={options}
        onClick={(option: MessageBoxOptionProps) => {
          this.setState({ deleteRecordingId: null });
          if (option.text === 'Cancel') {
            return;
          }

          recording.uploading ? cancelUpload(recording.id) : deleteRecording(recording.id);
        }}
      />
    );
  };

  onBottomScroll = () => {
    const { getRecordings = () => {} } = this.props;
    getRecordings();
  };

  render() {
    const {
      onSearchRecordings = () => {},
      getRecordings = () => {},
      toggleLightMode = () => {},
      toggleFavoritesFilter = () => {},
      isFavoritesFilter,
      isLightMode,
      selectVisibleRecordingsOption = () => {},
      isLoading,
      selectedVisibleRecordingOption
    } = this.props;

    const mineSelected = this.state.options[0].id === selectedVisibleRecordingOption;
    const sharedSelected = this.state.options[1].id === selectedVisibleRecordingOption;

    return (
      <Wrapper onBottomScroll={debounce(this.onBottomScroll, 500).bind(this)}>
        <TopArea>
          <Logo xxl opacity={0.4} />
          <ActionButtons
            size="4x"
            style={{ position: 'absolute', left: '42%' }}
          />
          <Settings googleSignOut={() => {}} /** bug - tbr */ />
        </TopArea>
        <TopBarWrapper>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <IconButton
              icon="lightbulb"
              orange={!isLightMode}
              onClick={toggleLightMode}
              mRight={10}
              tooltip={{ text: 'Switch between light and night modes', position: 'bottom' }}
            />
            <IconButton
              tooltip={{ text: `Filter on/off favorite items`, position: 'bottom' }}
              icon="star"
              orange={isFavoritesFilter}
              onClick={toggleFavoritesFilter}
              mRight={10}
            />
            <IconButton
              icon="redo"
              onClick={() => getRecordings(true)}
              mRight={10}
              tooltip={{ text: 'Reload all recordings', position: 'bottom' }}
            />
          <Dropdown options={this.state.sort} onSelect={this.selectSort} name={''} />
          </div>
          <SearchInput onSearch={onSearchRecordings} />
        </TopBarWrapper>
        <ContentWrapper>
          <NavBar
            options={this.state.options}
            onSelect={selectVisibleRecordingsOption}
            selected={selectedVisibleRecordingOption}
          />
          <br />
          {mineSelected && this.renderMyRecordings()}
          {sharedSelected && this.renderSharedRecordings()}
        </ContentWrapper>
        {this.renderDeleteRecordingModal()}
        {isLoading && (
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <IconButton icon="spinner" spin active size="3x" />
          </div>
        )}
      </Wrapper>
    );
  }
}

export default withRouter((props: RecordingListProps) => <RecordingsList {...props} />);
