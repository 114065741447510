import * as React from 'react';
import IconButton, { IconButtonProps } from 'app/components/molecules/IconButton';
import Text from 'app/components/atoms/Text';
import { connect } from 'react-redux';

import {
  toggleSourceSelectorModal,
  setRecordingSettings,
  toggleTimerModal,
  takeScreenshot,
  startWebCamRecording,
  startWebCamAndVideoRecording,
} from 'app/redux/actions';

import styled from 'app/utils/theme';
import { StoreModel } from 'app/models/StoreModel';
import { RecordActionType } from 'app/config';

export interface ActionButtonsProps extends Pick<IconButtonProps, 'size'> {
  toggleSourceSelectorModal?: Function;
  setRecordingSettings?: Function;
  toggleTimerModal?: Function;
  takeScreenshot?: Function;
  startWebCamRecording?: Function;
  startWebCamAndVideoRecording?: Function;
  style?: any;
  big?: boolean;
}

const ActionButtons = ({
  big,
  style,
  size,
  toggleSourceSelectorModal = (type: string) => {},
  setRecordingSettings = () => {},
  toggleTimerModal = () => {},
  takeScreenshot = () => {},
  startWebCamRecording = () => {},
  startWebCamAndVideoRecording = () => {},
}: ActionButtonsProps) => (
  <ActionButtons.Wrapper style={style}>
    <ActionButtons.ButtonWrpatter big={big}>
      <IconButton
        action
        icon="desktop"
        size={size}
        onClick={() => {
          setRecordingSettings({ type: RecordActionType.VIDEO });
          toggleSourceSelectorModal();
        }}
        tooltip={{ text: 'Press to start screen video recording!', position: 'top' }}
      />
      <Text sm>Video Recording</Text>
    </ActionButtons.ButtonWrpatter>
    <ActionButtons.ButtonWrpatter big={big}>
      <IconButton
        action
        icon="microphone"
        size={size}
        onClick={() => {
          setRecordingSettings({
            sound: true,
            source: { name: 'Audio' },
            type: RecordActionType.AUDIO
          });
          toggleTimerModal();
        }}
        tooltip={{ text: 'Press to start sound only recording!', position: 'top' }}
      />
      <Text sm>Audio Recording</Text>
    </ActionButtons.ButtonWrpatter>
    <ActionButtons.ButtonWrpatter big={big}>
      <IconButton
        action
        icon="screenshot"
        size={size}
        onClick={() => {
          setRecordingSettings({ type: RecordActionType.SCREENSHOT });
          takeScreenshot();
        }}
        tooltip={{ text: 'Press to take screenshot!', position: 'top' }}
      />
      <Text sm>Screenshot</Text>
    </ActionButtons.ButtonWrpatter>
    <ActionButtons.ButtonWrpatter big={big}>
      <IconButton
        action
        icon="camera"
        size={size}
        onClick={async () => {
          startWebCamRecording();
        }}
        tooltip={{ text: 'Press to record webcam!', position: 'top' }}
      />
      <Text sm>Webcam</Text>
    </ActionButtons.ButtonWrpatter>
    <ActionButtons.ButtonWrpatter big={big}>
      <IconButton
        action
        icon="video"
        size={size}
        onClick={() => {
          startWebCamAndVideoRecording();
        }}
        tooltip={{ text: 'Press to start screen video and webcam recording!', position: 'top' }}
      />
      <Text sm>Video with Webcam Recording</Text>
    </ActionButtons.ButtonWrpatter>
  </ActionButtons.Wrapper>
);

ActionButtons.Wrapper = styled.div`
  display: flex;
`;

ActionButtons.ButtonWrpatter = styled.div<{ big?: boolean }>`
  p {
    display: none;
  }
  margin-right: 20px;

  ${(props) =>
    props.big &&
    `
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    max-width: 100px;
    margin-right: 40px;

    p {
      display: inline-block;
      padding-top: 10px;
      text-align: center;
    }
  `}
`;

export default connect(null, (dispatch) => ({
  toggleSourceSelectorModal: () => dispatch(toggleSourceSelectorModal() as any),
  setRecordingSettings: (settings: StoreModel.RecordingSettings) =>
    dispatch(setRecordingSettings(settings)),
  toggleTimerModal: () => dispatch(toggleTimerModal()),
  takeScreenshot: () => dispatch(takeScreenshot() as any),
  startWebCamRecording: () => dispatch(startWebCamRecording() as any),
  startWebCamAndVideoRecording: () => dispatch(startWebCamAndVideoRecording() as any),
}))(styled(ActionButtons)``);
