import React from 'react';
import { connect } from 'react-redux';
import { StoreModel } from 'app/models/StoreModel';
import {
  getReccordingSettings,
  isLoadingSources,
  getNumberOfDisplays,
  getRecordingType
} from 'app/redux/reducers/recordingSelector';
import {
  toggleSourceSelectorModal,
  getRecordingSources,
  filterRecordingSources,
  setRecordingSettings,
  toggleTimerModal,
  takeScreenshot
} from 'app/redux/actions';
import { getSources } from 'app/redux/reducers/recordingSelector';

import styled from 'app/utils/theme';
import Modal from 'app/components/molecules/Modal';
import Checkbox from 'app/components/atoms/Checkbox';
import Icon from 'app/components/atoms/Icon';
import SourceCard from 'app/components/molecules/SourceCard';
import SearchInput from 'app/components/molecules/SearchInput';
import Text from 'app/components/atoms/Text';

import { PRODUCT_NAME } from 'app/config';

interface ModalProps {
  toggleSourceSelectorModal?: Function;
  getRecordingSources?: Function;
  filterRecordingSources?: Function;
  setRecordingSettings?: Function;
  sources?: Array<StoreModel.RecordingSource>;
  settings?: StoreModel.RecordingSettings;
  toggleTimerModal?: Function;
  isLoadingSources?: boolean;
  numberOfDisplays?: number;
  takeScreenshot?: Function;
  isScreenshotAction?: boolean;
  recordType?: string;
}

class SelectSourceModal extends React.Component<ModalProps> {
  async componentDidMount() {
    const { getRecordingSources = () => { } } = this.props;

    getRecordingSources();
  }

  render() {
    const {
      sources = [],
      toggleSourceSelectorModal = () => {},
      filterRecordingSources = () => {},
      setRecordingSettings = () => {},
      toggleTimerModal = () => {},
      takeScreenshot = () => {},
      settings = { sound: false },
      numberOfDisplays = 1,
      isLoadingSources,
      recordType
    } = this.props;

    const emptyList = (
      <React.Fragment>
        {new Array(numberOfDisplays).fill(1).map((number, index) => (
          <SourceCard progress big id={`id_${index}`} name="" thumbnail="" onClick={() => {}} />
        ))}
        {new Array(9).fill(1).map((number, index) => (
          <SourceCard progress id={`id_${index}`} name="" thumbnail="" onClick={() => {}} />
        ))}
      </React.Fragment>
    );
    return (
      <Modal onClose={() => toggleSourceSelectorModal()}>
        <br />
        <ModalOptions>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Checkbox
              label="With Sound"
              checked={settings.sound}
              onClick={() => setRecordingSettings({ sound: !settings.sound })}
            />
            <Text pLeft={15} />
            <Icon icon="microphone" />
          </div>
          <SearchInput onSearch={(v: any) => filterRecordingSources(v)} />
        </ModalOptions>
        <br />
        <ModalContent>
          {isLoadingSources && emptyList}
          {!isLoadingSources &&
            sources
              // hackish way of sorting by screen
              .sort((source1: StoreModel.RecordingSource, source2: StoreModel.RecordingSource) => {
                const source1IsScreen = source1.id.indexOf('screen:') > -1;
                const source2IsScreen = source2.id.indexOf('screen:') > -1;

                if (source1IsScreen && !source2IsScreen) {
                  return -1;
                }

                if (!source1IsScreen && source2IsScreen) {
                  return 1;
                }

                return 0;
              })
              .filter((source) => source.name.search(new RegExp(PRODUCT_NAME, 'i')))
              .map((source: StoreModel.RecordingSource) => (
                <SourceCard
                  big={source.id.includes('screen')}
                  id={source.id}
                  thumbnail={source.thumbnail}
                  name={source.name}
                  onClick={() => {
                    setRecordingSettings({
                      source
                    });

                    if (recordType === 'screenshot') {
                      takeScreenshot();
                      toggleSourceSelectorModal();
                    } else {
                      toggleTimerModal();
                    }
                  }}
                />
              ))}
        </ModalContent>
      </Modal>
    );
  }
}

const ModalOptions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const ModalContent = styled.div`
  position: relative;
  height: 88%;
  overflow-y: scroll;
  overflow-x: hidden;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  padding-left: 15px;
  margin-left: -38px;

  ${Icon} {
    font-size: 25em;
    opacity: 0.35;
    position: absolute;
    z-index: 10000;
  }
`;

export default connect(
  (state) => ({
    sources: getSources(state),
    settings: getReccordingSettings(state),
    isLoadingSources: isLoadingSources(state),
    numberOfDisplays: getNumberOfDisplays(state),
    recordType: getRecordingType(state)
  }),
  (dispatch) => ({
    takeScreenshot: () => dispatch(takeScreenshot() as any),
    toggleTimerModal: () => dispatch(toggleTimerModal()),
    getRecordingSources: () => dispatch(getRecordingSources()),
    toggleSourceSelectorModal: () => dispatch(toggleSourceSelectorModal() as any),
    filterRecordingSources: (filter: string) => dispatch(filterRecordingSources(filter)),
    setRecordingSettings: (settings: StoreModel.RecordingSettings) =>
      dispatch(setRecordingSettings(settings))
  })
)(SelectSourceModal);
