import { Middleware } from 'redux';
import * as asyncActions from 'app/utils/asyncActions';

export const asyncAction: Middleware = (store) => (next) => async (action) => {
  if (!action.asyncAction) {
    return next(action);
  }

  const { dispatch } = store;
  const { method, params } = action.payload;

  dispatch({
    type: action.type.STARTED,
    payload: {
      params
    }
  });

  console.log(asyncActions);
  const methodToCall = (<any>asyncActions)[method];
  if (!methodToCall) {
    return next({
      type: action.type.FAILED
    });
  }

  try {
    const result = await methodToCall(params);

    dispatch({
      type: action.type.COMPLETED,
      payload: {
        data: result
      }
    });
  } catch (error) {
    dispatch({
      type: action.type.FAILED,
      payload: {
        error
      }
    });
  }
};
