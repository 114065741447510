import { Store, createStore, applyMiddleware, compose } from 'redux';
import reduxLogger from 'redux-logger';
import thunkMiddleware from 'redux-thunk';
import set from 'lodash/set';
import rootReducer from 'app/redux/reducers/index';

import {
  addAccessToken,
  asyncAction,
  recordCompleteInterceptor,
  authErrorInterceptor,
  notificationInterceptor,
  actionTracking,
  hideWindowOnScreenshot,
} from 'app/middleware';

import { StoreModel } from 'app/models/StoreModel';
import { GOOGLE_API_URI } from 'app/config';

import axios from 'axios';
import { multiClientMiddleware } from 'redux-axios-middleware';
import debounce from 'lodash/debounce';
import { loadState, saveState } from 'app/utils/persistStore';

const googleClient = axios.create({
  baseURL: GOOGLE_API_URI,
  responseType: 'json'
});

const apiClient = axios.create({
  baseURL: process.env.API_URL,
  responseType: 'json'
});

// keep the client global;
set(window, 'googleClient', googleClient);
set(window, 'apiClient', apiClient);

const clients = {
  default: { client: googleClient },
  api: { client: apiClient }
};

const axiosOptions = {
  // not required, but use-full configuration option
  returnRejectedPromiseOnError: true,
  interceptors: {
    request: [
      {
        success: ({ dispatch }: any, response: any) => {
          // Response interception
          return response;
        }
        // error: ({ dispatch }: any, error: any) => {
        //   // Response Error Interception
        //   return Promise.reject(error);
        // }
      }
    ],
    response: [
      {
        success: ({ dispatch }: any, response: any) => {
          // Response interception
          return response;
        },
        error: ({ dispatch }: any, error: any) => {
          // Response Error Interception
          return Promise.reject(error);
        }
      }
    ]
  }
};

export function configureStore(initialState: StoreModel = loadState()) {
  let middleware = [
    thunkMiddleware,
    multiClientMiddleware(clients, axiosOptions),
    asyncAction,
    addAccessToken,
    recordCompleteInterceptor,
    reduxLogger,
    authErrorInterceptor,
    notificationInterceptor,
    actionTracking,
    hideWindowOnScreenshot,
    //getRecordingsOnLogin
  ];

  const store = createStore(
    rootReducer,
    initialState as any,
    compose(applyMiddleware(...middleware)),
  ) as Store<StoreModel>;

  if (module.hot) {
    module.hot.accept('app/redux/reducers', () => {
      const nextReducer = require('app/redux/reducers');
      store.replaceReducer(nextReducer);
    });
  }

  store.subscribe(debounce(() => saveState(store.getState()), 800));

  return { store };
}
