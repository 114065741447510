import * as React from 'react';
import styled from 'app/utils/theme';
import Text from 'app/components/atoms/Text';
import { IconType } from 'app/components/atoms/Icon';
import IconButton from 'app/components/molecules/IconButton';

export type DropdownOption = {
  text: string | JSX.Element;
  meta?: any;
};

interface DropdownProps {
  options: Array<DropdownOption>;
  onSelect: (option: DropdownOption) => void;
  name?: string | JSX.Element;
  noIcon?: boolean;
  icon?: IconType;
  maxWidth?: string;
  right?: boolean;
  left?: boolean;
}

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;

const DropdownMenu = styled.div<{
  right?: boolean;
  left?: boolean;
  isOpen: boolean;
  options: Array<DropdownOption>;
  maxWidth: any;
}>`
  ${(props) => (props.isOpen ? '' : 'display:none')};
  position: absolute;
  background: ${(props) => props.theme.dropdown.backgroundColor};
  height: ${(props) => `${props.options.length * 30}px`};
  width: ${(props) => `${props.maxWidth || '140px'}`};
  top: 15px;
  border-radius: 4px;
  border: 1px solid ${(props) => props.theme.dropdown.borderColor};
  z-index: 10;

  ${(props) => props.left && `right: 0;`}
`;

const OptionItem = styled(Text)`
  border-bottom: 1px solid ${(props) => props.theme.dropdown.borderColor};
  display: flex;
  justify-content: space-between;
  cursor: pointer;

  :hover {
    background-color: ${(props) => props.theme.dropdown.borderColor};
  }
`;

class Dropdown extends React.PureComponent<DropdownProps> {
  state = {
    isOpen: false
  };

  container: any = null;
  key: string = `dropdown_${Math.random()}`;

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside, false);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, false);
  }

  toggleOpen = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  };

  handleClickOutside = (e: any) => {
    const { container } = this;

    if (container.contains(e.target)) {
      return;
    }

    this.setState({
      isOpen: false
    });
  };

  onSelect = (option: DropdownOption) => {
    this.setState(
      {
        isOpen: false
      },
      () => {
        this.props.onSelect(option);
      }
    );
  };

  render() {
    const { options, name, noIcon, icon, maxWidth, right, left } = this.props;

    return (
      <Wrapper
        ref={(el: HTMLDivElement) => (this.container = el)}
        onClick={this.handleClickOutside}
      >
        {name && (
          <Text onClick={() => this.toggleOpen()} underline active>
            {name}
          </Text>
        )}
        {!noIcon && (
          <IconButton
            key={this.key}
            size="2x"
            icon={icon || 'sort'}
            onClick={() => this.toggleOpen()}
          />
        )}

        <DropdownMenu
          isOpen={this.state.isOpen}
          options={options}
          maxWidth={maxWidth}
          right={right}
          left={left}
        >
          {options.map((option, index) => (
            <React.Fragment key={index}>
              <OptionItem active p={5} onClick={() => this.onSelect(option)}>
                {option.text}
              </OptionItem>
            </React.Fragment>
          ))}
        </DropdownMenu>
      </Wrapper>
    );
  }
}

export default Dropdown;
