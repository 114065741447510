import { withTheme } from 'styled-components';
import Page from 'app/components/pages/Page';
import { toggleVideoPlaybackById } from 'app/redux/actions';
import { getSharedFile } from 'app/redux/actions/apiActions';
import React from 'react';
import { connect } from 'react-redux';
import Logo from '../molecules/Logo';
import styled from 'app/utils/theme';
import { StoreModel } from 'app/models/StoreModel';
import {
  getDurationInSeconds,
  getDetailsPageRecording,
  isLoadingRecordings,
  isScreenshot
} from 'app/redux/reducers/recordings';
import VideoPlayer from 'app/components/atoms/VideoPlayer/index';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import Image from 'app/components/atoms/Image';
import Settings from 'app/components/organisms/Settings';
import IconButton from '../molecules/IconButton';
import get from 'lodash/get';
import { isAuthenticated } from 'app/redux/reducers/googleSignin';
import Text from 'app/components/atoms/Text';
import formatBytes from 'app/utils/formatBytes';

const PlayerWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 8%;

  > div:nth-of-type(1) {
    width: 80vw !important;
    height: 75vh !important;

    box-shadow: 0 4px 15px 2px rgba(0, 0, 0, 0.35);
  }
`;


interface PlayerPageProps extends RouteComponentProps {
  playRecording?: StoreModel.RecordingModel;
  durationInSeconds?: number;
  isScreenshot?: boolean;
  getSharedFile: Function;
  toggleVideoPlaybackById: Function;
  isLoading: boolean;
  isAuthenticated: boolean;
  theme?: any;
}

@connect(
  (state) => ({
    playRecording: getDetailsPageRecording(state),
    durationInSeconds: getDurationInSeconds(state),
    isScreenshot: isScreenshot(state),
    isLoading: isLoadingRecordings(state),
    isAuthenticated: isAuthenticated(state)
  }),
  (dispatch) => ({
    getSharedFile: (fileId: string, token: string) => dispatch(getSharedFile(fileId, token)),
    toggleVideoPlaybackById: (fileId: string) => dispatch(toggleVideoPlaybackById(fileId) as any)
  })
)
class RecordingDetailsPage extends Page<PlayerPageProps> {
  componentDidMount() {
    // get recording
    const fileId = location.pathname.replace('/details/', '');
    const token = location.search.replace('?token=', '');
    const { getSharedFile, toggleVideoPlaybackById, isAuthenticated } = this.props;

    getSharedFile(fileId, token)
      .then(() => {
        toggleVideoPlaybackById(fileId);
      })
      .catch((e: any) => {
        const errorCode = get(e, 'error.response.data.statusCode');
        if (errorCode === 401) {
          location.href = `${location.origin}/login?redirect=${encodeURIComponent(
            location.pathname
          )}`;
        } else {
          location.href = isAuthenticated
            ? `${location.origin}/no-access`
            : `${location.origin}/login`;
        }
      });
  }

  render() {
    const { playRecording, durationInSeconds = 0, isScreenshot, isLoading } = this.props;

    return (
      <div style={{ display: 'flex', flexDirection: 'column', padding: '20px 20px' }}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Logo xxl opacity={1} />
          <Settings />
        </div>
        <PlayerWrapper>
          <IconButton
            size="3x"
            icon="arrow-alt-circle-left"
            onClick={() => this.props.history.push('/')}
            style={{position: 'absolute', top: '12%', left: '5%'}}
          />

          {playRecording && !isScreenshot && (
            <VideoPlayer {...playRecording} durationInSeconds={durationInSeconds} />
          )}
          {playRecording && isScreenshot && <Image src={playRecording.url} />}
          {isLoading && (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <IconButton icon="spinner" spin active size="5x" />
            </div>
          )}
          {playRecording && (
            <div>
              <Text active m pTop={10}>
                {playRecording.name}
              </Text>
              <Text s pTop={5} sm pasive>
                {' '}
                {!isScreenshot && `${playRecording.duration} • `}
                {`${playRecording.createdAt} • ${formatBytes(playRecording.size)}`}
              </Text>
            </div>
          )}
          {playRecording && playRecording.transcription && (
            // <Chrono
            //   theme={{
            //     primary: this.props.theme.text.colors.active,
            //     secondary: this.props.theme.text.colors.regular,
            //     cardBgColor: this.props.theme.backgroundColor,
            //     cardForeColor: this.props.theme.text.colors.active,
            //     titleColor: this.props.theme.text.colors.active
            //   }}
            //   items={playRecording.transcription}
            //   mode="VERTICAL"
            // />
            <div>
              <Text pTop={40} pBottom={20} m>
                Transcription
              </Text>
              {playRecording.transcription.map((item) => (
                <Text pTop={5} style={{ display: 'flex', alignItems: 'center' }}>
                  <Text style={{ width: '80px' }} pasive>
                    {item.startTime}
                  </Text>
                  {/* <Text style={{ width: '10px', opacity: '0.5', textAllign: 'center' }} pasive>
                    &nbsp;-&nbsp;
                  </Text> */}
                  <Text style={{ width: '80px'}} pasive>
                    {item.endTime}
                  </Text>

                  <Text fs={20} italic>{item.cardDetailedText}</Text>
                </Text>
              ))}
            </div>
          )}
        </PlayerWrapper>
      </div>
    );
  }
}

export default withTheme(withRouter(RecordingDetailsPage));
