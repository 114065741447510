import { ActionTypes } from 'app/redux/actionTypes';
import { StoreModel, UploadingModel } from 'app/models/StoreModel';
import { getApiToken, getAuthToken } from 'app/redux/reducers/googleSignin';
import BlaseStorage from 'app/utils/storage/BlazeStorage';
import { whereToStoreFile } from 'app/redux/actions/index';
import get from 'lodash/get';

import { Dispatch } from 'react';
import { StorageTypes, VIDEO_MIME, IMAGE_MIME, MAX_S3_RECORDING_SIZE, GIF_MIME } from 'app/config';
import GoogleStorage from 'app/utils/storage/GoogleStorage';
import { Recorder } from 'app/utils/asyncActions';
import S3Storage from 'app/utils/storage/S3Storage';
import { createUpdateFromActiveRecording } from 'app/middleware/recordCompleteInterceptor';
import { getUploadById } from '../reducers/uploading';
import getGiFfromBlob from 'app/utils/gifFromBlob';

interface StartUploadParams {
  recordingId: string;
  blob?: Blob;
  apiToken: string;
  googleToken: string;
  state: StoreModel;
  dispatch: Dispatch<any>;
}

export const retryRecordingUpload = (recordingId: string) => async (
  dispatch: Dispatch<any>,
  getState: Function
) => {
  // refresh token before trying to upload
  const state = getState();
  const apiToken = getApiToken(state);
  const googleToken = getAuthToken(state);

  return startUpload({ recordingId, state, apiToken, googleToken, dispatch });
};

const startUpload = async ({
  recordingId,
  state,
  apiToken,
  googleToken,
  dispatch
}: StartUploadParams) => {
  console.time('StartUploadMain');

  console.time('createUpdateFromActiveRecording');
  const uploading =
    getUploadById(state, recordingId) || await createUpdateFromActiveRecording(state, dispatch); // create update object
  console.timeEnd('createUpdateFromActiveRecording');

  try {
    if (!uploading) {
      throw 'missing uploading object';
    }

    const isVideoRecording = uploading.mimeType === VIDEO_MIME;
  console.time('StartUploadMain-getUploadBlob');
    const blob: Blob = await getUploadBlob(uploading);
  console.timeEnd('StartUploadMain-getUploadBlob');
  console.time('StartUploadMain-getStorageClient');

    const storageClient = await getStorageClient(blob.size, googleToken, dispatch);
  console.timeEnd('StartUploadMain-getStorageClient');

    // set blob in canvas
    console.time('TotalGifCreationTime');
    const gifBlob = await getGiFfromBlob(blob, uploading.duration); // empty source
    console.timeEnd('TotalGifCreationTime');

    console.time('StartUploadMain-Storage');

    const {
      url,
      sharedUrl,
      downloadUrl,
      thumbnail,
      gif,
      meta,
      storage,
      accessType
    } = await storageClient.store(
      {
        file: {
          name: uploading.name,
          mimetype: uploading.mimeType,
          blob
        },
        thumbnail: isVideoRecording
          ? {
              name: uploading.name,
              mimetype: IMAGE_MIME,
              blob: await fetch(uploading.thumbnail).then((res) => res.blob())
            }
          : null,
        gif: isVideoRecording
          ? {
              name: uploading.name,
              mimetype: GIF_MIME,
              blob: gifBlob
            }
          : null
      },
      (progress: number) => {
        dispatch({
          type: `${ActionTypes.UPLOAD_RECORDING}_PROGRESS`,
          payload: {
            data: {
              progress,
              uploading
            }
          }
        });
      }
    );

    console.timeEnd('StartUploadMain-Storage');

    const file = {
      name: uploading.name,
      url,
      sharedUrl,
      downloadUrl,
      thumbnail,
      gif,
      meta,
      mimeType: uploading.mimeType,
      duration: uploading.duration,
      size: blob.size,
      storage,
      accessType
    };

    const { apiClient } = window as any;

    const { data } = await apiClient({
      data: file,
      method: 'POST',
      url: '/files',
      headers: {
        Authorization: `Bearer ${apiToken}`
      }
    });

    Recorder.IndexedDB.deleteChunks(uploading.id);

    dispatch({
      type: ActionTypes.RESET_ACTIVE_RECORDING
    });

    console.timeEnd('StartUploadMain');

    dispatch({
      type: `${ActionTypes.UPLOAD_RECORDING}_SUCCESS`,
      payload: {
        data
      },
      meta: {
        previousAction: {
          payload: {
            data: { uploading }
          }
        }
      }
    });
  } catch (e) {
    dispatch({
      type: `${ActionTypes.UPLOAD_RECORDING}_FAIL`,
      error: e,
      meta: {
        previousAction: {
          payload: {
            data: { uploading }
          }
        }
      }
    });
  }


  async function getUploadBlob(uploading: UploadingModel) {
    const isSnapshotUpload = uploading.mimeType === IMAGE_MIME;
    if (isSnapshotUpload) {
      return fetch(uploading.thumbnail).then((res) => res.blob());
    }

    const chunks = await Recorder.IndexedDB.getChunks(uploading.id);
    const chunksSize = chunks.reduce((sum, chunk: any) => sum + chunk.size, 0);
    const maxFileSize = 1.8 * 1024 * 1024 * 1024; // 1.8 GB

    if (chunksSize < maxFileSize) {
      return Recorder.injectMetadata(new Blob(chunks, { type: uploading.mimeType }));
    }

    // const slicedChunks = chunk(
    //   chunks,
    //   Math.ceil(chunks.length / Math.round(chunksSize / maxFileSize))
    // );

    let totalSize = 0;
    const s3LimitedChunks = chunks.reduce((acc: Array<any>, chunk: any) => {
      if (totalSize <= MAX_S3_RECORDING_SIZE) {
        acc.push(chunk);
      }

      totalSize += chunk.size;

      return acc;
    }, []);

    const slicedChunks = s3LimitedChunks.reduce((acc: Array<any>, chunk: any) => {
      if (!acc.length) {
        acc.push([chunk]);
        return acc;
      }

      const lastChunkSeg: Array<any> = acc[acc.length - 1];
      const segTotalSize = lastChunkSeg.reduce((sum, chunk: any) => sum + chunk.size, 0);
      const segmentCantTakeThisBlobToo = segTotalSize + chunk.size < maxFileSize;
      if (segmentCantTakeThisBlobToo) {
        lastChunkSeg.push(chunk);
      } else {
        acc.push([chunk]);
      }

      return acc;
    }, []);

    const seekableSlicedChunks = [];
    for (let nonSeekableChunks of slicedChunks) {
      if (slicedChunks.indexOf(nonSeekableChunks) === 0) {
        seekableSlicedChunks.push(
          await Recorder.injectMetadata(new Blob(nonSeekableChunks, { type: uploading.mimeType }))
        );
      } else {
        seekableSlicedChunks.push(new Blob(nonSeekableChunks, { type: uploading.mimeType }));
      }
    }

    return new Blob(seekableSlicedChunks, { type: uploading.mimeType });
  }

  async function getStorageClient(size: number, googleToken: string, dispatch: Dispatch<any>) {
    const response = await dispatch(whereToStoreFile(size));
    const { signedUrls, storageType } = get(response, 'payload.data');

    switch (storageType.name) {
      case StorageTypes.BACKBLAZE.name:
        return new BlaseStorage(signedUrls);
      case StorageTypes.S3.name:
        return new S3Storage(signedUrls);
      case StorageTypes.GOOGLE_DRIVE.name:
        return new GoogleStorage({ access_token: googleToken });
    }

    throw new Error('Unknown storage type!');
  }
};
