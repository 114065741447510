import { isWebBuild } from "app/config";

/**
 * on web builds we mock the checkForUpdates
 */
const _getLocalServerPort = !isWebBuild
  ? // needs to by window.require to avoid conflict with browserify require
    // https://github.com/electron/electron/issues/7300
    () => Promise.resolve((<any>window).require('electron').remote.getGlobal('APP_LOCAL_SERVER'))
  : () => Promise.resolve(document.location.origin);

export const getLocalServerPort = () => _getLocalServerPort();
