import { css } from 'app/utils/theme';

const font = require('../../assets/fonts/SignPainterHouseScriptRegular/signpainter_housescript_regular-webfont.woff2');
const font2 = require('../../assets/fonts/SignPainterHouseScriptRegular/signpainter_housescript_regular-webfont.woff');

export default css`
  @font-face {
    font-family: 'signpainter_housescriptRg';
    src: url(${font}) format('woff2'), url(${font2}) format('woff');
    font-weight: normal;
    font-style: normal;
  }
`;
