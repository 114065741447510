import React from 'react';
import Text, { TextProps } from 'app/components/atoms/Text';
import IconButton from 'app/components/molecules/IconButton';
import styled from 'app/utils/theme';
import Tooltip from 'app/components/molecules/Tooltip';

export interface TextEditProps extends TextProps {
  onTextChange?: Function;
  value?: string;
  tooltip?: string;
}

const TextEditInput = styled.div`
  position: relative;

  input {
    width: 100%;
  }
  ${IconButton} {
    position: absolute;
    right: 5%;
    top: 20%;

    :hover {
      color: ${(props) => props.theme.text.colors.success};
    }
  }
`;

const TextEditView = styled.div`
  display: flex;
  ${IconButton} {
    visibility: hidden;
  }

  :hover ${IconButton} {
    visibility: visible;
  }
  :hover ${Text} {
    color: ${(props) => props.theme.text.colors.active};
    text-decoration: underline;
    cursor: pointer;
  }
`;
export default class TextEdit extends React.Component<TextEditProps> {
  state = { isEdit: false, value: null };

  toggleEdit = () => {
    this.setState({ isEdit: !this.state.isEdit });
  };

  componentWillMount() {
    this.setState({ value: this.props.value });
  }

  // componentWillReceiveProps(nextProps: TextEditProps) {
  //   this.setState({ value: nextProps.value });
  // }
  render() {
    const { isEdit } = this.state;
    const { tooltip, children, value, ...props } = this.props;
    const { onTextChange } = this.props;

    if (isEdit && onTextChange) {
      return (
        <TextEditInput>
          <input
            value={this.state.value || ''}
            onChange={(e: any) => {
              this.setState({ value: e.currentTarget.value });
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                this.toggleEdit();
                onTextChange(this.state.value);
              }
              if (e.key === 'Escape') {
                this.toggleEdit();
              }
            }}
          />
          <IconButton
            tooltip={{ text: 'Save', position: 'top' }}
            size="sm"
            success
            icon="check-circle"
            onClick={this.toggleEdit}
          />
        </TextEditInput>
      );
    }

    const component = (
      <TextEditView>
        <Text {...props} pRight={5}>
          {children}
        </Text>
        {onTextChange &&
          <IconButton
          tooltip={{ text: 'Edit name', position: 'top' }}
          size="xs"
          icon="pencil-alt"
          onClick={this.toggleEdit}
        />}
      </TextEditView>
    );

    if (tooltip) {
      return (
        <Tooltip placement="top" overlay={<Text>{tooltip}</Text>} mouseEnterDelay={0.3}>
          {component}
        </Tooltip>
      );
    }
    return component;
  }
}
