import { Middleware } from 'redux';
import { ActionTypes } from 'app/redux/actionTypes/index';
import { sendWindowEvent } from 'app/redux/actions';

export const hideWindowOnScreenshot: Middleware = ({ dispatch }) => (next) => async (action) => {
  if (action.type === ActionTypes.TAKE_SCREENSHOT.STARTED) {
    dispatch(sendWindowEvent({ eventName: 'window-hide' }));
  }

  if (
    action.type === ActionTypes.TAKE_SCREENSHOT.COMPLETED ||
    action.type === ActionTypes.TAKE_SCREENSHOT.FAILED
  ) {
    dispatch(sendWindowEvent({ eventName: 'window-show' }));
  }

  return next(action);
};
