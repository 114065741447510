const KEY = 'SCREENTAPE_STATE';
export function loadState() {
  try {
    const serializedState = localStorage.getItem(KEY);
    if (!serializedState) return undefined;
    return JSON.parse(serializedState);
  } catch (e) {
    console.log('error loading state', e);
    return undefined;
  }
}

export async function saveState(state: any) {
  try {
    const serializedState = JSON.stringify({
      ...state,
      recordings: undefined
    });

    localStorage.setItem(KEY, serializedState);
  } catch (e) {
    console.log('error saving state', e);
    // Ignore
  }
}
