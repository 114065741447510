export enum NavbarItem {
  MINE = 'mine',
  SHARED_WITH_ME = 'SHARED_WITH_ME'
}

export enum SortBy {
  BY_DATE_ASC = 'BY_DATE_ASC',
  BY_DATE_DESC = 'BY_DATE_DESC',
  BY_LENGTH_ASC = 'BY_LENGTH_ASC',
  BY_LENGTH_DESC = 'BY_LENGTH_DESC',
  BY_NAME_ASC = 'BY_NAME_ASC',
  BY_NAME_DESC = 'BY_NAME_DESC'
}

export enum UploadingStatus {
  PENDING = 'PENDING',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  CANCELED = 'CANCELED'
}

export interface ReduxActionModel {
  type: string;
}

export interface CommentModel {
  text: string;
  date: Date;
  time?: number | null;
}

export interface UploadingModel {
  progress: number; // 0 to 100%,
  status: UploadingStatus;
  error?: string | null;
  id: string;
  name: string;
  thumbnail: string;
  duration: number;
  url: string;
  size: number;
  mimeType: string;
  uploader?: any;
  createdAt: string;
  meta: any;
}

export interface ThumbnailModel {
  fileId: string;
  thumbnail: string;
}

// export interface RecordingModel {
//   inProgress: boolean;
//   isPaused: boolean;
//   startedAt: Date;
//   preRecordTimer: number; // seconds to show 4 - 3 - 2 - 1 start!
//   name: string;
// }

export namespace StoreModel {
  // https://developers.google.com/drive/api/v3/reference/files#resource
  export interface RecordingModel {
    id: string;
    name: string;
    notes?: Array<string>;
    comments?: Array<CommentModel>;
    duration?: string;
    thumbnail: string;
    gif: string;
    mimeType: string;
    uploading?: UploadingModel | null;
    deleting?: UploadingModel | null;
    createdAt?: string;
    meta?: any;
    url: string;
    sharedUrl: string;
    downloadUrl: string;
    size: number;
    ownerEmail: string;
    isFavorite: boolean;
    isUpdating: boolean;
    sharedWith: Array<{ email: string }>;
    accessType: string | 'PRIVATE' | 'LINK';
    vttUrl?: string;
    transcription?: Array<any>;
  }

  export interface ApiFileModel {
    id: string,
    meta: RecordingModel,
    sharedWith: Array<{email: string}>
  }

  export enum ActiveRecordingState {
    STARTED,
    STOPPED,
    PAUSED,
    CANCELED,
    FAILED
  }
  export interface ActiveRecording {
    id: string;
    state: ActiveRecordingState;
    name: string;
    time: number; //seconds
    loading: boolean;
    error: any;
    showTimerModal: boolean;
    blob: any;
    thumbnail: string | null;
    toggleEditScreenshot: boolean;
    size: number;
  }

  export type RecordingsState = {
    isLoading: boolean;
    count: number;
    page: number;
    pageCount: number;
    total: number;
    data: Array<RecordingModel>;
    sharedWithMe: Array<RecordingModel>;
    error?: any;
    rootFolder?: any | null | undefined;
    playRecording?: RecordingModel | null | undefined;
    searchText?: string | null | undefined;
    sortOption?: number | null | undefined;
    shareRecordFileId: string | null;
    isLightMode: boolean;
    isFavoritesFilter: boolean;
    selectedVisibleRecordingOption: string;
  }; // list of recordings available on the main screen
  export type GoogleSignIn = any;

  export type RecordingSource = {
    id: string;
    name: string;
    thumbnail: string;
  };

  export type RecordingSettings = {
    sound: boolean;
    source: any;
    type: string;
    showSettingsModal: boolean;
    speakersDeviceId?: string;
    webCamDeviceId?: string;
    microphoneDeviceId?: string;
    devices: Array<{ label: string; value: string }>;
  };

  export type RecordingSelector = {
    show: boolean;
    sources: Array<RecordingSource>;
    filter: string;
    settings: RecordingSettings;
    isLoading: boolean;
    numberOfDisplays: number;
  };
}

export type StoreModel = {
  readonly googleSignIn: StoreModel.GoogleSignIn;
  readonly recordings: StoreModel.RecordingsState;
  readonly recordingSelector: StoreModel.RecordingSelector;
  readonly activeRecording: StoreModel.ActiveRecording; // recording that takes place?
  readonly uploads: Array<UploadingModel>;
  readonly thumbnails: Array<ThumbnailModel>;
  readonly toastr: any;
  /*
  readonly playBackRecording: RecordingModel | null; // recording that will show up in modal
  readonly activeNavbarItem: NavbarItem;
  readonly searchText: string;
  readonly activeSort: SortBy;
  readonly showRecordingSharingModal: boolean;
  */
};

export type StartRecordingProps = {
  id: string;
  type: string;
  sourceId: string;
  audio?: boolean;
  stream?: any;
  onExternalStop?: Function;
  onRecordingSize?: Function;
  settings: StoreModel.RecordingSettings,
};

export type GoogleStorageConstrParams = { access_token: string };

export type FileBlob = {
  name: string;
  mimetype: string;
  blob: Blob;
};

export type StorePayload = {
  file: FileBlob;
  thumbnail?: FileBlob | null;
  gif?: FileBlob | null;
};

export type StoreResponse = {
  url: string;
  sharedUrl: string;
  downloadUrl: string;
  thumbnail: string;
  gif: string;
  meta: any;
  storage: { id: number; name: string };
  accessType: string;
};


export type SignedUrlsType = { file: string; thumbnail: string, gif: string; };

export type MediaUploadCreateType = {
  file: FileBlob;
  signedUrl: string;
  progressCb?: Function;
  headers?: object;
};
