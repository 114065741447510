import { isWebBuild } from "app/config";

/**
 * on web builds we mock the checkForUpdates
 */
const _checkForUpdates = !isWebBuild
  ? // needs to by window.require to avoid conflict with browserify require
    // https://github.com/electron/electron/issues/7300
    (<any>window).require('electron').remote.getGlobal('manuallyCheckForUpdates')
  : (options: any, cb: Function) => cb(Promise.reject('is web build'));

export const checkForUpdates = () => _checkForUpdates();
