import * as React from 'react';
import { connect } from 'react-redux';
import { googleSignIn } from 'app/redux/actions';
import { getNewUserSignInFailure, isAuthLoading, getStateProp } from 'app/redux/reducers/googleSignin';
import ReactGoogleLogin, { useGoogleLogin } from 'react-google-login';
import { GOOGLE_CLIENT_ID, GOOGLE_SCOPE } from 'app/config';
import styled from 'app/utils/theme';
import { Loader } from 'semantic-ui-react';

interface IMapStateToProps {
  newUserSignInFailure?: string;
  isAuthLoading?: boolean;
  redirectUri?: string;
}
interface IMapDispatchToProps {
  googleSignIn?: (data: any) => void;
}

interface IOwnProps {}

type GoogleLoginProps = IMapStateToProps & IMapDispatchToProps & IOwnProps;

const ButtonWrapper = styled.div`
  button {
    width: 408px;
    div {
      width: 40px;
      height: 40px;
      padding: 12px !important;
      margin-left: 1px;
      svg {
        transform: scale(1.7);
      }
    }
    span {
      display: block;
      font-family: montserratlight;
      font-style: normal;
      font-weight: 400 !important;
      font-size: 17px;
      line-height: 12px;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

const GoogleLogin = (props: GoogleLoginProps) => {
  const { newUserSignInFailure, googleSignIn = () => {}, isAuthLoading, redirectUri } = props;
  if (newUserSignInFailure) {
    const { signIn } = useGoogleLogin({
      onSuccess: (d: any) => googleSignIn({ ...d, redirectUri }),
      onAutoLoadFinished: (d: any) => {
        console.log('from hook onAutoLoadFinished', d);
        console.log('finished loading');
      },
      clientId: GOOGLE_CLIENT_ID,
      redirectUri,
      onFailure: (d: any) => {
        console.log('from hook onFailure', d);
        googleSignIn({ ...d });
      },
      scope: GOOGLE_SCOPE,
      accessType: 'offline',
      responseType: 'code',
      prompt: 'consent',
      uxMode: 'dark',
      // loginHint: newUserSignInFailure,
    });

    signIn();
  }

  return (
    <ButtonWrapper>
      {isAuthLoading && <Loader active />}
      <ReactGoogleLogin
        clientId={GOOGLE_CLIENT_ID}
        redirectUri={redirectUri}
        scope={GOOGLE_SCOPE}
        responseType="code"
        buttonText={`SignIn with Google`}
        accessType="offline"
        {...{ theme: 'dark' }}
        prompt="select_account"
        disabled={isAuthLoading}
        onSuccess={(d) =>
          googleSignIn(
            // prompt="consent"
            {
              ...d,
              redirectUri,
            }
          )
        }
        onFailure={(d) => googleSignIn({ ...d })}
      />
    </ButtonWrapper>
  );
};

export default connect<IMapStateToProps, IMapDispatchToProps, IOwnProps>(
  (state) => ({
    newUserSignInFailure: getNewUserSignInFailure(state),
    isAuthLoading: isAuthLoading(state),
    redirectUri: getStateProp(state, 'redirectUri'),
  }),
  (dispatch: any) => ({
    googleSignIn: (data: any) => dispatch(googleSignIn(data)),
  })
)(GoogleLogin);
