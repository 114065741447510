import React from 'react';
import { Checkbox as SemanticCheckbox } from 'semantic-ui-react';
import styled from 'app/utils/theme';

import Text from '../Text';

export interface CheckboxProps {
  checked: boolean;
  onClick: Function;
  label: string;
}

const Checkbox = ({ label, checked, onClick }: CheckboxProps) => (
  <Checkbox.Wrapper>
    <SemanticCheckbox label="" checked={checked} onClick={() => onClick()} />
    <Text ssm active semiBold>
      {label}
    </Text>
  </Checkbox.Wrapper>
);

Checkbox.Wrapper = styled.div`
  display: flex;
`;

export default Checkbox;
