import React from 'react';
import Logo from 'app/components/molecules/Logo';
import Page from 'app/components/pages/Page';
import GoogleLogin from 'app/components/organisms/GoogleLogin/index';
import RedirectIfAuthenticated from 'app/components/organisms/RedirectIfAuthenticated';
import Text from 'app/components/atoms/Text';
import EmptyView from 'app/components/molecules/EmptyView';

const logoDark = require('../../../assets/all logo/login_background_light.png');

export class GoogleLoginPage extends Page<any> {
  render() {
    return <RedirectIfAuthenticated>
        <EmptyView image={logoDark}>
          <Logo fs={80} active semiBold logo pBottom={10} />
          <Text pasive l pBottom={50}>
            We Help You Record Your Meetings
          </Text>
          <GoogleLogin />
        </EmptyView>
      </RedirectIfAuthenticated>;
  }
}
