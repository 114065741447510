import get from 'lodash/get';
import { AnyAction } from 'redux';
import { StoreModel, ThumbnailModel } from 'app/models/StoreModel';
import { ActionTypes } from 'app/redux/actionTypes/index';

export const STATE_KEY = 'thumbnails';

export const reducer = (state: Array<ThumbnailModel> = [], action: AnyAction) => {
  switch (action.type) {
    case ActionTypes.UPLOAD_RECORDING: {
      const {id, thumbnail} = action.payload.data;

      return [...state, { fileId: id, thumbnail }];
    }
    case `${ActionTypes.UPLOAD_RECORDING}_SUCCESS`: {
      const thumbnail = get(action, 'meta.previousAction.payload.data.uploading.thumbnail');
      const recording = action.payload.data;

      return [...state, { fileId: recording.id, thumbnail }];
    }
    default:
      return state;
  }
};

export const getFileThumbnailById = (state: StoreModel, id: string) => {
  const item = state[STATE_KEY].find((t) => t.fileId === id);
  return get(item, 'thumbnail', null) || get(item, 'meta.driveFile.thumbnail', null);
};
