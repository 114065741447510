import * as React from 'react';
import get from 'lodash/get';
import { TitleBar as MacTitleBar } from 'react-desktop/macOs';
import { TitleBar as WinTitleBar } from 'react-desktop/windows';
import { connect } from 'react-redux';
import styled from 'app/utils/theme';
import { minimizeWindow, maximizeWindow, closeWindow, resizeToWindow } from 'app/redux/actions';
import { PRODUCT_NAME } from 'app/config';

interface IOwnProps {
  refreshToken?: string;
  minimizeWindow?: Function;
  maximizeWindow?: Function;
  closeWindow?: Function;
  resizeToWindow?: Function;
  isControlMode?: Boolean;
}

const isMac = () => {
  const platform = get(window, 'process.platform', 'darwin');
  return platform === 'darwin';
};

const StyledTitleBar = styled.div<{ isControlMode: boolean }>`
  position: fixed;
  display: block;
  width: 100vw;
  height: 25px;
  z-index: 10000000;
  opacity: ${props => props.isControlMode ? 0 : 1};
  /* :hover {
    background-color: ${props => props.theme.modal.contentColor};
  } */
  background-color: ${props => props.theme.topBar.backgroundColor}
`;
@connect<any, IOwnProps>(
  () => ({
    isControlMode: get(document, 'location.pathname', '') === '/recording-controls',
  }),
  (dispatch) => ({
    minimizeWindow: () => dispatch(minimizeWindow()),
    maximizeWindow: () => dispatch(maximizeWindow()),
    closeWindow: () => dispatch(closeWindow()),
    resizeToWindow: () => dispatch(resizeToWindow())
  })
)
class TitleBar extends React.Component<any> {
  state = {
    isFullscreen: false,
    isMaximized: false
  };

  toggleResizeClick = () => {
    if (isMac()) {
      this.state.isFullscreen ? this.props.resizeToWindow() : this.props.maximizeWindow();
      this.setState({ isFullscreen: !this.state.isFullscreen });
      return;
    }

    this.state.isMaximized ? this.props.resizeToWindow() : this.props.maximizeWindow();
    this.setState({ isMaximized: !this.state.isMaximized });
  };

  render() {
    const TitleComponent = isMac() ? MacTitleBar : WinTitleBar;
    const { minimizeWindow, closeWindow, isControlMode = false } = this.props;
    return (
      <StyledTitleBar isControlMode={isControlMode}>
        <TitleComponent
          controls
          transparent
          inset
          title={isMac() ? '' : PRODUCT_NAME}
          isFullscreen={this.state.isFullscreen}
          isMaximized={this.state.isMaximized}
          onCloseClick={closeWindow}
          onMinimizeClick={minimizeWindow}
          onResizeClick={this.toggleResizeClick}
          onMaximizeClick={this.toggleResizeClick}
          onRestoreDownClick={this.toggleResizeClick}
          theme="dark"
          background={this.props.theme.backgroundImage}
        />
      </StyledTitleBar>
    );
  }
}

export default styled(TitleBar).attrs({
  theme: (props: any) => props.theme
})``;
