import React from 'react';
import { connect } from 'react-redux';
import {
  toggleSendMessageModal,
  sendMessage,
} from 'app/redux/actions';
import Text from 'app/components/atoms/Text';
import Modal from 'app/components/molecules/Modal';
import Button from 'app/components/molecules/Button';
import TextArea from 'app/components/atoms/TextArea';
import { toastr } from 'react-redux-toastr';

import styled from 'app/utils/theme';

import { PRODUCT_NAME } from 'app/config';
import Logo from 'app/components/molecules/Logo';

const showWarning = ({ message }: any) => {
  toastr.warning(`${PRODUCT_NAME} notification`, message, {
    icon: <Logo />,
    timeOut: 4000,
    attention: true
  });
};


const Wrapper = styled.div`
  position: relative;
  width: 40vw;
  background-color: ${(props: any) => props.theme.modal.contentColor};
  box-shadow: ${(props: any) => props.theme.modal.border};
  border-radius: 4px;
  padding: 50px 15px 50px 30px;
  margin-left: auto;
  margin-right: auto;

  #close-modal {
    position: absolute;
    top: 5%;
    right: 3%;
  }
`;

interface ModalProps {
  toggleSendMessageModal?: Function;
  sendMessage?: Function;
}

class SendMessageModal extends React.Component<ModalProps> {
  state = {
    touched: false,
    isLoading: false,
    titleError: null,
    message: '',
    title: ''
  };

  onMessageChange = (message: any) => {
    this.setState({ message });
  };

  onTitleChange = (title: any) => {
    this.setState({ title });
  };

  onSave = async () => {
    const {
      toggleSendMessageModal = () => {},
      sendMessage = () => {},
    } = this.props;

    if (this.state.title.length < 5) {
      this.setState({ titleError: 'Title is required and should have at least 5 charachters' });
      showWarning({ message: 'Title is required and should have at least 5 charachters' });
      return;
    }

    this.setState({
      isLoading: true
    });

    const { title, message } = this.state;

    sendMessage(title, message);

    this.setState({
      isLoading: false
    });

    toggleSendMessageModal();
  };

  render() {
    const { toggleSendMessageModal = () => {} } = this.props;
    const { titleError, title, message } = this.state;

    return (
      <Modal hideInnerWrapper onClose={toggleSendMessageModal}>
        <Wrapper>
          <Text bold active id="close-modal" onClick={() => toggleSendMessageModal()}>
            X
          </Text>
          <Text sm semiBold active pBottom={20}>
            Have an issue or want to give us feedback? Go ahead and shoot us a message:
          </Text>
          <div style={{ width: '100%', height: '50px' }}>
            <TextArea
              value={title}
              placeholder="Add a Title (required)"
              onChange={this.onTitleChange}
            />
            <Text s veryLight active error>
              {titleError}
            </Text>
          </div>
          <Text pTop={20} />
          <div style={{ width: '100%', height: '200px' }}>
            <TextArea
              value={message}
              placeholder="Add a message (optional)"
              onChange={this.onMessageChange}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: '30px' }}>
            <Button
              loading={this.state.isLoading}
              active={!this.state.isLoading}
              onClick={this.onSave}
            >
              Send
            </Button>
          </div>
        </Wrapper>
      </Modal>
    );
  }
}

export default connect(
  (state) => ({}),
  (dispatch) => ({
    toggleSendMessageModal: () => dispatch(toggleSendMessageModal()),
    sendMessage: (title: string, message: string) => dispatch(sendMessage(title, message) as any)
  })
)(SendMessageModal);
