export * from './sort';
export const GOOGLE_API_URI = 'https://www.googleapis.com';
export const GOOGLE_CLIENT_ID =
  '900532494131-jf81bol2dg4q7a2gqs06eshunhqj6u55.apps.googleusercontent.com';
export const GOOGLE_SCOPE =
  'https://www.googleapis.com/auth/drive.file https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile';

export const PRODUCT_NAME = 'ScreenTape';
export const VIDEO_MIME = 'video/webm';
export const AUDIO_MIME = 'audio/webm';
export const IMAGE_MIME = 'image/png';
export const GIF_MIME = 'image/gif';


export const FOLDER_MIME = 'application/vnd.google-apps.folder';
export const DRIVER_FOLDER_NAME = `ScreenTape Files (Don't Touch)`;

export const AUDIO_THUMBNAIL_URL =
  'https://screentape-prod-static.s3.amazonaws.com/audio_thumbnail.png';

export const GOOGLE_ANALYTICS_ID = 'UA-149171012-1';
export const GOOGLE_WEB_ANALYTICS_ID = 'UA-149171012-4';

export const TERMS = 'https://screentape.io/terms/index.html';
export const POLICY = 'https://screentape.io/privacy/index.html';
export const WEB_PAGE = 'https://screentape.io';

export const StorageTypes = {
  BACKBLAZE: { id: 3, name: 'BLACKBLAZE_STORAGE' },
  GOOGLE_DRIVE: { id: 1, name: 'GOOGLE_DRIVE_STORAGE' },
  S3: { id: 2, name: 'AWS_S3_STORAGE' }
};

export enum SubscriptionTypes {
  FREE = "FREE",
  PAID = "PAID",
};

export enum FileAccessType {
  LINK = "LINK",
  PRIVATE = "PRIVATE"
};

export enum RecordActionType {
  VIDEO = 'VIDEO',
  AUDIO = 'AUDIO',
  SCREENSHOT = 'SCREENSHOT',
  WEBCAM = 'WEBCAM',
  VIDEOANDWEBCAM = 'VIDEOANDWEBCAM',
};

export const isWebBuild = !!(process.env.IS_WEB_BUILD || process.env.IS_PLAYER_BUILD);

export const getGoogleAnalyticsId = () => {
  const analyticsId = isWebBuild ? GOOGLE_WEB_ANALYTICS_ID : GOOGLE_ANALYTICS_ID;
  return analyticsId;
};

export const MAX_S3_RECORDING_SIZE = 4.9 * 1024 * 1024 * 1024; // 50gb on AWS

