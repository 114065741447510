import React from 'react';
import styled from 'app/utils/theme';
import IconButton from 'app/components/molecules/IconButton';

interface ModalProps {
  children: JSX.Element | JSX.Element[];
  onClose: Function;
  hideInnerWrapper?: Boolean;
}

const Modal = ({ children, onClose, hideInnerWrapper }: ModalProps) => {
  if (hideInnerWrapper) {
    return (
      <Modal.Wrapper>
        <Modal.Background />
        <IconButton active size="2x" icon="times-circle" onClick={onClose} />
        <Modal.ThinInnerWrapper>
          <Modal.Content>{children}</Modal.Content>
        </Modal.ThinInnerWrapper>
      </Modal.Wrapper>
    );
  }
  return (
    <Modal.Wrapper>
      <Modal.Background />
      <Modal.InnerWrapper>
        <IconButton active size="2x" icon="times-circle" onClick={onClose} />
        <Modal.Content>{children}</Modal.Content>
      </Modal.InnerWrapper>
    </Modal.Wrapper>
  );
};

Modal.Wrapper = styled.div`
  position: fixed;
  width: 100vw;
  height: ${() => document.body.scrollHeight}px;
  overflow: hidden;
  z-index: 1000;
  top: 0;

  ${IconButton} {
    background-color: black;
    border-radius: 40px;
    position: absolute;
    right: 15px;
    top: 20px;
    z-index: 1001;
  }
`;

Modal.Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.modal.backgroundColor};
  opacity: 0.8;
`;

Modal.ThinInnerWrapper = styled.div`
  position: relative;
  width: 90%;
  border-radius: 5px;
  left: 5%;
  top: 30px;
`;

Modal.InnerWrapper = styled.div`
  position: relative;
  width: 90%;
  height: 90vh;
  background-color: ${(props) => props.theme.modal.contentColor};
  box-shadow: ${(props) => props.theme.modal.border};
  border-radius: 5px;
  left: 5%;
  top: 30px;

  padding: 50px 15px 50px 30px;
`;

Modal.Content = styled.div`
  position: relative;
  height: 95%;
`;
Modal.InnerWrapperBottom = styled.div`
  position: absolute;
  width: 95%;
  bottom: 10px;
`;

export default Modal;
