import * as React from 'react';
import { connect } from 'react-redux';
import Text from 'app/components/atoms/Text';
import ActionButtons from 'app/components/organisms/ActionButtons';
import EmptyView from 'app/components/molecules/EmptyView';
import Settings from 'app/components/organisms/Settings';
import Notifications from 'app/components/organisms/Notifications';
import Logo from 'app/components/molecules/Logo';

import styled from 'app/utils/theme';

const emptyList = require('../../../../assets/all logo/empty_list.png');

const TopBarWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 60px 0 60px;
  align-items: center;
`;

const ActionsWrapper = styled.div``;

interface EmptyRecordingsListProps {
}

const EmptyRecordingsList = ({
}: EmptyRecordingsListProps) => (
  <EmptyView image={emptyList} style={{ backgroundPosition: '-70px 150px' }}>
    <TopBarWrapper>
      <Logo xxl opacity={1} />
      <Settings />
    </TopBarWrapper>

    <Text active xxl bold pBottom={40} pTop={100}>
      You have no recordings
    </Text>
    <Text m pBottom={100}>
      Start recording and never forget what she said
    </Text>

    <ActionsWrapper>
      <ActionButtons
        big
        size="7x"
      />
    </ActionsWrapper>
    <Notifications />
  </EmptyView>
);

export default connect(null, (dispatch) => ({
}))(EmptyRecordingsList);
