import { Middleware } from 'redux';
import isString from 'lodash/isString';

import ReactGA from 'react-ga';
import { ActionTypes } from 'app/redux/actionTypes';
import EVENTS from 'app/config/tracking';

/**
 * Intercept recording complete and initiate google drive file upload
 */
export const actionTracking: Middleware = (store) => (next) => (action) => {
  let event = null;
  switch (action.type) {
    case `${ActionTypes.GOOGLE_SIGN_IN}_SUCCESS`:
      event = {
        category: EVENTS.CATEGORIES.APPLICATION,
        action: EVENTS.ACTIONS.LOGIN,
        label: 'SUCCESS'
      };
      break;
    case `${ActionTypes.GOOGLE_SIGN_IN}_FAIL`:
      event = {
        category: EVENTS.CATEGORIES.APPLICATION,
        action: EVENTS.ACTIONS.LOGIN,
        label: 'FAILED'
      };
      break;
    case `${ActionTypes.GOOGLE_SIGN_OUT}`:
      event = {
        category: EVENTS.CATEGORIES.APPLICATION,
        action: EVENTS.ACTIONS.LOGOUT
      };
      break;
    case `${ActionTypes.CHECK_FOR_UPDATES}`:
      event = {
        category: EVENTS.CATEGORIES.APPLICATION,
        action: EVENTS.ACTIONS.CHECK_FOR_UPDATES
      };
      break;
    default:
      event = {
        category: 'REDUX',
        action: isString(action.type) ? action.type : JSON.stringify(action.type)
      };
  }

  if (event) {
    ReactGA.event({
      ...event
      // nonInteraction: true,
    });
  }
  return next(action);
};

/*
Track events in prod release vs dev release

1. Extract pages
2. Extract modals
3. Extract event categories, actions, labels, values, non-intercation
4. Any timing to measure
5. ReactGA.exception ?...


Categories:
1. Application
  actions: open, login start, login fail, login complete, logout, close, quit, check for updates
  values: count [?!] or !!isFirstTime

2. Recording
  actions: start video, start audio, select source, cancel select source, cancel timer, pause, stop, cancel
  values: count [!?] or !!isFirstTime

3. Search
actions: search

4. Order
actions: order
labels: by date, by time, by name
values: count or isFirstTime

5. ListView
actions: view mine, view shared with me

6. Playback
actions: play, pause, close

7.

Posible Tracked events:
==========
- login start
- login completed
==========
- video record click
- video record select source
- video record cancel select source
- video record cancel record timer
- video record paused
- video record stopped
- video record canceled
==========
- search recording
==========
- order recordings by date/duration/name
==========
- view mine recordings
- view shared recordings
==========
- check for updates
- logout click
==========
- start play recording
- stop play recording
- pause play recording

*/
