import * as React from 'react';
import { Progress } from 'semantic-ui-react';
import styled from 'app/utils/theme';
import TextEdit from 'app/components/molecules/TextEdit';
import Text from 'app/components/atoms/Text';
import { StoreModel, UploadingStatus } from 'app/models/StoreModel';
import IconButton from 'app/components/molecules/IconButton';
import Image from 'app/components/atoms/Image';

import { IMAGE_MIME } from 'app/config';

export interface CardProps extends StoreModel.RecordingModel {
  onDelete: Function;
  onPlay: Function;
  onTitleChange?: Function;
  onShare?: Function;
  onDownload?: Function;
  onStopUpload?: Function;
  onRetryUpload?: Function;
  onFavorite?: Function;
}
class Card extends React.Component<CardProps> {
  static Play: any;
  static Wrapper: any;
  static Icons: any;
  static ImageContainer: any;
  static ImageOnHover: any;
  static DefaultImage: any;
  static Favorite: any;
  static TextContainer: any;
  static DropDownWrapper: any;

  state: any = {
    isPlayHoover: false
  };

  render() {
    const {
      thumbnail,
      gif,
      name,
      onPlay,
      onFavorite,
      onTitleChange,
      createdAt,
      uploading,
      onStopUpload,
      onRetryUpload,
      isFavorite,
      isUpdating,
      mimeType,
      onShare,
      onDelete,
      onDownload,
      meta: { formatedDuration, formatedSize }
    } = this.props;
    const isScreenshot = mimeType === IMAGE_MIME;
    return (
      <Card.Wrapper isFavorite={isFavorite}>
        <Card.Icons>
          {/* {onDelete && <IconButton
            icon="trash-alt"
            tooltip={{ text: 'Delete this recording', position: 'top' }}
            size="2x"
            onClick={onDelete}
            opacity={0.4}
          />} */}
          &nbsp;{' '}
          {onFavorite && (
            <IconButton
              opacity={!isFavorite && !isUpdating && '0.7'}
              icon={isUpdating ? 'spinner' : 'star'}
              spin={isUpdating}
              orange={isFavorite}
              tooltip={{ text: 'Add to favorites', position: 'top' }}
              size="2x"
              onClick={() => onFavorite()}
            />
          )}
        </Card.Icons>
        <Card.ImageContainer>
          <Card.Play>
            <IconButton size="3x" icon={isScreenshot ? 'eye' : 'play'} onClick={onPlay} />
          </Card.Play>

          {gif ? (
            <>
              <Card.DefaultImage src={thumbnail} />
              <Card.ImageOnHover src={gif} />
            </>
          ) : (
            <Image src={thumbnail} />
          )}

          {uploading && (
            <Progress
              progress="percent"
              total="100"
              value={uploading.progress}
              error={
                uploading.status === UploadingStatus.FAILED ||
                uploading.status === UploadingStatus.CANCELED ||
                uploading.status === UploadingStatus.PENDING
              }
              success={uploading.status === UploadingStatus.IN_PROGRESS}
              attached="bottom"
            />
          )}
        </Card.ImageContainer>
        <Card.TextContainer>
          <div>
            <TextEdit
              active
              sm
              maxSize={20}
              onTextChange={onTitleChange}
              value={name}
              tooltip={name}
            >
              {name}
            </TextEdit>
            <Text s pTop={3} pasive>
              {' '}
              {!isScreenshot && `${formatedDuration} • `}
              {`${createdAt} • ${formatedSize}`}
            </Text>
          </div>
          <Card.DropDownWrapper>
            {onStopUpload && (
              <IconButton
                tooltip={{ text: 'Stop upload', position: 'top' }}
                size="2x"
                mRight={5}
                icon="stop-circle"
                onClick={onStopUpload}
              />
            )}
            {onRetryUpload && (
              <IconButton
                tooltip={{ text: 'Retry upload', position: 'top' }}
                size="2x"
                icon="redo-alt"
                mRight={5}
                error
                onClick={onRetryUpload}
              />
            )}
            {onDelete && (
              <IconButton
                icon="trash-alt"
                tooltip={{ text: 'Delete this recording', position: 'top' }}
                size="2x"
                onClick={onDelete}
                opacity={1}
                mRight={15}
              />
            )}
            {onShare && (
              <IconButton
                icon="share-square"
                tooltip={{ text: 'Share this recording', position: 'top' }}
                size="2x"
                onClick={onShare}
                opacity={1}
                mRight={10}
              />
            )}
            <IconButton
              icon="download"
              tooltip={{ text: 'Download this recording', position: 'top' }}
              size="2x"
              onClick={onDownload}
              opacity={1}
            />
          </Card.DropDownWrapper>
        </Card.TextContainer>
      </Card.Wrapper>
    );
  }
}

Card.Play = styled.div`
  width: 100%;
  height: calc(30vw * 9 / 16);
  display: none;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 1;

  background-color: rgba(0, 0, 0, 0.1);

  ${IconButton} {
    width: 50px;
    height: 50px;
    background-color: transparent !important;
  }
`;

Card.DropDownWrapper = styled.div`
  display: none;
  align-items: center;
`;

Card.Wrapper = styled.div<{ isFavorite: boolean }>`
  position: relative;
  background-color: ${(props) => props.theme.card.backgroundColor};
  box-shadow: ${(props) => props.theme.card.shadow};
  border-radius: 5px;
  min-width: 240px;
  min-height: 200px;

  @media only screen and (min-width: 600px) {
    width: 45vw;
  }
  @media only screen and (min-width: 768px) {
    width: 29vw;
  }
  @media only screen and (min-width: 1050px) {
    width: 30vw;
  }
  @media only screen and (min-width: 1500px) {
    width: 23vw;
  }
  @media (pointer: none), (pointer: coarse) {
    width: 80vw;
  }

  ${IconButton} {
    display: none;
  }

  .fa-star {
    ${(props) => props.isFavorite && 'display: flex;'}
  }
  .fa-spinner {
    display: flex;
  }

  :hover {
    ${IconButton} {
      display: flex;
    }
    ${Card.DropDownWrapper} {
      display: flex;
    }
  }
`;

Card.Icons = styled.div`
  position: absolute;
  top: 5%;
  right: 3%;
  z-index: 999;
  display: flex;
  justify-content: row;
`;

Card.ImageOnHover = styled(Image)`
  display: none;
`;

Card.DefaultImage = styled(Image)``;

Card.ImageContainer = styled.div<{ gif: string }>`
  @media only screen and (min-width: 600px) {
    height: calc(45vw * 9 / 16);
  }
  @media only screen and (min-width: 768px) {
    height: calc(29vw * 9 / 16);
  }
  @media only screen and (min-width: 1050px) {
    height: calc(30vw * 9 / 16);
  }
  @media only screen and (min-width: 1500px) {
    height: calc(23vw * 9 / 16);
  }
  @media (pointer: none), (pointer: coarse) {
    height: calc(80vw * 9 / 16);
  }

  :hover ${Card.Play} {
    display: flex;
  }
  :hover ${Card.ImageOnHover} {
    display: block;
  }

  :hover ${Card.DefaultImage} {
    display: none;
  }

  ${Card.DefaultImage} {
    border-radius: 5px 5px 0 0;
    background-size: contain;
    background-position: center center;
    background-color: black;
  }
  .ui.progress .bar {
    position: absolute;
    color: ${(props) => props.theme.text.colors.orange};
    height: 0.2em;
    .progress {
      display: none;
    }
  }
`;

Card.TextContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 8px 15px 10px 15px;
`;

export default Card;
