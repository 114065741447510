import React, { MouseEventHandler } from 'react';
import styled from 'app/utils/theme';
import 'tui-image-editor/dist/tui-image-editor.css';
import Button from 'app/components/molecules/Button';

const ImageEditor = require('tui-image-editor');
export interface ImageProps {
  src: string;
  gallery?: boolean;
  edit?: boolean;
  style?: any;
  onSaveImageUpdate?: Function;
  onMouseOver?: MouseEventHandler<HTMLDivElement> | undefined;
  onMouseOut?: MouseEventHandler<HTMLDivElement> | undefined;
}

const Image = styled.div.attrs((props) => ({
  referrerpolicy: 'no-referrer'
}))<ImageProps>`
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url(${(props) => props.src});
  background-size: ${(props) => (props.gallery ? 'auto' : 'contain')};
  background-repeat: no-repeat;
  background-position: ${(props) => (props.gallery ? 'center' : 'center')};
  background-color: ${(props) => 'rgba(0, 0, 0)'};
`;



const myTheme = {
  'header.display': 'none'
};

export default styled(({ edit, src, onSaveImageUpdate = () => {}, ...props }: ImageProps) => {
  if (edit) {
    let imageEditor: any = null;
    return (
      <>
        <div
          onMouseOver={props.onMouseOver}
          onMouseOut={props.onMouseOut}
          ref={(ref) => {
            if (!ref) {
              return;
            }

            imageEditor = new ImageEditor(ref, {
              usageStatistics: false,
              includeUI: {
                loadImage: {
                  path: src,
                  name: 'SampleImage'
                },
                locale: {},
                theme: myTheme, // or whiteTheme
                menuBarPosition: 'bottom'
              }
            });
          }}
        />
        <Button
          style={{ position: 'absolute', zIndex: '100', bottom: '100px', right: '100px' }}
          active
          onClick={() => {
            onSaveImageUpdate(imageEditor.toDataURL());
          }}
        >
          Save
        </Button>
      </>
    );
  }

  return <Image src={src} {...props} />;
})`
`;
