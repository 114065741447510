import * as React from 'react';
import Tooltip from 'app/components/molecules/Tooltip';

import Text from 'app/components/atoms/Text';
import styled from 'app/utils/theme';

export interface NavBarOption {
  text: string;
  tooltip?: string;
  id: string;
}
export interface NavBarProps {
  options: Array<NavBarOption>;
  onSelect: Function;
  selected?: string;
}

const NavBar = ({ onSelect, options, selected }: NavBarProps) => (
  <NavBar.Wrapper>
    {...options.map((option, index) => (
      <React.Fragment key={index}>
        <Tooltip placement="top" overlay={<Text>{option.tooltip}</Text>} mouseEnterDelay={0.3}>
          <Text
            semiBold
            active={option.id === selected}
            bold={option.id === selected}
            underline={option.id !== selected}
            onClick={() => onSelect(option.id)}
          >
            {option.text}
          </Text>
        </Tooltip>
        {index < options.length - 1 && <Text>&nbsp;&nbsp;|&nbsp;&nbsp;</Text>}
      </React.Fragment>
    ))}
  </NavBar.Wrapper>
);
NavBar.Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export default NavBar;
