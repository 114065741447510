import * as React from 'react';
import { connect } from 'react-redux';
import styled from 'app/utils/theme';
import ProgressNotification from 'app/components/molecules/ProgressNotification';
import { getRemoving } from 'app/redux/reducers/recordings';
import { UploadingModel } from 'app/models/StoreModel';
import { cancelUpload } from 'app/redux/actions';
import { getUploads } from 'app/redux/reducers/uploading';

type NotificationsProps = {
  uploads?: Array<UploadingModel>;
  removals?: Array<UploadingModel>;
  cancelUpload?: Function;
};

const Notifications = ({
  uploads = [],
  removals = [],
  cancelUpload = () => {}
}: NotificationsProps) => (
  <Notifications.Wrapper>
    {uploads
      .filter(({ status }) => status !== 'FAILED')
      .map(({ name, progress, id }) => (
        <ProgressNotification
          title={`Uploading ${name}`}
          progress={progress}
          onCancel={() => cancelUpload(id)}
        />
      ))}
  </Notifications.Wrapper>
);

Notifications.Wrapper = styled.div`
  position: absolute;
  right: 10px;
  bottom: 5px;
`;

export default connect(
  (state) => ({
    uploads: getUploads(state),
    removals: getRemoving(state)
  }),
  (dispatch) => ({
    cancelUpload: (id: string) => dispatch(cancelUpload(id))
  })
)(Notifications);
