import React from 'react';
import { Dropdown, DropdownItemProps } from 'semantic-ui-react';
import styled from 'app/utils/theme';

const Wrapper = styled.div`
  width: 100%;
  position: relative;

  .selection.dropdown {
    background: ${(props) => props.theme.dropdown.backgroundColor};
    border: 1px solid ${(props) => props.theme.dropdown.borderColor};
    color: ${(props) => props.theme.text.colors.active};
    input,
    span {
      color: ${(props) => props.theme.text.colors.active};
    }
    box-sizing: border-box;
    border-radius: 4px;
  }

  .visible .menu.transition {
    background: ${(props) => props.theme.dropdown.backgroundColor};
    border: 1px solid ${(props) => props.theme.dropdown.borderColor};
  }

  .ui.selection.dropdown .menu > .item {
    border-top: transparent;
  }
`;

interface SelectProps {
  onChange: Function;
  value: Array<string> | string;
  options: Array<DropdownItemProps>;
  noResultsMessage: string;
  placeholder: string;
  additionLabel: string;
  disabled?: boolean;
  multiple?: boolean;
}
class Select extends React.Component<SelectProps> {
  render() {
    let { value = [], onChange, multiple = true, ...restProps } = this.props;

    /*
    {!multiple && fieldValue && <Select.Icon onClick={() => onChange(null)} color={theme.buttonSecondaryColor} name="remove" />}
    */
    return (
      <Wrapper>
        <Dropdown
          onChange={(e, element) => onChange(element.value)}
          multiple={multiple}
          basic
          search={multiple}
          selection
          hideLabel
          allowAdditions={multiple}
          fluid
          value={value}
          {...restProps}
        />
      </Wrapper>
    );
  }
}

export default Select;
