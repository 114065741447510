import { Middleware } from 'redux';
import get from 'lodash/get';
import { ActionTypes } from 'app/redux/actionTypes/index';
import { googleRefreshAccessToken } from 'app/redux/actions/index';

export const authErrorInterceptor: Middleware = ({ getState, dispatch }) => (next) => (action) => {
  const { error } = action;
  const errorCode = get(error, 'response.status');

  const isAuthorizationError = errorCode == 401;
  if (!isAuthorizationError) {
    return next(action);
  }

  dispatch(googleRefreshAccessToken());
  dispatch({ type: ActionTypes.GOOGLE_SIGN_OUT });

  return next(action);
};
