import uniqid from 'uniqid';

import { ActionTypes } from 'app/redux/actionTypes/index';
import { getReccordingSettings } from 'app/redux/reducers/recordingSelector';
import { Dispatch } from 'react';
import { StoreModel } from 'app/models';
import get from 'lodash/get';
import { getRecordingById } from '../reducers/recordings';
import { getActiveRecording } from '../reducers/activeRecording';
import { RecordActionType } from 'app/config';
import { Recorder } from 'app/utils/asyncActions/record';

export * from './startRecordingUpload';
export * from './apiActions';
export * from './googleActions';

export const toggleSourceSelectorModal = () => async (dispatch: Dispatch<any>) => {
  // calling getDisplayMedia here so we show the selector before the timer modal
  const source = await(navigator.mediaDevices as any).getDisplayMedia({
    video: true,
    audio: true,
    systemAudio: 'include',
  });

  dispatch(
    setRecordingSettings({
      source
    })
  );

  dispatch(toggleTimerModal());
};

export const startWebCamRecording = () => async (dispatch: Dispatch<any>, getState: Function) => {
  const { webCamDeviceId } = getReccordingSettings(getState());

  const webCamStream = await (<any>navigator).mediaDevices.getUserMedia({
    audio: false,
    video: {
      deviceId: webCamDeviceId ? { exact: webCamDeviceId } : undefined,
      width: window.innerWidth, // / 4,
      height: window.innerHeight // ((window.innerWidth / 4) * 9) / 16
    }
  });

  dispatch(setRecordingSettings({
    type: RecordActionType.WEBCAM,
    source: webCamStream,
  }));

  dispatch(toggleTimerModal());
}

export const startWebCamAndVideoRecording = () => async (dispatch: Dispatch<any>, getState: Function) => {
  const { webCamDeviceId } = getReccordingSettings(getState());

  const webCamStream = await (<any>navigator).mediaDevices.getUserMedia({
    audio: false,
    video: {
      deviceId: webCamDeviceId ? { exact: webCamDeviceId } : undefined,
      width: window.innerWidth, // / 4,
      height: window.innerHeight // ((window.innerWidth / 4) * 9) / 16
    }
  });

  dispatch(
    setRecordingSettings({
      type: RecordActionType.VIDEOANDWEBCAM,
      source: webCamStream
    })
  );

  dispatch(toggleSourceSelectorModal());
};

export const getRecordingSources = () => ({
  type: ActionTypes.GET_RECORDING_SOURCES,
  asyncAction: true,
  payload: {
    method: 'getRecordingSources',
    params: null
  }
});

export const filterRecordingSources = (filter: string) => ({
  type: ActionTypes.FILTER_RECORDING_SOURCES,
  payload: {
    filter
  }
});

export const setRecordingSettings = (settings: Object) => ({
  type: ActionTypes.SET_RECORDING_SETTINGS,
  payload: {
    settings
  }
});

export const startRecording = ({ history }: any) => async (
  dispatch: Dispatch<any>,
  getState: Function
) => {
  const settings = getReccordingSettings(getState());

  history.push({ pathname: '/recording-controls' });

  const id = uniqid();

  // const stopRecordsDueToFileSize = {};

  return dispatch({
    type: ActionTypes.START_RECORD,
    asyncAction: true,
    payload: {
      method: 'startRecording',
      params: {
        id,
        type: settings.type,
        audio: settings.sound,
        stream: get(settings, 'source'),
        sourceId: get(settings, 'source.id'),
        thumbnail: get(settings, 'source.thumbnail'),
        name: get(settings, 'source.name'),
        onExternalStop: () => dispatch(stopRecording({ history })),
        settings,
        onRecordingSize: (size: number) => {
          dispatch(updateActiveRecording({ ...getActiveRecording(getState()), size }));
        },
      }
    }
  });
};

export const takeScreenshot = () => (dispatch: Dispatch<any>, getState: Function) => {
  const settings = getReccordingSettings(getState());
  return dispatch({
    type: ActionTypes.TAKE_SCREENSHOT,
    asyncAction: true,
    payload: {
      method: 'takeScreenshot',
      params: {
        id: uniqid(),
        audio: settings.sound,
        sourceId: get(settings, 'source.id'),
        thumbnail: get(settings, 'source.thumbnail'),
        name: get(settings, 'source.name')
      }
    }
  });
};

export const resumeRecording = () => ({
  type: ActionTypes.RESUME_RECORDING,
  asyncAction: true,
  payload: {
    method: 'resumeRecording'
  }
});

export const pauseRecording = () => ({
  type: ActionTypes.PAUSE_RECORD,
  asyncAction: true,
  payload: {
    method: 'pauseRecording'
  }
});

export const stopRecording = ({ history }: { history: any }) => {
  history.push({ pathname: '/' });

  return {
    type: ActionTypes.STOP_RECORD,
    asyncAction: true,
    payload: {
      method: 'stopRecording'
    }
  };
};

export const cancelRecording = ({ history }: { history: any }) => (
  dispatch: Dispatch<any>,
  getState: Function
) => {
  history.push({ pathname: '/' });

  const activeRecording = getActiveRecording(getState());

  setTimeout(() => {
    Recorder.IndexedDB.deleteChunks(activeRecording.id);
  }, 2000);

  return dispatch({
    type: ActionTypes.CANCEL_RECORD,
    asyncAction: true,
    payload: {
      method: 'cancelRecording'
    }
  });
};

export const toggleTimerModal = () => ({
  type: ActionTypes.TOGGLE_TIMER_MODAL
});

export const toggleSendMessageModal = () => ({
  type: ActionTypes.TOGGLE_SEND_MESSAGE
});

export const toggleShareRecordModal = (fileId: string | null) => ({
  type: ActionTypes.TOGGLE_SHARED_RECORD_MODAL,
  payload: {
    data: {
      fileId
    }
  }
});

export const updateActiveRecording = (data: StoreModel.ActiveRecording) => ({
  type: ActionTypes.UPDATE_ACTIVE_RECORDING,
  payload: data
});

export const incrementRecordingTime = () => ({
  type: ActionTypes.INCREMENT_RECORDING_TIME
});

export const toggleVideoPlayback = (playRecording: StoreModel.RecordingModel | null) => ({
  type: ActionTypes.PLAY_RECORDING,
  payload: {
    data: playRecording
  }
});

export const toggleVideoPlaybackById = (fileId: string) => (
  dispatch: Dispatch<any>,
  getState: Function
) => {
  const playRecording = getRecordingById(getState(), fileId);

  return dispatch({
    type: ActionTypes.PLAY_RECORDING,
    payload: {
      data: playRecording
    }
  });
};

// export const minimizeUpload = ({ minimized, id }: Pick<UploadingModel, 'id'>) => ({
//   type: ActionTypes.MINIMIZE_UPLOAD,
//   payload: { data: { id, minimized } }
// });

export const cancelUpload = (id: string) => ({
  type: ActionTypes.CANCEL_UPLOAD,
  payload: {
    data: { id }
  }
});

export const stopUpload = (id: string) => ({
  type: ActionTypes.STOP_UPLOAD,
  payload: {
    data: { id }
  }
});

export const retryUpload = (id: string) => ({
  type: ActionTypes.RETRY_UPLOAD,
  payload: {
    data: { id }
  }
});

export const minimizeWindow = () => ({
  type: ActionTypes.WINDOW_ACTION,
  asyncAction: true,
  payload: { method: 'ipcSendEvent', params: { eventName: 'window-minimize' } }
});
export const maximizeWindow = () => ({
  type: ActionTypes.WINDOW_ACTION,
  asyncAction: true,
  payload: { method: 'ipcSendEvent', params: { eventName: 'window-maximize' } }
});
export const resizeToWindow = () => ({
  type: ActionTypes.WINDOW_ACTION,
  asyncAction: true,
  payload: { method: 'ipcSendEvent', params: { eventName: 'resize-to-window' } }
});

export const closeWindow = () => ({
  type: ActionTypes.WINDOW_ACTION,
  asyncAction: true,
  payload: { method: 'ipcSendEvent', params: { eventName: 'window-close' } }
});

export const sendWindowEvent = ({ eventName, params }: any) => ({
  type: ActionTypes.WINDOW_ACTION,
  asyncAction: true,
  payload: { method: 'ipcSendEvent', params: { eventName, params } }
});

export const checkForUpdates = () => ({
  type: ActionTypes.CHECK_FOR_UPDATES,
  asyncAction: true,
  payload: {
    method: 'checkForUpdates'
  }
});

export const getLocalServerPort = () => ({
  type: ActionTypes.GET_LOCAL_SERVER_PORT,
  asyncAction: true,
  payload: { method: 'getLocalServerPort' }
});

export const onSearchRecordings = (text: string) => ({
  type: ActionTypes.SEARCH_RECORDINGS,
  payload: { data: { text } }
});

export const setRecordingsSortOption = (sortOption: number) => ({
  type: ActionTypes.SET_RECORDINGS_SORT_OPTION,
  payload: { data: { sortOption } }
});

export const toggleOfflineMode = (mode: boolean) => ({
  type: ActionTypes.TOGGLE_OFFLINE_MODE,
  payload: { data: mode }
});

export const toggleLightMode = () => ({
  type: ActionTypes.TOGGLE_LIGHT_MODE
});

export const toggleFavoritesFilter = () => ({
  type: ActionTypes.TOGGLE_FAVORITES_FILTER
});

export const getNumberOfDisplays = () => ({
  type: ActionTypes.GET_NUMBER_OF_DISPLAYS,
  asyncAction: true,
  payload: {
    method: 'getNumberOfDisplays',
    params: null
  }
});

export const toggleEditScreenshot = (show: boolean, snapshot: string) => ({
  type: ActionTypes.UPDATE_SCREENSHOT,
  payload: {
    data: {
      show,
      snapshot
    }
  }
});

export const selectVisibleRecordingsOption = (option: string) => ({
  type: ActionTypes.SELECT_VISIBLE_RECORDING_OPTION,
  payload: {
    data: {
      option
    }
  }
});
