// https://react.semantic-ui.com/modules/dropdown/
import React from 'react';
import { Icon } from 'semantic-ui-react';
import { connect } from 'react-redux';
import difference from 'lodash/difference';
import {
  toggleShareRecordModal,
  shareRecordingWithEmails,
  updateRecordingAccessType
} from 'app/redux/actions';
import { getSharedEmails, getAllEmails, getSharedRecording } from 'app/redux/reducers/recordings';
import Text from 'app/components/atoms/Text';
import Select from 'app//components/molecules/Select';
import Modal from 'app/components/molecules/Modal';
import Button from 'app/components/molecules/Button';
import TextArea from 'app/components/atoms/TextArea';

import { toastr } from 'react-redux-toastr';
import { PRODUCT_NAME } from 'app/config';
import styled from 'app/utils/theme';
import { StoreModel } from 'app/models';
import Logo from 'app/components/molecules/Logo';

const validateEmail = (email: string) => {
  var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

const showWarning = ({ message }: any) => {
  toastr.warning(`${PRODUCT_NAME} notification`, message, {
    icon: <Logo />,
    timeOut: 4000,
    attention: true
  });
};

const showSuccess = ({ message }: any) => {
  toastr.success(`${PRODUCT_NAME} notification`, message, {
    timeOut: 1000,
    attention: false
  });
};

const Wrapper = styled.div`
  position: relative;
  width: 40vw;
  background-color: ${(props: any) => props.theme.modal.contentColor};
  box-shadow: ${(props: any) => props.theme.modal.border};
  border-radius: 4px;
  padding: 50px 15px 50px 30px;
  margin-left: auto;
  margin-right: auto;

  #close-modal {
    position: absolute;
    top: 5%;
    right: 3%;
  }
`;

const CopyArea = styled.div`
  width: 100%;
  position: relative;

  textarea {
    padding-bottom: 0 !important;
  }

  i {
    position: absolute;
    right: 5px;
    top: 10px;
    cursor: pointer;
  }
`;
interface ModalProps {
  closeShareRecordModal?: Function;
  sharedEmails?: Array<string>;
  allEmails?: string[];
  shareRecordingWithEmails?: Function;
  recording?: StoreModel.RecordingModel;
  updateRecordingAccessType?: Function;
}

const FileAccessTypes = [
  { text: 'Private access (Only for shared emails)', value: 'PRIVATE', key: 'PRIVATE' },
  { text: 'Public (Anyone with the link)', value: 'LINK', key: 'LINK' }
];
class SharedRecordModal extends React.Component<ModalProps> {
  state = {
    added: [],
    removed: [],
    touched: false,
    isLoading: false,
    message: ''
  };

  copyToClipboard = () => {
    /* Get the text field */
    const copyText: any = document.getElementsByTagName('textarea')[0];

    if (!copyText) {
      return;
    }

    /* Select the text field */
    copyText.select();
    copyText.setSelectionRange(0, 99999); /* For mobile devices */

    /* Copy the text inside the text field */
    document.execCommand("copy");

    /* Alert the copied text */
    showSuccess({
      message: 'Link copied!',
    });
  }

  onChange = (newEmails: Array<string>) => {
    const { sharedEmails = [] } = this.props;
    const curatedEmails = newEmails.reduce((acc: Array<string>, email) => {
      return [...acc, ...email.split(' ')];
    }, []);
    const added = difference(curatedEmails, sharedEmails);

    const isInvalidEmails = added.filter((email) => !validateEmail(email));
    if (isInvalidEmails.length) {
      showWarning({ message: `${isInvalidEmails.join(', ')} is not a valid email address` });
    }

    this.setState({
      added: added.filter((email) => !isInvalidEmails.includes(email)),
      removed: difference(sharedEmails, curatedEmails),
      touched: true
    });
  };

  onMessageChange = (message: any) => {
    this.setState({ message });
  };

  onSave = async () => {
    const {
      closeShareRecordModal = () => {},
      shareRecordingWithEmails = () => {},
      recording,
      sharedEmails = []
    } = this.props;

    // no need to keep modal opened anymore
    if (!this.state.touched) {
      closeShareRecordModal();
      return;
    }

    this.setState({
      isLoading: true
    });

    const { added, removed, message } = this.state;

    // update file with the latest sharedUsers
    const allSharedEmails = [
      ...sharedEmails.filter((email) => !removed.find((rEmail) => rEmail === email)),
      ...added
    ].map((email) => ({ email }));

    shareRecordingWithEmails({
      id: recording?.id,
      sharedWith: allSharedEmails,
      message
    });

    this.setState({
      isLoading: false
    });

    closeShareRecordModal();
  };

  onAccessTypeChanged = (accessType: string) => {
    const { updateRecordingAccessType = () => { } } = this.props;

    updateRecordingAccessType({
      fileId: this.props.recording?.id,
      accessType
    });
  };

  render() {
    const {
      closeShareRecordModal = () => {},
      sharedEmails,
      allEmails = [],
      recording
    } = this.props;
    const { added, removed, message } = this.state;
    const selectOptions = [...difference(sharedEmails, removed), ...added].map((email: string) => ({
      key: email,
      value: email,
      text: email
    }));

    const allEmailsOptions = [...added, ...allEmails].map((email: string) => ({
      key: email,
      value: email,
      text: email
    }));

    if (!recording) {
      return null;
    }

    return (
      <Modal hideInnerWrapper onClose={closeShareRecordModal}>
        <Wrapper>
          <Text bold active id="close-modal" onClick={() => closeShareRecordModal()}>
            X
          </Text>
          <Text sm semiBold active pBottom={20}>
            Share this recording with others:
          </Text>
          <Select
            options={allEmailsOptions}
            value={selectOptions.map((o) => o.key)}
            onChange={this.onChange}
            placeholder="Share video with others by entering their email(s)"
            noResultsMessage="Type to add email"
            additionLabel="Share with "
            disabled={this.state.isLoading || recording.isUpdating}
          />
          <Text pTop={5} />

          <Select
            multiple={false}
            options={FileAccessTypes}
            value={recording.accessType}
            onChange={this.onAccessTypeChanged}
            placeholder="Set file access level"
            noResultsMessage="Type to add access"
            additionLabel="Select access type"
            disabled={this.state.isLoading || recording.isUpdating}
          />
          <Text pTop={5} />
          <CopyArea>
            <TextArea value={recording.sharedUrl} />
            <Icon name="copy" onClick={() => this.copyToClipboard()}/>
          </CopyArea>
          <Text pTop={20} />
          <div style={{ width: '100%', height: '200px' }}>
            <TextArea
              value={message}
              placeholder="Add a message (optional)"
              onChange={this.onMessageChange}
            />
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: '30px' }}>
            <Button
              loading={this.state.isLoading || recording.isUpdating}
              active={!(this.state.isLoading || recording.isUpdating)}
              onClick={this.onSave}
            >
              Share
            </Button>
          </div>
        </Wrapper>
      </Modal>
    );
  }
}

export default connect(
  (state) => ({
    sharedEmails: getSharedEmails(state),
    allEmails: getAllEmails(state),
    recording: getSharedRecording(state)
  }),
  (dispatch) => ({
    updateRecordingAccessType: (params: any) => dispatch(updateRecordingAccessType(params)),
    shareRecordingWithEmails: (recording: StoreModel.ApiFileModel) =>
      dispatch(shareRecordingWithEmails(recording)),
    closeShareRecordModal: () => dispatch(toggleShareRecordModal(null))
  })
)(SharedRecordModal);
