import * as React from 'react';
import { connect } from 'react-redux';

import { Image } from 'semantic-ui-react';
import {
  googleSignOut,
  checkForUpdates,
  sendWindowEvent,
  toggleLightMode,
  toggleSendMessageModal,
  setRecordingSettings
} from 'app/redux/actions/index';
import { getUserProfile } from 'app/redux/reducers/googleSignin';
import { getRecordingsField } from 'app/redux/reducers/recordings';
import Text from 'app/components/atoms/Text';
import Dropdown from 'app/components/molecules/Dropdown';
import Tooltip from 'app/components/molecules/Tooltip';

const getSettingsOptions = (isLightMode: boolean | undefined) => {
  return [
    {
      text: `${isLightMode ? 'Dark' : 'Light'} mode`
    },
    {
      text: 'Settings'
    },
    {
      text: 'Give feedback'
    },
    {
      text: 'Sign out'
    }
  ];
};
export interface SettignsProps {
  googleSignOut?: Function;
  checkForUpdates?: Function;
  profile?: any;
  sendWindowEvent?: Function;
  isLightMode?: boolean;
  toggleLightMode?: Function;
  toggleSendMessageModal?: Function;
  toggleShowSettingsModal?: Function;
}
const Settings = ({
  googleSignOut = () => {},
  sendWindowEvent = () => {},
  profile = {},
  isLightMode,
  toggleLightMode = () => {},
  toggleSendMessageModal = () => { },
  toggleShowSettingsModal = () => {} ,
}: SettignsProps) => (
  <div>
    <Dropdown
      left
      noIcon
      name={
        profile && (
          <Tooltip placement="left" overlay={<Text>{profile.email}</Text>} mouseEnterDelay={4}>
            <Image floated="right" size="mini" avatar src={profile.picture} />
          </Tooltip>
        )
      }
      maxWidth="173px"
      options={getSettingsOptions(isLightMode)}
      onSelect={(option) => {
        switch (option.text) {
          case 'Sign out':
            return googleSignOut();
          case 'Settings':
            toggleShowSettingsModal();
            return;
          case 'Give feedback':
            return toggleSendMessageModal();
          case 'About':
            return sendWindowEvent({ eventName: 'openAboutWindow' });
          case `${isLightMode ? 'Dark' : 'Light'} mode`:
            return toggleLightMode();
          default:
        }
      }}
    />
  </div>
);

export default connect(
  (store) => ({
    profile: getUserProfile(store),
    isLightMode: getRecordingsField('isLightMode', store)
  }),
  (dispatch) => ({
    googleSignOut: () => dispatch(googleSignOut() as any),
    checkForUpdates: () => dispatch(checkForUpdates()),
    sendWindowEvent: (params: any) => dispatch(sendWindowEvent(params)),
    toggleLightMode: () => dispatch(toggleLightMode()),
    toggleSendMessageModal: () => dispatch(toggleSendMessageModal()),
    toggleShowSettingsModal: () => dispatch(setRecordingSettings({ showSettingsModal: true }))
  })
)(Settings);
