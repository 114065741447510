import * as React from 'react';
import styled, { css } from 'app/utils/theme';
import Text from 'app/components/atoms/Text';
import Image from 'app/components/atoms/Image';
import { Progress } from 'app/components/molecules/ProgressWithLogo';
import { StoreModel } from 'app/models/StoreModel';

export interface CardProps extends StoreModel.RecordingSource {
  onClick: Function;
  big?: boolean;
  progress?: boolean;
}

const Card = ({ progress, big, thumbnail, name, onClick }: CardProps) => (
  <Card.Wrapper>
    <Card.ImageContainer big={!!big}>
      <Card.ImageHover onClick={() => onClick()}>
        <Text sm semiBold active maxSize={50}>
          {name}
        </Text>
      </Card.ImageHover>
      <Image src={thumbnail} />
      {progress && <Progress />}
    </Card.ImageContainer>
  </Card.Wrapper>
);

Card.ImageHover = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 4px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  :hover {
    cursor: pointer;
    ${Text} {
      text-decoration: underline;
    }
  }

  ${Text} {
    padding: 20px;
    text-overflow: ellipsis;
    word-break: break-all;
    color: white;
  }
`;

Card.Wrapper = styled.div`
  position: relative;
  padding: 10px;
  padding-left: 5px;
`;

const sizes: MediaSize = {
  desktop: 1200,
  tablet: 768,
  phone: 576
};

type MediaSize = {
  desktop: number;
  tablet: number;
  phone: number;
};

type MediaModel = {
  desktop: Function;
  tablet: Function;
  phone: Function;
};

const media: MediaModel = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (args: any) => css`
    @media (max-width: ${sizes[label]}px) {
      ${css(args)}
    }
  `;
  return acc;
}, {}) as MediaModel;

Card.ImageContainer = styled.div<{ big: boolean }>`
  position: relative;
  background-color: ${(props) => props.theme.modal.backgroundColor};
  box-shadow: ${(props) => props.theme.card.shadow};
  border-radius: 4px;

  ${media.desktop``}
  width: 26vw;
  height: calc(26vw * 9 / 16);

  ${media.tablet`
    width: 23vw;
    height: calc(23vw * 9 / 16);
  `}
  ${media.phone`
    width: 35vw;
    height: calc(35vw * 9 / 16);
  `}

  ${(props) =>
    props.big &&
    `
  width: 42vw;
  height: calc(42vw * 9 / 16);
  `}
`;
export default Card;
