import React from 'react';
import { connect } from 'react-redux';
import styled from 'app/utils/theme';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import IconButton from 'app/components/molecules/IconButton';
import Image from 'app/components/atoms/Image';

import { getActiveRecordingField } from 'app/redux/reducers/activeRecording';
import { toggleEditScreenshot } from 'app/redux/actions';
import { StyledComponent } from 'styled-components';

interface ModalProps extends RouteComponentProps {
  toggleEditScreenshot?: Function;
  screenshot?: string;
}

class EditScreenshotModal extends React.Component<ModalProps> {
  static Wrapper: StyledComponent<any, any>;

  render() {
    const { toggleEditScreenshot = () => {}, screenshot } = this.props;
    return (
      <EditScreenshotModal.Wrapper>
        <IconButton
          icon="times-circle"
          size="2x"
          onClick={() => toggleEditScreenshot(false, screenshot)}
        />
        <Image
          src={screenshot || ''}
          edit
          onSaveImageUpdate={(blob: string) => {
            toggleEditScreenshot(false, blob);
          }}
        />
      </EditScreenshotModal.Wrapper>
    );
  }
}

EditScreenshotModal.Wrapper = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  z-index: 10001;

  ${IconButton} {
    background-color: black;
    border-radius: 40px;
    position: absolute;
    top: 5%;
    right: 3%;
    z-index: 1;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: bold;
    color: white;
    z-index: 10001;
  }

  > svg {
    position: absolute;
    top: 35px;
    right: 10px;
    z-index: 10002;
  }
`;

export default withRouter(
  connect(
    (state) => ({
      screenshot: getActiveRecordingField('thumbnail', state)
    }),
    (dispatch) => ({
      toggleEditScreenshot: (show: boolean, snapshot: string) =>
        dispatch(toggleEditScreenshot(show, snapshot) as any)
    })
  )(EditScreenshotModal)
);
