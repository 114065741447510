import * as React from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import 'react-html5video/dist/styles.css';
import styled from 'app/utils/theme';
// import { StoreModel } from 'app/models/StoreModel';

// import set from 'lodash/set';

// import { DefaultPlayer as Video } from 'react-html5video';
// import 'react-html5video/dist/styles.css';

// export default ({
//   thumbnail,
//   url,
//   mimeType,
//   durationInSeconds
// }: StoreModel.RecordingModel & { durationInSeconds: number }) => {
//   return (
//     <VideoPlayerWrapper>
//       <video preload="none" muted controls>
//         <source src={url} type={mimeType} />
//       </video>
//     </VideoPlayerWrapper>
//   );
// };

const VideoPlayerWrapper = styled.div`
  width: 100%;
  height: 100%;

  :first-child {
    height: 100vh;
    width: 100vw;
    position: relative;
  }

  .video-js {
    height: 100% !important;
    width: 100% !important;
    position: relative;
    background-color: black;
  }
  video[poster] {
    object-fit: cover;
    ${(props: any) => props.fill && 'object-fit:fill;'}
  }

  .vjs-poster {
    background-size: cover;
    background-position: center center;
    background-position: inherit;
  }

  /* .vjs-paused .vjs-big-play-button {
    display: none;
  } */

  .vjs-picture-in-picture-control {
    display: none;
  }

  .video-js .vjs-time-control {
    display: block;
  }
  .video-js .vjs-remaining-time {
    display: none;
  }

  .vjs-button > .vjs-icon-placeholder::before {
    font-size: 2.2em;
  }

  .vjs-time-divider,
  .vjs-control {
    height: 50% !important;
    padding: 0 !important;
    min-width: unset !important;
  }

  .vjs-control-bar {
    background-color: rgba(0, 0, 0, 0.25) !important;
    height: 80px !important;
    align-items: flex-end;
    /* width: 92% !important;
    padding-left: 30px !important; */
  }

  .vjs-current-time {
  }

  .vjs-volume-horizontal {
    padding-top: 3px !important;
  }
  .vjs-time-control {
    font-size: 1.7em !important;
    line-height: 1.7em !important;
    padding-top: 3px !important;

    align-self: flex-end !important;

    font-family: ${(props) => props.theme.text.fonts.default} !important;
    font-style: normal !important;
    font-weight: normal !important;
  }

  .vjs-progress-control {
    background-color: transparent;
    position: absolute !important;
    width: 100% !important;
    top: 0 !important;
  }
  .vjs-progress-holder {
    border-radius: 4px !important;
    height: 3.5px !important;
  }
  .vjs-play-progress {
    background-color: ${(props) => props.theme.text.colors.orange} !important;
  }

  .vjs-fullscreen-control {
    position: absolute !important;
    right: 0;
  }
`;

// const StyledVideoPlayer = styled.div<any>`
//   width: 100% !important;
//   height: 100% !important;
//   .vjs-progress-control {
//     position: absolute;
//     bottom: 26px; /* The height of the ControlBar minus 4px. */
//     left: 0;
//     right: 0;
//     width: 100%;
//     height: 10px; /* the height must be reduced from 30 to 10px in order to allow the buttons below (e.g. play) to be pushed */
//   }

//   .vjs-progress-holder {
//     /* needed to have a real 100% width display. */
//     margin-left: 0;
//     margin-right: 0;
//   }

//   .vjs-time-control {
//     flex: 1;
//   }

//   .vjs-remaining-time-display {
//     text-align: right;
//   }

//   .vjs-icon-previous-item,
//   .vjs-icon-next-item {
//     cursor: pointer;
//   }

//   .vjs-icon-previous-item {
//     width: 20px;
//     height: 15px;
//     margin: 8px 6px 8px 10px;
//     background-size: cover;

//     background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgaGVpZ2h0PSIzMiIgd2lkdGg9Ijg0Ij4gIDxwYXRoIGZpbGw9IndoaXRlIiB0cmFuc2Zvcm09InNjYWxlKDAuMDMsLTAuMDMpIHRyYW5zbGF0ZSgwLC0xNDAwKSIgICAgICAgIGQ9Ik00NDggMTM0NEg1OTcuMzMzMzMzMzMzMzMzNFY0NDhINDQ4ek03MDkuMzMzMzMzMzMzMzMzNCA4OTZMMTM0NCA0NDhWMTM0NHoiLz48L3N2Zz4=);

//     &:focus {
//       text-shadow: 0 0 1em white;
//     }

//     &-disabled {
//       width: 20px;
//       height: 15px;
//       margin: 8px 6px 8px 10px;
//       background-size: cover;
//       background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgaGVpZ2h0PSIzMiIgd2lkdGg9Ijg0Ij4gIDxwYXRoIGZpbGw9ImdyZXkiIHRyYW5zZm9ybT0ic2NhbGUoMC4wMywtMC4wMykgdHJhbnNsYXRlKDAsLTE0MDApIiAgICAgICAgZD0iTTQ0OCAxMzQ0SDU5Ny4zMzMzMzMzMzMzMzM0VjQ0OEg0NDh6TTcwOS4zMzMzMzMzMzMzMzM0IDg5NkwxMzQ0IDQ0OFYxMzQ0eiIvPjwvc3ZnPg==);
//     }
//   }

//   .vjs-icon-next-item {
//     width: 20px;
//     height: 15px;
//     margin: 8px auto;
//     background-size: cover;

//     background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgaGVpZ2h0PSIzMiIgd2lkdGg9IjY1Ij4gIDxwYXRoIGZpbGw9IndoaXRlIiB0cmFuc2Zvcm09InNjYWxlKDAuMDMsLTAuMDMpIHRyYW5zbGF0ZSgwLC0xNDAwKSIgICAgICAgIGQ9Ik00NDggNDQ4TDEwODIuNjY2NjY2NjY2NjY2NyA4OTZMNDQ4IDEzNDRWNDQ4ek0xMTk0LjY2NjY2NjY2NjY2NjcgMTM0NFY0NDhIMTM0NFYxMzQ0SDExOTQuNjY2NjY2NjY2NjY2N3oiLz48L3N2Zz4=);

//     &:focus {
//       text-shadow: 0 0 1em white;
//     }

//     &-disabled {
//       width: 20px;
//       height: 16px;
//       margin: 8px auto;
//       background-size: cover;
//       background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgaGVpZ2h0PSIzMiIgd2lkdGg9IjY1Ij4gIDxwYXRoIGZpbGw9ImdyZXkiIHRyYW5zZm9ybT0ic2NhbGUoMC4wMywtMC4wMykgdHJhbnNsYXRlKDAsLTE0MDApIiAgICAgICAgZD0iTTQ0OCA0NDhMMTA4Mi42NjY2NjY2NjY2NjY3IDg5Nkw0NDggMTM0NFY0NDh6TTExOTQuNjY2NjY2NjY2NjY2NyAxMzQ0VjQ0OEgxMzQ0VjEzNDRIMTE5NC42NjY2NjY2NjY2NjY3eiIvPjwvc3ZnPg==);
//     }
//   }

//   .vjs-control-disabled {
//     cursor: default;
//   }
// `;

interface VideoProps {
  url: string;
  mimeType: string;
  durationInSeconds: number;
  vttUrl?: string;
}

export default class VideoPlayer extends React.Component<VideoProps> {
  player: any = null;
  videoNode: any = null;
  componentDidMount() {
    // instantiate Video.js
    this.player = videojs(
      this.videoNode,
      {
        html5: {
          nativeTextTracks: false
        },
        tracks: [
          {
          src: this.props.vttUrl,
          kind: 'subtitles',
          srclang: 'en',
          label: 'Caption',
          default: true
        }
        ],
        autoplay: true,
        controls: true
        // sources: [
        //   {
        //     src: this.props.url,
        //     type: this.props.mimeType
        //   }
        // ]
      },
      () => {
        if (!this.props.vttUrl) {
          return;
        }

        const allTracks = this.player.remoteTextTracks().tracks_;
        allTracks.length && (allTracks[0].mode = 'showing');
      }
    );

    this.player.duration = () => this.props.durationInSeconds;

    this.player.src({
      src: this.props.url,
      type: this.props.mimeType
    });
  }

  // destroy player on unmount
  componentWillUnmount() {
    if (this.player) {
      this.player.dispose();
    }
  }

  // wrap the player in a div with a `data-vjs-player` attribute
  // so videojs won't create additional wrapper in the DOM
  // see https://github.com/videojs/video.js/pull/3856
  render() {
    return (
      <VideoPlayerWrapper>
        <div data-vjs-player>
          <video
            ref={(node) => (this.videoNode = node)}
            className="video-js vjs-big-play-centered"
          />
        </div>
      </VideoPlayerWrapper>
    );
  }
}
