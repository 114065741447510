import { Middleware } from 'redux';
import get from 'lodash/get';
import { toastr } from 'react-redux-toastr';
import { ActionTypes } from 'app/redux/actionTypes/index';
// import { googleRefreshAccessToken } from 'app/redux/actions/index';
// import Text from 'app/components/atoms/Text';
import { PRODUCT_NAME } from 'app/config';

const showWarning = ({ message }: any) => {
  toastr.warning(`${PRODUCT_NAME} notification`, message, {
    timeOut: 4000,
    attention: true
  });
};

const showSuccess = ({ message }: any) => {
  toastr.success(`${PRODUCT_NAME} notification`, message, {
    timeOut: 4000,
    attention: true
  });
};

export const notificationInterceptor: Middleware = ({ getState, dispatch }) => (next) => (
  action
) => {
  const { error } = action;
  const errorCode = get(error, 'response.status');
  const _isNewUserSignInFailure =
    get(action, 'error.response.data.newUserSignInFailure');
  const isAuthorizationError = errorCode == 401;
  const isMessageSent = action.type === `${ActionTypes.SEND_MESSAGE}_SUCCESS`;

  if (isAuthorizationError && !_isNewUserSignInFailure) {
    showWarning({ message: 'Authentication session expired, please login again.' });
    dispatch({ type: ActionTypes.GOOGLE_SIGN_OUT });
  }

  const isUploadError = action.type === `${ActionTypes.UPLOAD_RECORDING}_FAIL`;
  if (isUploadError) {
    //.error.response.data.message
    showWarning({
      message: `Upload failed with error: "${get(
        action,
        'error',
        JSON.stringify(action.error, null, 2)
      )}"`
    });
  }

  const failedToShareRecording = action.type === `${ActionTypes.SHARE_FILE}_FAIL`;
  if (failedToShareRecording) {
    const sharedEmail = get(action, 'meta.previousAction.payload.data.sharedWith');
    showWarning({
      message: `Failed to share recording with "${sharedEmail}". Error: "${action.error.message}"`
    });
  }

  if (isMessageSent) {
    showSuccess({ message: ' Your message was successfully sent. Thank you for the feedback! ' });
  }

  return next(action);
};
