import * as React from 'react';
import { onlyUpdateForKeys } from 'recompose';

import Text from 'app/components/atoms/Text';
import Icon, { IconProps, IconType } from 'app/components/atoms/Icon';
import styled, { css } from 'app/utils/theme';
import Image from 'app/components/atoms/Image';
import Tooltip from 'app/components/molecules/Tooltip';

export const action = css`
  background-color: ${(props) => props.theme.icons.success};
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-sizing: border-box;
  /* Orange Shadow */

  box-shadow: 0px 4px 5px rgba(255, 142, 60, 0.1), 0px 3px 14px rgba(255, 142, 60, 0.12),
    0px 4px 10px rgba(255, 142, 60, 0.14);
  border-radius: 8px;
  color: ${(props) => props.theme.text.colors.white};
  width: 40px !important;
  height: 40px;
  padding: 7px;
  background-origin: content-box;
  background-position: center;
`;

const StyledIcon = (Icon: any, customProps: object = {}) => styled(Icon).attrs((props) => ({
  ...customProps
}))<IconProps>`
  color: ${(props) => props.theme.icons.passive};
  :hover {
    cursor: pointer;
    color: ${(props) => props.theme.icons.active};
  }
  :active {
    transform: rotateY(10deg);
    animation: two 2s infinite;
    animation-direction: alternate;
  }

  ${(props) => props.action && action};

  ${(props) =>
    props.size === '7x' &&
    `
  width: 80px !important;
  height: 80px;
  padding: 18px;
  `}
`;

export interface IconButtonProps extends IconProps {
  icon: IconType;
  tooltip?: {
    text?: string;
    position?: 'top' | 'bottom' | 'left' | 'right';
  };
}

const customIcons = {
  video: require('../../../../assets/all logo/video.png'),
  microphone: require('../../../../assets/all logo/microphone.png'),
  screenshot: require('../../../../assets/all logo/screenshot.png'),
  // star: require('../../../../assets/all logo/start-empty.png'),
  play: require('../../../../assets/all logo/play.png'),
  // 'times-circle': require('../../../../assets/all logo/close.png')
};

const pure = onlyUpdateForKeys(['icon', 'orange']);
const IconButton = pure(({ tooltip, icon, ...props }: IconButtonProps) => {
  const customIcon = customIcons[icon];
  let StyledIconButton;

  if (customIcon) {
    StyledIconButton = StyledIcon(Image, { src: customIcon });
  } else {
    StyledIconButton = StyledIcon(Icon);
  }

  if (!tooltip) {
    return <StyledIconButton {...props} icon={icon} />;
  }
  return (
    <Tooltip
      placement={tooltip.position}
      overlay={<Text>{tooltip.text}</Text>}
      mouseEnterDelay={0.3}
    >
      <StyledIconButton {...props} icon={icon} />
    </Tooltip>
  );
});

export default styled(IconButton)``;
