import React from 'react';
import Text, { TextProps } from 'app/components/atoms/Text';
import styled, { css } from 'app/utils/theme';
import Icon from 'app/components/atoms/Icon';

const active = css`
  background-color: ${(props) => props.theme.button.active.backgroundColor};
  color: ${(props) => props.theme.button.active.text};
  box-shadow: 0px 4px 5px rgba(255, 142, 60, 0.1), 0px 3px 14px rgba(255, 142, 60, 0.12),
    0px 4px 10px rgba(255, 142, 60, 0.14);
  border: 1px solid rgba(255, 255, 255, 0.2);

  p {
    color: ${(props) => props.theme.button.active.text};
  }
`;

const regular = css`
  background-color: ${(props) => props.theme.button.regular.backgroundColor};
  color: ${(props) => props.theme.button.regular.text};

  border: 1px solid rgba(0, 0, 0, 0.2);

  p {
    color: ${(props) => props.theme.button.regular.text};
  }
`;

interface ButtonProps extends TextProps {
  active?: boolean;
  loading?: boolean;
  style?: object;
}
const Button = ({ active, style, loading, children, ...props }: ButtonProps) => (
  <Button.Wrapper style={style} active={!!active}>
    {loading && <Icon pLeft={5} icon="spinner" spin size="2x" />}
    {!loading && (
      <Text veryBold sm active onClick={() => props.onClick && props.onClick()}>
        {children}
      </Text>
    )}
  </Button.Wrapper>
);

Button.Wrapper = styled.div<{active: boolean}>`
  ${(props) => props.active && active};
  ${(props) => !props.active && regular};

  box-sizing: border-box;
  border-radius: 4px;

  padding: 7px 20px 7px 20px;
  background-origin: content-box;
  background-position: center;

  align-items: center;

  display: flex;
  align-items: space-between;
`;

export default Button;
