import * as React from 'react';
import { Route as ReactRoute, Redirect } from 'react-router';
import { connect } from 'react-redux';

import { isAuthenticated, getIsOffline } from 'app/redux/reducers/googleSignin';

interface RouteProps {
  component: any;
  path: string;
  allowAccess?: boolean;
  isOffline?: boolean;
  isPrivate?: boolean;
  exact?: boolean;
}
const Route = ({
  component: Component,
  allowAccess,
  isOffline,
  isPrivate,
  ...rest
}: RouteProps) => (
  <ReactRoute
    {...rest}
    render={(props) => {
      if (isOffline && window.location.pathname !== '/offline') {
        return <Redirect to="/offline" />;
      }
      if (!isOffline && !allowAccess && isPrivate && window.location.pathname !== '/login') {
        return <Redirect to="/login" />;
      }

      return <Component {...props} />;
    }}
  />
);

export default connect((state) => ({
  allowAccess: isAuthenticated(state),
  isOffline: getIsOffline(state)
}))(Route);
