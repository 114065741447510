import React from 'react';
import Text from 'app/components/atoms/Text';
import Modal from 'app/components/molecules/Modal';
import Button from 'app/components/molecules/Button';

import styled from 'app/utils/theme';

const Wrapper = styled.div`
  position: relative;
  width: 40vw;
  background-color: ${(props: any) => props.theme.modal.contentColor};
  box-shadow: ${(props: any) => props.theme.modal.border};
  border-radius: 4px;
  padding: 50px 15px 50px 30px;
  margin-left: auto;
  margin-right: auto;
  
  margin-top: 30%;
`;

export interface MessageBoxOptionProps {
  active?: boolean;
  text: string;
}
interface ModalProps {
  onClick: Function;
  message: string;
  options: Array<MessageBoxOptionProps>;
}

class MessageBoxModal extends React.Component<ModalProps> {
  render() {
    const { onClick, message, options } = this.props;

    return <Modal hideInnerWrapper onClose={() => onClick()}>
        <Wrapper>
          <Text sm veryBold active pBottom={20}>
            {message}
          </Text>
          <div style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: '30px' }}>
            {options.map((option: MessageBoxOptionProps) => <React.Fragment>
                <Text pRight={10} />
                <Button active={option.active} onClick={() => onClick(option)}>
                  {option.text}
                </Button>
              </React.Fragment>)}
          </div>
        </Wrapper>
      </Modal>;
  }
}

export default MessageBoxModal;
