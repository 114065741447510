import { FileAccessType, isWebBuild, StorageTypes } from 'app/config';
import {
  FileBlob,
  GoogleStorageConstrParams,
  StorePayload,
  StoreResponse
} from 'app/models/StoreModel';
import get from 'lodash/get';
import isString from 'lodash/isString';
import MasterStorage from './MasterStorage';

require('../MediaUploader.js');
const MediaUploader = (window as any).UploaderForGoogleDrive;

const parseErrorResponse = (response: object | string) => {
  if (!isString(response)) {
    return response;
  }

  try {
    const objResponse = JSON.parse(response as any);

    return {
      message: objResponse.error.errors
        .map(({ message, reason }: any) => `${message} (${reason})`)
        .join('\n\r')
    };
  } catch (e) {
    return {
      message: response
    };
  }
};

export const FOLDER_MIME = 'application/vnd.google-apps.folder';
export const DRIVER_FOLDER_NAME = `ScreenTape Files (Don't Touch)`;


let loadGapi: any = () => {
  let { gapi } = window as any;
  if (!gapi) {
    return setTimeout(loadGapi, 1000);
  }

  return gapi.load('client:auth2', () => {
    gapi.client.load(
      'https://content.googleapis.com/discovery/v1/apis/drive/v3/rest'
    );
  });
};

loadGapi();
export default class GoogleStorage implements MasterStorage {
  drive: any;
  access_token: string;
  static StorageType: { id: number; name: string } = StorageTypes.GOOGLE_DRIVE;

  constructor({ access_token }: GoogleStorageConstrParams) {
    if (isWebBuild) {
      (window as any).gapi.auth.setToken({ access_token });
      this.drive = (window as any).gapi.client.drive;
      this.access_token = access_token;
    } else {
      const oauth2Client = new (window as any).gapi.auth.OAuth2();
      oauth2Client.setCredentials({ access_token });
      this.drive = (window as any).gapi.drive({ version: 'v3', auth: oauth2Client });
      this.access_token = access_token;
    }
  }

  getParentFolder = async () => {
    //** 1. DETECT ROOT PARENT FOLDER */
    let driveFolder = null;
    const result = await this.drive.files.list({
      pageSize: 1,
      q: `mimeType='${FOLDER_MIME}'`, // and name='${DRIVER_FOLDER_NAME}'`,
      fields: '*'
    });
    driveFolder = this.getFolderFromResponse(result);
    if (!driveFolder) {
      driveFolder = (
        await this.drive.files.create({
          requestBody: {
            name: DRIVER_FOLDER_NAME
          },
          media: {
            mimeType: FOLDER_MIME
          },
          fields: '*'
        })
      ).data;
    }

    return driveFolder;
  };

  create = async ({ name, mimetype, blob }: FileBlob, progressCb?: Function) => {
    return new Promise(async (res, rej) => {
      try {
        const driveFolder = await this.getParentFolder();

        const uploader = new MediaUploader({
          storageType: GoogleStorage.StorageType.name,
          file: blob,
          token: this.access_token,
          metadata: { name, mimetype, parents: [driveFolder.id] },
          params: {
            fields: '*'
          },
          onError: (response: any) => {
            const error = parseErrorResponse(response);
            rej(error);
          },
          onComplete: (response: any) => {
            const file = JSON.parse(response);
            res(file);
          },
          onProgress: (event: any) => {
            const progress = Math.round((event.loaded / event.total) * 100);
            progressCb && progressCb(progress);
          }
        });

        uploader.upload();
      } catch (e) {
        rej(e);
      }
    });
  };

  private getFolderFromResponse(result: any): any {
    if (isWebBuild) {
      return result.result.files ? result.result.files[0] : null;
    }

    return result.data.files ? result.data.files[0] : null;
  }

  async store({ file, thumbnail, gif }: StorePayload, progressCb: Function): Promise<StoreResponse> {
    const driveFile: any = await this.create(file, progressCb);

    let driveThumbnail;
    if (thumbnail) {
      driveThumbnail = await this.create(thumbnail);
      await this.drive.permissions.create({
        fileId: get(driveThumbnail, 'id'),
        sendNotificationEmail: false,
        [isWebBuild ? 'resource' : 'requestBody']: {
          role: 'reader',
          type: 'anyone'
        }
      });
    }

    return {
      gif: ``,
      url: `https://drive.google.com/file/d/${driveFile.id}/preview?start=1&&output=embed`,
      sharedUrl: get(driveFile, 'webViewLink'),
      downloadUrl: `${get(driveFile, 'webContentLink') || get(driveFile, 'webViewLink')}&alt=media`,
      meta: {
        driveFile,
        driveThumbnail
      },
      thumbnail:
        get(driveThumbnail, 'webContentLink', null) || get(driveFile, 'thumbnailLink', null),
      storage: GoogleStorage.StorageType,
      accessType: FileAccessType.PRIVATE
    };
  }
}
