import React from 'react';
import { connect } from 'react-redux';
import styled from 'app/utils/theme';
import { StyledComponent } from 'styled-components';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import Text from 'app/components/atoms/Text';
import IconButton from 'app/components/molecules/IconButton';

import { getReccordingSettings } from 'app/redux/reducers/recordingSelector';
import { isTimerModalVisible } from 'app/redux/reducers/activeRecording';
import { toggleTimerModal, startRecording } from 'app/redux/actions';
import { getSources } from 'app/redux/reducers/recordingSelector';

interface ModalProps extends RouteComponentProps {
  toggleTimerModal?: Function;
  startRecording?: Function;
  isTimerModalVisible?: Boolean;
}

class TimerModal extends React.Component<ModalProps> {
  static Wrapper: StyledComponent<any, any>;
  intervalHandler: any /*NodeJS.Timeout?*/ = null;
  state = {
    count: 3
  };

  componentDidMount() {
    // run in 1 second
    this.intervalHandler = setInterval(
      () =>
        this.setState({
          count: this.state.count - 1
        }),
      1000
    );
  }
  shouldComponentUpdate() {
    const {
      toggleTimerModal = () => {},
      startRecording = () => {},
      history
    } = this.props;

    if (this.state.count === 1) {
      clearInterval(this.intervalHandler);
      toggleTimerModal();
      startRecording({ history });
      return false;
    }

    return true;
  }

  render() {
    const { toggleTimerModal = () => {} } = this.props;
    return (
      <TimerModal.Wrapper>
        <IconButton icon="times-circle" size="2x" onClick={() => toggleTimerModal()} />
        <Text xxxxl active>
          {this.state.count}
        </Text>
      </TimerModal.Wrapper>
    );
  }
}

TimerModal.Wrapper = styled.div`
  top: 0;
  position: absolute;
  width: 100vw;
  height: ${() => document.body.scrollHeight}px;
  overflow: hidden;
  z-index: 1000;
  background-color: ${(props) => props.theme.modal.backgroundColor};
  opacity: 0.8;

  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${IconButton} {
    position: fixed;
    top: 10px;
    right: 10px;
  }

  ${Text} {
    position: fixed;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
  }
`;

export default withRouter(
  connect(
    (state) => ({
      isTimerModalVisible: isTimerModalVisible(state),
      sources: getSources(state),
      settings: getReccordingSettings(state)
    }),
    (dispatch) => ({
      toggleTimerModal: () => dispatch(toggleTimerModal()),
      startRecording: (params: any) => dispatch(startRecording(params as any) as any)
    })
  )(TimerModal)
);
