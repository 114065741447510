import get from 'lodash/get';
import { groupBy } from 'lodash';
import { keys } from 'lodash';

const indexFields = ['indexedDB', 'webkitIndexedDB', 'mozIndexedDB', 'OIndexedDB', 'msIndexedDB'];
const transactionFields = ['IDBTransaction', 'webkitIDBTransaction', 'OIDBTransaction', 'msIDBTransaction'];
const keyRangeFields = ['IDBKeyRange', 'webkitIDBKeyRange', 'msIDBKeyRange'];

export class IndexDB {
  dbName = 'ScreenTape';
  indexedDB = IndexDB.getGlobal(indexFields);
  IDBTransaction = IndexDB.getGlobal(transactionFields);
  idbKeyRange = IndexDB.getGlobal(keyRangeFields);

  dbVersion = 1.0;

  static getGlobal = (fields: Array<string>) => {
    for (let field of fields) {
      const globalVal = get(window, field);

      if (globalVal) {
        return globalVal;
      }
    }

    return null;
  };

  constructor() {
    if (!this.indexedDB) {
      throw 'IndexedDB not supported';
    }

    const request = this.indexedDB.open(this.dbName, this.dbVersion);

    //create a objectStore
    request.onupgradeneeded = (e: any) => {
      const result = e.target.result;
      result.createObjectStore('handler', { keyPath: 'id' });
      result.createObjectStore('chunks', { keyPath: 'id' });
    };

    request.onsuccess = () => {
      console.log('db opened');
    }

    request.onerror = (e: any) => {
      console.log('db failed to open', e);
    };
  }

  getHandlerData = (key: string) => {
    const request = this.indexedDB.open(this.dbName, this.dbVersion);

    return new Promise((res, rej) => {
      request.onsuccess = (e: any) => {
        let result = e.target.result;
        let handlerTable = result
          .transaction('handler', 'readwrite')
          .objectStore('handler');
        handlerTable.get(key).onsuccess = (e: any) => {
          const existingData = e.target.result || {};
          res(existingData);
        }
      };

      request.onerror = (e: any) => {
        rej(e);
      };
    });
  }

  updateHandler = (key: string, meta: any) => {
    const request = this.indexedDB.open(this.dbName, this.dbVersion);

    request.onsuccess = (e: any) => {
      let result = e.target.result;
      let handlerTable = result
        .transaction('handler', 'readwrite')
        .objectStore('handler');
      handlerTable.get(key).onsuccess = (e: any) => {
        const existingData = e.target.result || {};
        const thumbnail = meta.thumbnail || existingData.thumbnail;

        handlerTable.put({ id: key, ...existingData, ...meta, thumbnail });
      }
    };

    request.onerror = (e: any) => {
      throw new Error('Database error!');
    };
  }

  saveChunk = (key: string, chunk: any, index: number) => {
    const request = this.indexedDB.open(this.dbName, this.dbVersion);

    request.onsuccess = (e: any) => {
      let result = e.target.result;
      let chunkTable = result.transaction('chunks', 'readwrite').objectStore('chunks');
      let handlerTable = result.transaction('handler', 'readwrite').objectStore('handler');
      chunkTable.add({ id: `${key}_${index}`, chunk: chunk });

      const request = handlerTable.get(key);

      request.onsuccess = (e: any) => {
        const existingHandlerData = e.target.result;
        handlerTable.put({ id: key, ...existingHandlerData, count: index });
      }
    };

    request.onerror = (e: any) => {
      throw new Error('Database error!');
    };
  };

  getHandlerById = async (key: string) => {
    const request = this.indexedDB.open(this.dbName, this.dbVersion);

    return new Promise((res: Function) => {
      request.onsuccess = ({ target: { result: db } }: any) => {
        const handlerTable = db.transaction('handler', 'readwrite').objectStore('handler');
        const request = handlerTable.get(key);

        request.onsuccess = (e: any) => {
          res(e.target.result);
        };
      };
    });
  };

  getChunks = async (key: string): Promise<BlobPart[]> => {
    const request = this.indexedDB.open(this.dbName, this.dbVersion);

    return new Promise((res) => {
      request.onsuccess = ({ target: { result: db } }: any) => {
        const chunkTable = db.transaction('chunks', 'readwrite').objectStore('chunks');
        const getAllRequest = chunkTable.getAll(IDBKeyRange.bound(key, key + '\uffff'));
        getAllRequest.onsuccess = function () {
          const chunks = getAllRequest.result
            .filter((item: any) => item.id.includes(key))
            .sort((item1: any, item2: any) => {
              const id1 = parseInt(item1.id.split('_')[1]);
              const id2 = parseInt(item2.id.split('_')[1]);

              return id1 - id2;
            })
            .map((item: any) => item.chunk);

          res(chunks);
        };
      };
    });
  };

  deleteChunks = async (key: string) => {
    const handler: any = await this.getHandlerById(key);

    if (!handler) {
      return;
    }

    const request = this.indexedDB.open(this.dbName, this.dbVersion);

    let tx: any;

    request.onsuccess = ({ target: { result: db } }: any) => {
      tx = db.transaction(db.objectStoreNames, 'readwrite');

      for (let chunkIndex = 1; chunkIndex <= handler.count; chunkIndex++) {
        tx.objectStore('chunks').delete(`${key}_${chunkIndex}`);
      }

      tx.objectStore('handler').delete(key);
    };
  };

  getStatistics = async () => {
    const request = this.indexedDB.open(this.dbName, this.dbVersion);

    return new Promise((done) => {
      request.onsuccess = async ({ target: { result: db } }: any) => {
        const chunkTable = db.transaction('chunks', 'readwrite').objectStore('chunks');
        const handlerTable = db.transaction('handler', 'readwrite').objectStore('handler');

        const getAllRequest = chunkTable.getAll();
        const getAllHandles = handlerTable.getAll();

        const chunks = (await new Promise(
          (res) => (getAllRequest.onsuccess = () => res(getAllRequest.result))
        )) as Array<any>;

        const handles = (await new Promise(
          (res) => (getAllHandles.onsuccess = () => res(getAllHandles.result))
        )) as Array<any>;

        const chunksById = groupBy(chunks, (item) => item.id.split('_')[0]);
        const orphanChunks = keys(chunksById).filter(
          (id) => !handles.find((item) => item.id === id)
        );
        const orphanHandles = handles.filter(
          (item) => !keys(chunksById).find((id) => item.id == id)
        );

        done({
          totalChunks: chunks.length,
          totalChunkSize: chunks.reduce(
            (total: number, item: any) => total + item.chunk.size,
            0
          ),
          totalVideos: handles.length,
          orphanChunks,
          orphanHandles,
          details: { chunks, handles }
        });
      };
    });
  };
}
