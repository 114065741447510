import * as React from 'react';
import { Redirect } from 'react-router';
import { connect } from 'react-redux';

import { isAuthenticated, getIsOffline } from 'app/redux/reducers/googleSignin';

interface RedirectIfAuthenticatedProps {
  allowAccess?: boolean;
  children: any;
}
const RedirectIfAuthenticated = ({
  allowAccess,
  children,
  ...rest
}: RedirectIfAuthenticatedProps) => {
  if (allowAccess) {
    var urlParams = new URLSearchParams(window.location.search);
    const redirect = decodeURIComponent(urlParams.get('redirect') || '/');
    return <Redirect to={redirect} />;
  }

  return <React.Fragment {...rest}>{children}</React.Fragment>;
};

export default connect((state) => ({
  allowAccess: isAuthenticated(state) && !getIsOffline(state)
}))(RedirectIfAuthenticated);
