import { Middleware } from 'redux';
import set from 'lodash/set';
import { getAuthToken, getApiToken } from 'app/redux/reducers/googleSignin';

export const addAccessToken: Middleware = (store) => (next) => async (action) => {
  const isHttpRequest = action.payload && action.payload.request;
  const { getState } = store;
  const accessToken = getAuthToken(getState());
  const apiToken = getApiToken(getState());

  set(action, 'data.access_token', accessToken);
  set(action, 'data.api_token', apiToken);


  if (!isHttpRequest) {
    return next(action);
  }

  if (action.payload.client === 'api') {
    set(action, 'payload.request.headers.Authorization', `Bearer ${apiToken}`);
  } else {
    set(action, 'payload.request.headers.Authorization', `Bearer ${accessToken}`);
  }

  set(action, 'payload.request.headers.Access-Control-Allow-Origin', '*');

  next(action);
};
