import { ActionTypes } from 'app/redux/actionTypes/index';
import { Dispatch } from 'react';
import { getApiToken } from '../reducers/googleSignin';
import { getRecordingStateData } from '../reducers/recordings';

export const googleRefreshAccessToken = (): any => (
  dispatch: Dispatch<any>,
  getState: Function
) => {
  const apiKey = getApiToken(getState());
  if (!apiKey) {
    return;
  }

  return dispatch({
    type: ActionTypes.REFRESH_ACCESS_TOKEN,
    payload: {
      client: 'api',
      request: {
        url: '/auth/google/refresh-token',
        method: 'POST'
      }
    }
  });
};

export const sendMessage = (title: string, message: string) => ({
  type: ActionTypes.SEND_MESSAGE,
  payload: {
    client: 'api',
    request: {
      url: '/auth/message',
      method: 'POST',
      data: { title, message }
    }
  }
});

export const deleteRecording = (fileId: string) => ({
  type: ActionTypes.REMOVE_RECORDING,
  payload: {
    client: 'api',
    request: {
      method: 'DELETE',
      url: `/files/${fileId}`,
      data: { trashed: true }
    },
    data: {
      fileId
    }
  }
});

export const getRecordings = (reset = false) => (dispatch: Dispatch<any>, getState: Function) => {
  const state = getState();
  const { page = 0, pageCount } = getRecordingStateData(state);

  if (!reset && page >= pageCount) {
    return;
  }

  const newPage = reset ? 1 : page + 1;

  return dispatch({
    type: ActionTypes.GET_RECORDINGS,
    payload: {
      client: 'api',
      request: {
        url: `/files?page=${newPage}`,
        method: 'GET'
      },
      data: { page: newPage }
    }
  });
};

export const updateRecordingName = ({ id, name }: any) => ({
  type: ActionTypes.UPDATE_RECORDING_NAME,
  payload: {
    client: 'api',
    request: { method: 'PATCH', url: `/files/${id}`, data: { name } },
    data: {
      name,
      fileId: id,
    }
  }
});

export const updateRecordingAccessType = ({ fileId, accessType }: any) => ({
  type: ActionTypes.UPDATE_RECORDING_PROPERTIES,
  payload: {
    client: 'api',
    request: {
      method: 'PATCH',
      url: `/files/${fileId}`,
      data: { accessType }
    },
    data: { fileId }
  }
});

export const shareRecordingWithEmails = ({ id, sharedWith, message }: any) => ({
  type: ActionTypes.SHARE_FILE,
  payload: {
    client: 'api',
    request: { method: 'POST', url: `/files/${id}/share`, data: { sharedWith, message } },
    data: { name, fileId: id, sharedWith }
  }
});

export const toggleRecordingFavorite = (fileId: string, isFavorite: boolean) => ({
  type: ActionTypes.UPDATE_RECORDING_PROPERTIES,
  payload: {
    client: 'api',
    request: {
      method: 'PATCH',
      url: `/files/${fileId}`,
      data: { isFavorite }
    },
    data: { fileId }
  }
});

export const whereToStoreFile = (size: number) => ({
  type: ActionTypes.WHERE_TO_STORE_FILE,
  payload: {
    client: 'api',
    request: {
      method: 'GET',
      url: `/files/where-to-store?size=${size}`
    }
  }
});

export const getSharedFile = (fileId: string, token: string) => ({
  type: ActionTypes.GET_SHARED_FILE,
  payload: {
    client: 'api',
    request: {
      method: 'GET',
      url: `/shared/${fileId}?token=${token}`
    }
  }
});

export const getSharedRecordings = () => ({
  type: ActionTypes.GET_SHARED_FILES,
  payload: {
    client: 'api',
    request: {
      method: 'GET',
      url: `/files/shared`
    }
  }
});
