import { AnyAction } from 'redux';
import { StoreModel, UploadingModel, UploadingStatus } from 'app/models/StoreModel';
import { ActionTypes } from 'app/redux/actionTypes/index';
import get from 'lodash/get';

export const STATE_KEY = 'uploads';

export const reducer = (state: Array<UploadingModel> = [], action: AnyAction) => {
  switch (action.type) {
    case ActionTypes.UPLOAD_RECORDING: {
      const uploadingRecording = state.find((r) => r.id === get(action, 'payload.data.uploading.id'));

      // add uploading object only if not already present
      if (!uploadingRecording) {
        return [...state, get(action, 'payload.data.uploading')];
      }

      uploadingRecording.status = UploadingStatus.PENDING;

      return state;
    }
    case ActionTypes.REMOVE_RECORDING: {
      return JSON.parse(
        JSON.stringify([...state.filter((f) => f.id !== get(action, 'payload.data.fileId'))])
      );
    }
    case `${ActionTypes.UPLOAD_RECORDING}_SUCCESS`: {
      const upload = get(action, 'meta.previousAction.payload.data.uploading');

      return [...state.filter((u) => u.id !== upload.id)];
    }
    case `${ActionTypes.UPLOAD_RECORDING}_FAIL`: {
      const upload = get(action, 'meta.previousAction.payload.data.uploading');

      return [
        ...state.filter((u) => u.id !== upload.id),
        {
          ...upload,
          status: UploadingStatus.FAILED,
          error: action.error
        }
      ];
    }
    case `${ActionTypes.UPLOAD_RECORDING}_PROGRESS`: {
      const { uploading, progress } = action.payload.data;

      return [
        ...state.filter((u) => u.id !== uploading.id),
        {
          ...uploading,
          progress,
          status: UploadingStatus.IN_PROGRESS
        }
      ];
    }
    case ActionTypes.CANCEL_UPLOAD: {
      const uploadingRecording =
        state.find((r) => r.id === action.payload.data.id) || ({ uploading: {} } as any);
      if (!uploadingRecording) {
        return state;
      }

      if (uploadingRecording.uploader && uploadingRecording.uploader.cancel) {
        uploadingRecording.uploader.cancel();
      }

      //uploadingRecording.uploader = null;

      return state.filter((upload) => upload != uploadingRecording);
    }
    case ActionTypes.STOP_UPLOAD: {
      const uploadingRecording =
        state.find((r) => r.id === action.payload.data.id) || ({ uploading: {} } as any);
      if (!uploadingRecording) {
        return state;
      }

      if (uploadingRecording.uploader && uploadingRecording.uploader.cancel) {
        uploadingRecording.uploader.cancel();
      }

      //uploadingRecording.uploader = null;

      uploadingRecording.status = UploadingStatus.CANCELED;

      return [...state];
    }

    case ActionTypes.RETRY_UPLOAD: {
      const uploadingRecording =
        state.find((r) => r.id === action.payload.data.id) || ({ uploading: {} } as any);
      if (!uploadingRecording) {
        return state;
      }

      return [...state];
    }

    default:
      return state;
  }
};

export const isUploadInProgress = (state: StoreModel) => {
  return state[STATE_KEY].find(r => r.status === UploadingStatus.IN_PROGRESS);
};

export const isUploadFailed = (state: StoreModel) => {
  return state[STATE_KEY].find((r) => r.status === UploadingStatus.FAILED);
};

export const getUploads = (state: StoreModel) => {
  return state[STATE_KEY];
};

export const getUploadById = (state: StoreModel, recordingId: string) => {
  return state[STATE_KEY].find((upload: UploadingModel) => upload.id === recordingId);
};
