import React from 'react';
import { connect } from 'react-redux';
import { StoreModel } from 'app/models/StoreModel';
import {
  getReccordingSettings,
  isLoadingSources,
  getNumberOfDisplays,
  getRecordingType
} from 'app/redux/reducers/recordingSelector';
import {
  setRecordingSettings,
} from 'app/redux/actions';
import { getSources } from 'app/redux/reducers/recordingSelector';

import styled from 'app/utils/theme';
import Modal from 'app/components/molecules/Modal';
import Icon from 'app/components/atoms/Icon';
import Text from 'app/components/atoms/Text';
import IconButton from 'app/components/molecules/IconButton';
import Select from 'app/components/molecules/Select';

interface ModalProps {
  setRecordingSettings?: Function;
  settings?: StoreModel.RecordingSettings;
  closeSettingsModal?: Function;
}

class SelectSourceModal extends React.Component<ModalProps> {
  state = {
    isLoading: true,
    devices: [],
    selectedVideo: [],
    selectedAudio: [],
  };

  async componentDidMount() {
    navigator.mediaDevices.enumerateDevices().then(devices => {
      this.setState({
        devices,
        isLoading: false,
      })
    })
  }

  render() {
    const {
      closeSettingsModal = () => {},
      setRecordingSettings = () => {},
    } = this.props;
    const { speakersDeviceId = '', webCamDeviceId = '', microphoneDeviceId = '' } = this.props.settings || {};

    const videoOptions = this.state.devices && this
      .state
      .devices
      .filter((device: any) => device.kind === 'videoinput')
      .map((device: any) => ({
        key: device.deviceId,
        text: device.label,
        value: device.deviceId,
      }));

      const audioOptions =
        this.state.devices &&
        this.state.devices
          .filter((device: any) => device.kind === 'audiooutput')
          .map((device: any) => ({
            key: device.deviceId,
            text: device.label,
            value: device.deviceId
          }));
          const microphoneOptions =
            this.state.devices &&
            this.state.devices
              .filter((device: any) => device.kind === 'audioinput')
              .map((device: any) => ({
                key: device.deviceId,
                text: device.label,
                value: device.deviceId
              }));

    return (
      <Modal onClose={() => closeSettingsModal()}>
        <br />
        <ModalContent>
          {this.state.isLoading && (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <IconButton icon="spinner" spin active size="3x" />
            </div>
          )}
          {this.state.devices && (
            <div>
              <Text pTop={100}>Video/Audio Configuration:</Text>
              <Text pTop={10} pBottom={30}>
                Webcam
                <br />
                <Select
                  value={webCamDeviceId}
                  placeholder="Select Webcam"
                  options={videoOptions}
                  onChange={(value: any) =>
                    setRecordingSettings({
                      webCamDeviceId: value
                    })
                  }
                  noResultsMessage=""
                  additionLabel="Select Webcam"
                  multiple={false}
                />
              </Text>
              <Text pBottom={30}>
                Speakers
                <br />
                <Select
                  value={speakersDeviceId}
                  placeholder="Select Speakers"
                  options={audioOptions}
                  onChange={(value: any) => setRecordingSettings({ speakersDeviceId: value })}
                  noResultsMessage=""
                  additionLabel="Select Audio"
                  multiple={false}
                />
              </Text>
              <Text pBottom={30}>
                Microphone
                <br />
                <Select
                  value={microphoneDeviceId}
                  placeholder="Select Microphone"
                  options={microphoneOptions}
                  onChange={(value: any) => setRecordingSettings({ microphoneDeviceId: value })}
                  noResultsMessage="Type to add email"
                  additionLabel="Select Audio"
                  multiple={false}
                />
              </Text>
            </div>
          )}
        </ModalContent>
      </Modal>
    );
  }
}

const ModalContent = styled.div`
  position: relative;
  height: 88%;
  overflow-y: scroll;
  overflow-x: hidden;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;

  padding-left: 15px;
  margin-left: -38px;

  ${Icon} {
    font-size: 25em;
    opacity: 0.35;
    position: absolute;
    z-index: 10000;
  }
`;

export default connect(
  (state) => ({
    sources: getSources(state),
    settings: getReccordingSettings(state),
    isLoadingSources: isLoadingSources(state),
    numberOfDisplays: getNumberOfDisplays(state),
    recordType: getRecordingType(state)
  }),
  (dispatch) => ({
    closeSettingsModal: () => dispatch(setRecordingSettings({ showSettingsModal: false })),
    setRecordingSettings: (settings: StoreModel.RecordingSettings) =>
      dispatch(setRecordingSettings(settings))
  })
)(SelectSourceModal);
