import React from 'react';
import { connect } from 'react-redux';
import Page from 'app/components/pages/Page';

import {
  hasRecordings,
  getPlayRecording,
  isShareRecordModalVisible,
  getRecordingsField,
} from 'app/redux/reducers/recordings';
import { isShowSendMessageModal } from 'app/redux/reducers/googleSignin';

import { getActiveRecordingField, isTimerModalVisible } from 'app/redux/reducers/activeRecording';
import { isSourceSelectorVisible, getSettingsField } from 'app/redux/reducers/recordingSelector';
import {
  getRecordings as _getRecordings,
  getUserProfile as _getUserProfile,
  getSharedRecordings as _getSharedRecordings,
} from 'app/redux/actions';
import ProgressWithLogo from 'app/components/molecules/ProgressWithLogo';
import EmptyRecordingList from 'app/components/organisms/EmptyRecordingsList';
import RecordingList from 'app/components/organisms/RecordingsList';
import SelectSourceModal from 'app/components/organisms/SelectSourceModal';
import TimerModal from 'app/components/organisms/TimerModal';
import ShareRecordModal from 'app/components/organisms/ShareRecordModal';
import SendMessageModal from 'app/components/organisms/SendMessage';

import { StoreModel } from 'app/models';
import EditScreenshot from '../organisms/EditScreenshot';
import SettingsModal from '../organisms/SettingsModal';

interface ListProps {
  hasRecordings: Boolean;
  isTimerModalVisible: boolean;
  isSourceSelectorVisible: boolean;
  isShareRecordModalVisible: boolean;
  getRecordings?: Function;
  playRecording?: StoreModel.RecordingModel;
  isLoadingFromPage1: boolean;
  getUserProfile?: Function;
  showSendMessageModal: boolean;
  toggleSendMessageModal?: Function;
  getSharedRecordings?: Function;
  showEditScreenshotModal: Boolean;
  showSettingsModal: Boolean;
}

/**
 * To be refactored - moved this redirection onto the router logic
 */
@connect(
  (state) => ({
    hasRecordings: hasRecordings(state),
    isTimerModalVisible: isTimerModalVisible(state),
    isSourceSelectorVisible: isSourceSelectorVisible(state),
    isShareRecordModalVisible: isShareRecordModalVisible(state),
    playRecording: getPlayRecording(state),
    showSendMessageModal: isShowSendMessageModal(state),
    isLoadingFromPage1:
      getRecordingsField('page', state) < 2 && getRecordingsField('isLoading', state),
    showEditScreenshotModal: getActiveRecordingField('toggleEditScreenshot', state),
    showSettingsModal: getSettingsField('showSettingsModal', state),
  }),
  (dispatch) => ({
    getRecordings: (reset: boolean) => dispatch(_getRecordings(reset) as any),
    getUserProfile: () => dispatch(_getUserProfile() as any),
    getSharedRecordings: () => dispatch(_getSharedRecordings() as any)
  })
)
export class ListPage extends Page<ListProps> {
  componentWillMount() {
    const {
      getRecordings = () => {},
      getUserProfile = () => {},
      getSharedRecordings = () => {}
    } = this.props;

    getRecordings();
    getSharedRecordings();
    getUserProfile();
  }
  render() {
    const {
      isLoadingFromPage1,
      hasRecordings,
      isSourceSelectorVisible,
      isTimerModalVisible,
      isShareRecordModalVisible,
      showSendMessageModal,
      showEditScreenshotModal,
      showSettingsModal,
    } = this.props;

    if (isLoadingFromPage1) {
      return (
        <div
          style={{
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <ProgressWithLogo />
        </div>
      );
    }

    return (
      <React.Fragment>
        {isSourceSelectorVisible && <SelectSourceModal />}
        {isTimerModalVisible && <TimerModal />}
        {!hasRecordings ? <EmptyRecordingList /> : <RecordingList />}
        {isShareRecordModalVisible && <ShareRecordModal />}
        {showSendMessageModal && <SendMessageModal />}
        {showEditScreenshotModal && <EditScreenshot />}
        {showSettingsModal && <SettingsModal />}
      </React.Fragment>
    );
  }
}
