import * as React from 'react';
import { Search as SemanticSearch } from 'semantic-ui-react';
import styled from 'app/utils/theme';

interface SearchInputProps {
  onSearch: Function;
  className?: string;
}

const Search = ({ className, onSearch }: SearchInputProps) => (
  <SemanticSearch
    placeholder="Search"
    className={className}
    onSearchChange={(event, data) => onSearch(data.value)}
    open={false}
    fluid
  />
);

const StyledSearch = styled(Search)`
  .ui.input > input {
    height: 28px !important;
    width: 138px !important;
    transition: width 0.2s;
    background-color: ${(props) => props.theme.search.backgroundColor} !important;
    border-radius: 4px !important;
    border: 1px solid ${(props) => props.theme.search.borderColor} !important;
  }
  .ui.input > input:focus {
    background-color: rgba(255, 255, 255, 0.9) !important;
    width: 256px !important;
  }

  i {
    color: ${(props) => props.theme.search.borderColor} !important;
  }
`;

export default StyledSearch;
