import * as React from 'react';
import styled, { css } from 'app/utils/theme';

const weight = (props: TextProps) => css`
  font-weight: lighter;
  ${props.light && 'font-weight: 300;'};
  ${props.veryLight && 'font-weight: 100;'};

  ${props.semiBold && 'font-weight: 500;'};
  ${props.bold && 'font-weight: bold;'};
  ${props.veryBold && 'font-weight: 900;'};
`;

const lineHeight = (props: TextProps) => css`
  line-height: 1.2;
  ${props.lineHeight && `line-height: ${props.lineHeight}`};
`;

const padding = (props: TextProps) => css`
  ${props.pLeft && `padding-left: ${props.pLeft}px`};
  ${props.pRight && `padding-right: ${props.pRight}px`};
  ${props.pTop && `padding-top: ${props.pTop}px`};
  ${props.pBottom && `padding-bottom: ${props.pBottom}px`};
  ${props.p && `padding: ${props.p}px`};
`;
const size = (props: TextProps) => css`
  font-size: 16px;
  ${props.s && 'font-size: 14px;'};
  ${props.ssm && 'font-size: 16px;'};
  ${props.sm && 'font-size: 20px;'};
  ${props.m && 'font-size: 24px;'};
  ${props.l && 'font-size: 32px;'};
  ${props.xl && 'font-size: 40px;'};
  ${props.xxl && 'font-size: 60px;'};
  ${props.xxxl && 'font-size: 64px;'};
  ${props.xxxsl && 'font-size: 75px;'};
  ${props.xxxxl && 'font-size: 272px;'};

  ${props.fs && `font-size: ${props.fs}px;`};
`;

export interface TextProps {
  logo?: boolean;
  active?: boolean;
  pasive?: boolean;
  error?: boolean;
  context?: any;
  children?: any;
  // font-size
  s?: boolean;
  ssm?: boolean;
  sm?: boolean;
  m?: boolean;
  l?: boolean;
  xl?: boolean;
  xxl?: boolean;
  xxxl?: boolean;
  xxxsl?: boolean;
  xxxxl?: boolean;
  fs?: number;
  // lineHeight
  lineHeight?: string;
  // padding
  pLeft?: number;
  pRight?: number;
  pTop?: number;
  pBottom?: number;
  p?: number;
  // weight
  light?: boolean;
  veryLight?: boolean;
  semiBold?: boolean;
  bold?: boolean;
  veryBold?: boolean;

  italic?: boolean;
  // to add proper theme schema
  theme?: any;

  // limit size
  maxSize?: number;
  underline?: boolean;
  onClick?: (event?: any) => void;
  id?: string;
  customColor?: string;
  style?: any;
}

const color = (props: TextProps) => css`
  color: ${props.theme.text.colors.regular};
  ${props.active && `color: ${props.theme.text.colors.active}`};
  ${props.pasive && `color: ${props.theme.text.colors.pasive}`};
  ${props.error && `color: ${props.theme.text.colors.red}`};
  ${props.customColor && `color: ${props.customColor}`};
`;

const TextWithSize = ({ children, maxSize, ...rest }: TextProps) => {
  const childrenNotString = typeof children !== 'string';
  if (childrenNotString) {
    return <p {...rest}>{children}</p>;
  }

  const childrenNotOverflowing = !maxSize || children.length <= maxSize;
  if (childrenNotOverflowing) {
    return <p {...rest}>{children}</p>;
  }

  return <p {...rest}>{children.substring(0, maxSize)}...</p>;
};

const Text = styled(TextWithSize)<TextProps>`
  font-family: ${(props) => props.theme.text.fonts.default};
  ${(props) => props.logo && `font-family: ${props.theme.text.fonts.logo}`};
  ${(props) => props.underline && `text-decoration: underline;`}
  ${(props) => (props.underline || props.onClick) && `cursor: pointer;`}
  ${(props) => props.italic && `font-style: italic;`}
  ${color}
  ${weight}
  ${lineHeight}
  ${size}
  ${padding}
  margin: 0;
`;

const defaultProps: TextProps = {
  onClick: () => {},
  active: true
};

Text.defaultProps = defaultProps;

export default Text;
