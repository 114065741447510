export const SORT_TYPE = {
  BY_DATE_ASC: 1,
  BY_DATE_DESC: 2,
  BY_DURATION_ASC: 3,
  BY_DURATION_DESC: 4,
  BY_NAME_ASC: 5,
  BY_NAME_DESC: 6
};

export const SORT_OPTIONS = [
  {
    type: SORT_TYPE.BY_DATE_ASC,
    label: 'By Date',
    icon: 'sort-amount-up'
  },
  {
    type: SORT_TYPE.BY_DATE_DESC,
    label: 'By Date',
    icon: 'sort-amount-down'
  },
  {
    type: SORT_TYPE.BY_DURATION_ASC,
    label: 'By Duration',
    icon: 'sort-amount-up'
  },
  {
    type: SORT_TYPE.BY_DURATION_DESC,
    label: 'By Duration',
    icon: 'sort-amount-down'
  },
  {
    type: SORT_TYPE.BY_NAME_ASC,
    label: 'By Name',
    icon: 'sort-amount-up'
  },
  {
    type: SORT_TYPE.BY_NAME_DESC,
    label: 'By Name',
    icon: 'sort-amount-down'
  }
];

export const SELECTED_RECORDING_OPTIONS = [
  {
    id: 'MINE',
    text: 'Mine',
    tooltip: 'View recordings created by me'
  },
  {
    id: 'SHARED',
    text: 'Shared with me',
    tooltip: 'View recordings shared with me by others'
  }
];
