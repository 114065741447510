import { StartRecordingProps } from 'app/models/StoreModel';
import { Reader, Decoder, tools } from 'ts-ebml';
import { IndexDB } from 'app/utils/indexDB';
import { RecordActionType } from 'app/config';
import 'fabric';

const fabric = window.fabric;

function formatBytes(bytes: any, decimals = 2) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export class Recorder {
  static stream: any = null;
  static audioStream: any = null;
  static webCamStream: any = null;
  static recorder: any = null;
  static recordedChunks: number = 0;
  static thumbnail: any = null;
  static IndexedDB = new IndexDB();
  static recordSize = 0;
  static canvas: any;

  static injectMetadata = async (blob: any) => {
    const decoder = new Decoder();
    const reader = new Reader();
    reader.logging = false;
    reader.drop_default_duration = false;

    const buffer: any = await Recorder.readAsArrayBuffer(blob);

    let elms = decoder.decode(buffer);
    const validEmlType = ['m', 'u', 'i', 'f', 's', '8', 'b', 'd']; // This is from elm type of the lib
    elms = elms?.filter((elm) => validEmlType.includes(elm.type));

    elms.forEach((elm) => {
      reader.read(elm);
    });
    reader.stop();

    const refinedMetadataBuf = tools.makeMetadataSeekable(
      reader.metadatas,
      reader.duration,
      reader.cues
    );
    const body = buffer.slice(reader.metadataSize);

    const result = new Blob([refinedMetadataBuf, body], {
      type: blob.type
    });

    return result;
  };

  static waitForRecorderOnDataAvailable = () => {
    let maxSecondsToWait = 120;
    return new Promise((res, rej) => {
      const interval = setInterval(() => {
        const timeRanOut = maxSecondsToWait-- === 0;
        if (timeRanOut) {
          clearInterval(interval);
          return rej();
        }
        if (Recorder.recordedChunks > 0) {
          clearInterval(interval);
          res(1);
        }
      }, 1000);
    });
  };

  static clear = () => {
    Recorder.recordedChunks = 0;
    Recorder.stream = null;
    Recorder.webCamStream = null;
    Recorder.recorder = null;
    Recorder.thumbnail = null;
    Recorder.recordedChunks = 0;
    Recorder.canvas = null;
  };

  static getUserMediaError = () => {};

  static videoConstraints = {
    // width: { min: 320, ideal: 320, max: 640 },
    // height: { min: 240, ideal: 240, max: 480 },
    frameRate: 30 // { max: 60, ideal: 30, min: 10 },
  };

  static start = async ({
    id,
    type,
    stream,
    onExternalStop = () => {},
    onRecordingSize = () => {},
    settings: { webCamDeviceId, microphoneDeviceId }
  }: StartRecordingProps): Promise<any> => {
    Recorder.clear();
    Recorder.audioStream = await (<any>navigator).mediaDevices.getUserMedia({
      audio: microphoneDeviceId ? { deviceId: microphoneDeviceId } : true,
      video: false,
      systemAudio: 'include'
    });

    switch (type) {
      case RecordActionType.VIDEO: {
        Recorder.stream = stream;
        Recorder.mergeAudioTracks();
        // Recorder.renderStreamOnCanvas(stream);
        break;
      }
      case RecordActionType.AUDIO:
        Recorder.stream = Recorder.audioStream;
        break;
      case RecordActionType.WEBCAM: {
        Recorder.webCamStream = stream;
        Recorder.stream = Recorder.webCamStream;
        Recorder.mergeAudioTracks();
        Recorder.renderStreamOnCanvas(stream);
        break;
      }
      case RecordActionType.VIDEOANDWEBCAM: {
        Recorder.webCamStream = stream;

        // add video and webcam to it
        const canvasStream = await Recorder.renderStreamOnCanvas(stream);

        // set Recorder.stream to canvas stream
        Recorder.stream = canvasStream;
        Recorder.mergeAudioTracks();
        break;
      }
    }

    const options = Recorder.getRecordSettings();

    Recorder.recorder = new (<any>window).MediaRecorder(Recorder.stream, options);

    Recorder.recorder.ondataavailable = (e: any) => {
      Recorder.recordedChunks += 1;
      Recorder.recordSize += e.data.size;
      Recorder.IndexedDB.saveChunk(id, e.data, Recorder.recordedChunks);

      console.log(`recorder ${formatBytes(Recorder.recordSize)}...`);

      onRecordingSize(Recorder.recordSize, id);
    };

    Recorder.recorder.onstop = () => console.log('recorderOnStop fired');

    Recorder.recorder.start(5000);

    if (type !== RecordActionType.AUDIO) {
      Recorder.thumbnail = await Recorder.takeSnapshot();
      Recorder.IndexedDB.updateHandler(id, { thumbnail: Recorder.thumbnail });
    }

    // if user stops the stream from outside the app trigger stop recording
    Recorder.onExternalStop().then(() => onExternalStop());
  };

  static onExternalStop = async () => {
    return new Promise((res) => {
      const hasVideoStream = Recorder.stream.getVideoTracks().length;
      const hasAudioStream = Recorder.stream.getAudioTracks().length;

      if (hasVideoStream) {
        Recorder.stream.getVideoTracks()[0].addEventListener('ended', res);
      } else if (hasAudioStream) {
        Recorder.stream.getAudioTracks()[0].addEventListener('ended', res);
      }
    });
  };

  static stop = async () => {
    try {
      console.time('StopRecord');
      Recorder.recorder.stop();

      if (Recorder.stream) {
        Recorder.stream.getTracks().forEach((track: any) => track.stop());
      }

      if (Recorder.webCamStream) {
        Recorder.webCamStream.getTracks().forEach((track: any) => track.stop());
      }

      if (Recorder.audioStream) {
        Recorder.audioStream.getTracks().forEach((track: any) => track.stop());
      }

      // wait for recorderOnDataAvailable to get called
      await Recorder.waitForRecorderOnDataAvailable();

      if (Recorder.canvas) {
        Recorder.canvas.dispose();
      }
      console.timeEnd('StopRecord');
      return Promise.resolve({ snapshot: Recorder.thumbnail });
    } catch (e) {
      return Promise.reject(e);
    }
  };

  static readAsArrayBuffer = (blob: any) => {
    return blob.arrayBuffer();
  };

  static cancel = () => {
    try {
      Recorder.recorder.stop();
      return null;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  static pause = () => {
    try {
      Recorder.recorder.pause();
      return null;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  static resume = () => {
    try {
      Recorder.recorder.resume();
      return null;
    } catch (e) {
      return Promise.reject(e);
    }
  };

  private static mergeAudioTracks() {
    // CASE 1: IF NO MICROPHONE STREAM PRESENT DON'T DO NOTHING
    if (!Recorder.audioStream) {
      return;
    }

    // CASE 2: IF VIDEO STREAM HAS NO AUDIO TRACK SET DIRECTLY THE MICROPHONE TRACK
    const existingAudioTrack = Recorder.stream.getAudioTracks()[0];
    if (!existingAudioTrack) {
      Recorder.stream.addTrack(Recorder.audioStream.getAudioTracks()[0]);
      return;
    }

    // CASE 3: IF WE HAVE BOTH MICROPHONE AND AUDIO TRACK FROM STREAM THEN MERGE BOTH TRACKS
    const audioContext = new AudioContext();

    let audioIn_01 = audioContext.createMediaStreamSource(Recorder.audioStream);
    let audioIn_02 = audioContext.createMediaStreamSource(Recorder.stream);

    let dest = audioContext.createMediaStreamDestination();

    audioIn_01.connect(dest);
    audioIn_02.connect(dest);

    let FinalStream = dest.stream;

    Recorder.stream.removeTrack(existingAudioTrack);
    Recorder.stream.addTrack(FinalStream.getAudioTracks()[0]);
  }

  private static async renderStreamOnCanvas(stream: any) {
    // create fabric.js canvas
    Recorder.canvas = new fabric.Canvas('bigCanvas', {
      width: window.innerWidth,
      height: window.innerHeight
    });

    if (stream) {
      const videoEl = document.createElement('video');
      videoEl.srcObject = stream;
      videoEl.style.display = 'none';
      videoEl.width = window.innerWidth;
      videoEl.height = window.innerHeight;
      videoEl.muted = true;
      videoEl.play();
      document.body.appendChild(videoEl);

      var video1 = new fabric.Image(videoEl, {
        objectCaching: false
      });

      Recorder.canvas.add(video1);
    } else if (Recorder.webCamStream) {
      const webCamEl = document.createElement('video');
      webCamEl.srcObject = Recorder.webCamStream;
      webCamEl.style.display = 'none';
      webCamEl.width = window.innerWidth, // / 4;
      webCamEl.height = window.innerHeight, //((window.innerWidth / 4) * 9) / 16;
      webCamEl.muted = true;
      document.body.appendChild(webCamEl);
      webCamEl.play();

      await new Promise((resolve) => (webCamEl.onloadedmetadata = resolve));

      var webcam = new fabric.Image(webCamEl, {
        objectCaching: false,
        hasRotatingPoint: false,
        // scaleX: 1,
        // scaleY: 1,
        // width: webCamEl.width / 4,
        // height: ((webCamEl.width / 4) * 9) / 16
        // clipPath: new fabric.Circle({
        //   width: webCamEl.width / 4,
        //   height: ((webCamEl.width / 4) * 9) / 16,
        //   absolutePositioned: true,
        //   top: 0,
        //   left: 0,
        //   radius: 100,
        //   stroke: 'white',
        //   strokeWidth: 100,
        //   hasRotatingPoint: false
        // })
      });

      window['globalWebCam'] = webcam;
      Recorder.canvas.add(webcam);
    }

    fabric.util.requestAnimFrame(function render() {
      // https://stackoverflow.com/questions/66813248/video-capture-of-canvas-element-by-mediastream-recording-api-is-not-working
      Recorder.canvas.add(
        new fabric.Rect({
          left: 0,
          top: 0,
          width: 1,
          height: 1
        })
      );
      Recorder.canvas.renderAll();
      fabric.util.requestAnimFrame(render);
    });

    // get stream from canvas
    const canvasStream = document.getElementById('bigCanvas') as any;

    return canvasStream.captureStream(25);
  }

  private static getRecordSettings() {
    let options = {};
    if (typeof (<any>window).MediaRecorder.isTypeSupported == 'function') {
      /*
                MediaRecorder.isTypeSupported is a function announced in https://developers.google.com/web/updates/2016/01/mediarecorder and later introduced in the MediaRecorder API spec http://www.w3.org/TR/mediastream-recording/
            */
      if ((<any>window).MediaRecorder.isTypeSupported('video/webm;codecs=vp9')) {
        options = {
          ...options,
          mimeType: 'video/webm;codecs=vp9'
        };
      } else if ((<any>window).MediaRecorder.isTypeSupported('video/webm;codecs=h264')) {
        options = {
          mimeType: 'video/webm;codecs=h264'
        };
      } else if ((<any>window).MediaRecorder.isTypeSupported('video/webm;codecs=vp8')) {
        options = {
          mimeType: 'video/webm;codecs=vp8,opus'
        };
      }
    }
    return {
      ...options,
      audioBitsPerSecond: 105 * 128 * 1000, // x * 128 kbit/s
      videoBitsPerSecond: 8 * 1000 * 1000 // x * 1 Mbit/s
    };
  }

  static takeSnapshot() {
    const video = document.createElement('video') as HTMLVideoElement;
    const canvas = document.createElement('canvas');

    video.srcObject = Recorder.stream;
    video.muted = true;
    video.play();

    canvas.width = 400;
    canvas.height = (400 * 9) / 16;

    return new Promise((res, rej) => {
      setTimeout(() => {
        try {
          (canvas.getContext('2d') as any).drawImage(video, 0, 0, 400, (400 * 9) / 16);

          const thumbnail = canvas.toDataURL('image/png');

          video.pause();
          video.removeAttribute('src'); // empty source
          video.load();

          res(thumbnail);
        } catch (e) {
          rej(e);
        }
      }, 1000);
    });
  }
}

export const startRecording = (params: StartRecordingProps) => Recorder.start(params);

export const stopRecording = () => Recorder.stop();

export const pauseRecording = () => Recorder.pause();

export const cancelRecording = () => Recorder.cancel();

export const resumeRecording = () => Recorder.resume();

window['globalRecorder'] = Recorder;
