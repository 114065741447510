import moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import styled from 'app/utils/theme';
import IconButton from 'app/components/molecules/IconButton';
import Text from 'app/components/atoms/Text';
import { getActiveRecording } from 'app/redux/reducers/activeRecording';
import { StoreModel } from 'app/models';
import {
  resumeRecording,
  stopRecording,
  pauseRecording,
  startRecording,
  cancelRecording,
  updateActiveRecording,
  incrementRecordingTime,
  sendWindowEvent
} from 'app/redux/actions';
import { MAX_S3_RECORDING_SIZE } from 'app/config';

export interface RecordingControlsProps extends RouteComponentProps {
  onStop?: Function;
  onPause?: Function;
  onPlay?: Function;
  onStart?: Function;
  onCancel?: Function;
  activeRecording?: StoreModel.ActiveRecording;
  updateActiveRecording?: Function;
  incrementRecordingTime?: Function;
  sendWindowEvent?: Function;
}

class RecordingControls extends React.Component<RecordingControlsProps> {
  state = {
    interval: 0
  };

  componentDidMount() {
    const { sendWindowEvent = () => { } } = this.props;
    sendWindowEvent({ eventName: 'resize-to-record-controls' });
    const { incrementRecordingTime = () => {} }: RecordingControlsProps = this.props;
    this.setState({
      interval: setInterval(() => {
        incrementRecordingTime();
      }, 1000)
    });
  }
  componentWillUnmount() {
    const { sendWindowEvent = () => {} } = this.props;

    sendWindowEvent({ eventName: 'resize-to-window' });

    clearInterval(this.state.interval);
  }

  componentDidUpdate(prevProps: Readonly<RecordingControlsProps>, prevState: Readonly<{}>, snapshot?: any): void {
    const {
      activeRecording, onStop = () => {},
    } = this.props;
    // get current recording size
    // if size is still under max continue
    if (activeRecording && activeRecording?.size > MAX_S3_RECORDING_SIZE) {
      // if size is greater than max then (what a hack...)
      // 1. stop current recording
      onStop();
    }
  }

  render() {
    const {
      onPause,
      onStop,
      onPlay,
      onCancel,
      sendWindowEvent = () => {},
      activeRecording = {
        id: '',
        state: StoreModel.ActiveRecordingState.STOPPED,
        name: '',
        time: 0,
        loading: false,
        error: null,
        showTimerModal: false,
        thumbnail: null,
        blob: null,
        toggleEditScreenshot: false,
        size: 0,
      }
    }: RecordingControlsProps = this.props;

    return (
      <div>
        <RecordingControlsWrapper
          onMouseEnter={() =>
            sendWindowEvent({
              eventName: 'window-high-opacity'
            })
          }
          onMouseLeave={() => sendWindowEvent({ eventName: 'window-low-opacity' })}
        >
          <RecordingControlsRow>
            <Text xxxl bold pBottom={20}>
              {moment()
                .startOf('day')
                .seconds(activeRecording.time || 0)
                .format('H:mm:ss')}
            </Text>
          </RecordingControlsRow>
          <RecordingControlsRow>
            {activeRecording.state === StoreModel.ActiveRecordingState.STARTED && (
              <IconButton active icon="pause" size="3x" onClick={onPause} />
            )}
            {activeRecording.state === StoreModel.ActiveRecordingState.PAUSED && (
              <div style={{ width: '40px', height: '40px' }}>
                <IconButton active icon="play" size="3x" onClick={onPlay} />
              </div>
            )}
            <IconButton active icon="stop" size="3x" onClick={onStop} />
            <IconButton active icon="times-circle" size="3x" onClick={onCancel} />
          </RecordingControlsRow>
        </RecordingControlsWrapper>
        <BigCanvas id="bigCanvas" />
      </div>
    );
  }
}

const BigCanvas = styled.canvas.attrs({
    id: 'bigCanvas'
  })`
  top: 0;
  position: absolute;
`;
const RecordingControlsWrapper = styled.div`
  position: absolute;
  top: 0;
  margin-left: auto;
  margin-right: auto;
  z-index: 10000;
  border: 1px solid black;
  width: 350px;
  height: 200px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  border-radius: 10px;
  margin-left: 35%;
  background: linear-gradient(180deg, #32353a 0%, #292b2f 100%);
`;

const RecordingControlsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
`;

export default withRouter(
  connect(
    (state) => ({
      activeRecording: getActiveRecording(state)
    }),
    (dispatch, props) => ({
      sendWindowEvent: (params: any) => dispatch(sendWindowEvent(params)),
      incrementRecordingTime: () => dispatch(incrementRecordingTime()),
      updateActiveRecording: (data: StoreModel.ActiveRecording) =>
        dispatch(updateActiveRecording(data)),
      onPlay: () => dispatch(resumeRecording()),
      onStart: () => dispatch(startRecording({ history: props.history }) as any),
      onStop: () => {
        dispatch(stopRecording({ history: props.history }));
      },
      onPause: () => dispatch(pauseRecording()),
      onCancel: () => {
        dispatch(cancelRecording({ history: props.history }) as any);
      }
    })
  )(RecordingControls)
);
