// import { isWebBuild } from 'app/config';
import { StartRecordingProps } from 'app/models/StoreModel';

/**
 * on web builds we mock the screenshot library
 */
// const screenshot = !isWebBuild
//   ? // needs to by window.require to avoid conflict with browserify require
//     // https://github.com/electron/electron/issues/7300
//     (<any>window).require('screenshot-desktop')
//   : (options: any, cb: Function) => cb(Promise.reject('is web build'));

// class Screenshot {
//   static stream: any = null;
//   static audioStream: any = null;
//   static recorder: any = null;
//   static recordedChunks: any[] = [];

//   static recorderOnDataAvailable = (event: any) => {
//     Screenshot.recordedChunks.push(event.data);
//   };

//   static waitForRecorderOnDataAvailable = () => {
//     let maxSecondsToWait = 120;
//     return new Promise((res, rej) => {
//       const interval = setInterval(() => {
//         const timeRanOut = maxSecondsToWait-- === 0;
//         if (timeRanOut) {
//           clearInterval(interval);
//           return rej();
//         }
//         if (Screenshot.recordedChunks.length) {
//           clearInterval(interval);
//           res();
//         }
//       }, 1000);
//     });
//   };

//   static clear = () => {
//     Screenshot.recordedChunks = [];
//     Screenshot.stream = null;
//     Screenshot.audioStream = null;
//     Screenshot.recorder = null;
//   };

//   static getUserMediaError = () => {};

//   static videoConstraints = {
//     // width: { min: 320, ideal: 320, max: 640 },
//     // height: { min: 240, ideal: 240, max: 480 },
//     frameRate: 30 // { max: 60, ideal: 30, min: 10 },
//   };

//   static start = async ({ sourceId }: StartRecordingProps): Promise<any> => {
//     Screenshot.clear();
//     // get video stream
//     Screenshot.stream = await (<any>navigator).mediaDevices.getUserMedia({
//       audio: false, // there's an electron/chrome bug; when video with sound is needed the sound needs to be captured in a separate stream :(
//       video: sourceId && {
//         mandatory: {
//           chromeMediaSource: 'desktop',
//           chromeMediaSourceId: sourceId,
//           maxWidth: 1280,
//           maxHeight: 720
//           // maxFrameRate: 90
//           // minWidth: 1280,
//           // minHeight: 720
//           // maxWidth: window.screen.width, maxHeight: window.screen.height
//         }
//       }
//     });

//     Screenshot.stream.onended = () => console.log('Media stream ended.');
//     let options = {};
//     if (typeof (<any>window).MediaRecorder.isTypeSupported == 'function') {
//       /*
// 				MediaRecorder.isTypeSupported is a function announced in https://developers.google.com/web/updates/2016/01/mediarecorder and later introduced in the MediaRecorder API spec http://www.w3.org/TR/mediastream-recording/
// 			*/
//       if ((<any>window).MediaRecorder.isTypeSupported('video/webm;codecs=vp9')) {
//         options = { mimeType: 'video/webm;codecs=vp9' };
//       } else if ((<any>window).MediaRecorder.isTypeSupported('video/webm;codecs=h264')) {
//         options = { mimeType: 'video/webm;codecs=h264' };
//       } else if ((<any>window).MediaRecorder.isTypeSupported('video/webm;codecs=vp8')) {
//         options = { mimeType: 'video/webm;codecs=vp8' };
//       }
//     }
//     Screenshot.recorder = new (<any>window).MediaRecorder(Screenshot.stream, options);

//     Screenshot.recorder.ondataavailable = Screenshot.recorderOnDataAvailable;
//     Screenshot.recorder.onstop = () => console.log('recorderOnStop fired');
//     Screenshot.recorder.start();
//     await new Promise((res) => setTimeout(res, 1000));
//     const imageBlob = await Screenshot.stop();

//     return imageBlob;
//   };

//   static stop = async () => {
//     try {
//       var video = document.createElement('video');

//       video.style.cssText = 'position:absolute;top:-10000px;left:-10000px;';

//       const videoHeight = 900;
//       const videoWidth = 1200;

//       return new Promise((res, rej) => {
//         // Event connected to stream
//         video.onloadedmetadata = async () => {
//           // Set video ORIGINAL height (screenshot)
//           video.style.height = videoHeight + 'px'; // videoHeight
//           video.style.width = videoWidth + 'px'; // videoWidth

//           video.play();
//           var canvas = document.createElement('canvas');

//           // Create canvas
//           canvas.width = videoWidth;
//           canvas.height = videoHeight;
//           const ctx = canvas.getContext('2d');
//           if (!ctx) {
//             throw Error('unable to render canvas');
//           }
//           // Draw video on canvas
//           ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

//           let imageBlob = await new Promise((canvasRes) =>
//             canvas.toBlob((blob) => canvasRes(blob || {}))
//           );

//           res(imageBlob);
//           // Remove hidden video tag

//           Screenshot.recorder.stop();
//           // now carry o
//           video.remove();
//         };

//         video.controls = true;
//         video.muted = false;
//         video.autoplay = true;
//         video.srcObject = Screenshot.stream;
//       });
//     } catch (e) {
//       return Promise.reject(e);
//     }
//   };
// }

// function b64toBlob(dataURI: string) {
//   var byteString = atob(dataURI);
//   var ab = new ArrayBuffer(byteString.length);
//   var ia = new Uint8Array(ab);

//   for (var i = 0; i < byteString.length; i++) {
//     ia[i] = byteString.charCodeAt(i);
//   }
//   return new Blob([ab], { type: 'image/png' });
// }


async function takeSnapshot() {
  const stream = await(navigator.mediaDevices as any).getDisplayMedia({
    video: true,
    audio: false
  });
  const video = document.createElement('video');
  const canvas = document.createElement('canvas');
  // document.body.appendChild(video);
  // document.body.appendChild(canvas);

  video.srcObject = stream;
  video.play();

  canvas.width = video.width = window.screen.width;
  canvas.height = video.height = window.screen.height;

  return new Promise((res, rej) => {
    setTimeout(() => {
      try {
        (canvas.getContext('2d') as any).drawImage(
          video,
          0,
          0,
          window.screen.width,
          window.screen.height
        );

        // stop the stream before finishing
        stream.getTracks().forEach((track: any) => track.stop());

        res(canvas.toDataURL('image/png'));
      } catch (e) {
        rej(e);
      }
    }, 1000);
  });
}

export const takeScreenshot = async ({ sourceId }: StartRecordingProps) => {
  try {
        return {
          snapshot: await takeSnapshot()
        };
      } catch (e) {
    return Promise.reject(e);
  }
};

/**
 * STATE 1:
 *   plus icon; mouse up etc.
 * STATE 2:
 *   plus icon; mouse click down
 * STATE 3:
 *   plus icon; mouse click down; moouse dragg XY etc.
 * STATE 4:
 *   plus icon; mouse up
 *   calculate region coordinates; capture the section on the screen in the given region
 *   produse image blob
 *   upload and exit
 */
