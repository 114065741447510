import { isWebBuild } from "app/config";

/**
 * on web builds we mock the desktopCapturer
 */
const screen = !isWebBuild
  ? // needs to by window.require to avoid conflict with browserify require
    // https://github.com/electron/electron/issues/7300
    (<any>window).require('electron').remote.screen
  : {
      getSources: (options: any, cb: Function) => cb(Promise.reject('is web build'))
    };

export const getNumberOfDisplays = async (): Promise<any> => {
  let displays = screen.getAllDisplays();
  return Promise.resolve(displays.length);
};
