import { TextProps } from 'app/components/atoms/Text';
import { isWebBuild } from 'app/config';
import styled from 'app/utils/theme';
import React from 'react';

export interface ImageProps extends TextProps {
  opacity?: number | null;
}

const logoLight = require('../../../../assets/all logo/logo_text.png');
const logoDark = require('../../../../assets/all logo/logo_text_dark.png');

const Logo = styled.div<ImageProps>`
  position: relative;
  min-height: 100px;
  width: 200px;
  ${(props) => props.xl && `width: 150px`};
  background-image: url(${(props) => (props.theme.isDarkMode ? logoDark : logoLight)});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  opacity: ${(props) => props.opacity};
`;

export default  (...params: any) => {
  if (isWebBuild) {
    return <a href="/"><Logo {...params}/></a>;
  }

  return <Logo {...params} />;
};
