import * as React from 'react';
import { Loader } from 'semantic-ui-react';

import Text from 'app/components/atoms/Text';
import IconButton from 'app/components/molecules/IconButton';

import styled from 'app/utils/theme';

type ProgressNotificationProps = {
  progress: number;
  title: string;
  onCancel?: Function;
  red?: boolean;
  minimized?: boolean;
};

const ProgressNotification = ({
  progress,
  title,
  onCancel = () => {},
  red,
  minimized
}: ProgressNotificationProps) => (
  <ProgressNotification.Wrapper>
    <ProgressNotification.Background />
    <ProgressNotification.InnerWrapper>
      <div style={{ display: 'inline-block', position: 'relative' }}>
        <Loader active />
      </div>
      <Text active maxSize={50}>
        {title}
      </Text>
      <Text active>{progress}%</Text>
      <ProgressNotification.Cancel size="lg" icon={'times-circle'} onClick={onCancel} />
    </ProgressNotification.InnerWrapper>
  </ProgressNotification.Wrapper>
);

ProgressNotification.Wrapper = styled.div`
  position: relative;
  width: 400px;
  background-color: ${(props) => props.theme.modal.backgroundColor};
  opacity: 0.8;
  margin-top: 15px;
`;

ProgressNotification.Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.modal.backgroundColor};
  opacity: 0.6;
`;
ProgressNotification.InnerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 90%;
  height: 90%;
  left: 5%;
  top: 5%;
  padding: 15px 0 15px 10px;
`;

ProgressNotification.Cancel = styled(IconButton)``;

export default ProgressNotification;
