import { FileAccessType, StorageTypes } from 'app/config';
import {
  MediaUploadCreateType,
  SignedUrlsType,
  StorePayload,
  StoreResponse
} from 'app/models/StoreModel';
import isString from 'lodash/isString';
import MasterStorage from './MasterStorage';

require('../MediaUploader.js');
const MediaUploader = (window as any).UploaderForGoogleDrive;

const parseErrorResponse = (response: object | string) => {
  if (!isString(response)) {
    return response;
  }

  try {
    const objResponse = JSON.parse(response as any);

    return {
      message: objResponse.error.errors
        .map(({ message, reason }: any) => `${message} (${reason})`)
        .join('\n\r')
    };
  } catch (e) {
    return {
      message: response
    };
  }
};

export default class BlazeStorage implements MasterStorage {
  signedUrls: SignedUrlsType;
  StorageType: { id: number; name: string } = StorageTypes.BACKBLAZE;

  constructor(signedUrls: SignedUrlsType) {
    this.signedUrls = signedUrls;
  }

  create = async ({
    file: { name, mimetype, blob },
    signedUrl,
    progressCb,
    headers = {}
  }: MediaUploadCreateType): Promise<void> => {
    return new Promise(async (res, rej) => {
      try {
        const uploader = new MediaUploader({
          storageType: this.StorageType.name,
          url: signedUrl,
          file: blob,
          metadata: { name, mimetype, parents: [] },
          params: {
            fields: '*'
          },
          headers,
          onError: (response: any) => {
            const error = parseErrorResponse(response);
            rej(error);
          },
          onComplete: (response: any) => {
            res();
          },
          onProgress: (event: any) => {
            const progress = Math.round((event.loaded / event.total) * 100);
            progressCb && progressCb(progress);
          }
        });

        uploader.upload();
      } catch (e) {
        rej(e);
      }
    });
  };

  async store({ file, thumbnail, gif }: StorePayload, progressCb: Function): Promise<StoreResponse> {
    const fileUrl = new URL(this.signedUrls.file);
    const thumbnailUrl = new URL(this.signedUrls.thumbnail);
    const gifUrl = new URL(this.signedUrls.gif);


    await this.create({ file, signedUrl: this.signedUrls.file, progressCb });

    if (thumbnail) {
      await this.create({
        file: thumbnail,
        signedUrl: this.signedUrls.thumbnail,
      });
    }

    if (gif) {
      await this.create({
        file: gif,
        signedUrl: this.signedUrls.gif,
      });
    }

    return {
      url: `${fileUrl.origin}${fileUrl.pathname}`,
      sharedUrl: `${fileUrl.origin}${fileUrl.pathname}`,
      downloadUrl: `${fileUrl.origin}${fileUrl.pathname}`,
      meta: {
        fileUrl,
        thumbnailUrl,
        gifUrl
      },
      thumbnail: thumbnail ? `${thumbnailUrl.origin}${thumbnailUrl.pathname}` : '',
      gif: gif ? `${gifUrl.origin}${gifUrl.pathname}` : '',
      storage: this.StorageType,
      accessType: FileAccessType.PRIVATE
    };
  }
}
