import * as React from 'react';
import { connect } from 'react-redux';

import styled from 'app/utils/theme';
import Text from 'app/components/atoms/Text';
import Logo from 'app/components/molecules/Logo';

// import { StoreModel } from 'app/models';
import { sendWindowEvent } from 'app/redux/actions';
import { getUserProfile } from 'app/redux/reducers/googleSignin';
import { TERMS, POLICY, isWebBuild } from 'app/config/index';

const { remote, shell } =
  !isWebBuild
    ? window['require']('electron')
    : { remote: () => {}, shell: () => {} };

interface AboutProps {
  sendWindowEvent?: Function;
  name?: string;
  email?: string;
}

class About extends React.Component<AboutProps> {
  componentDidMount() {}
  componentWillUnmount() {}

  openLink = (event: any, to: string) => {
    event.preventDefault();
    shell.openExternal(to);
  };
  render() {
    return <AboutWrapper>
        <Logo />
        <Text pBottom={10}>Version: {remote.app.getVersion()}</Text>
        <Text pBottom={10}>Current user: {this.props.name} ({this.props.email})</Text>
        <Text pBottom={10}>
          Copyright @{new Date().getFullYear()} ScreenTape, All rights reserved.
        </Text>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Text underline onClick={(e) => this.openLink(e, TERMS)} pRight={10}>
            Terms and Conditions
          </Text>
          <Text underline onClick={(e) => this.openLink(e, POLICY)}>
            Privacy Policy
          </Text>
        </div>
      </AboutWrapper>;
  }
}

const AboutWrapper = styled.div`
  width: 400px;
  padding-left: 40px;
  padding-right: 10px;
  padding-top: 30px;
`;

export default connect(
  (state) => ({
    email: getUserProfile(state).email,
    name: getUserProfile(state).name,
  }),
  (dispatch, props) => ({
    sendWindowEvent: (params: any) => dispatch(sendWindowEvent(params))
  })
)(About);
