export default {
  CATEGORIES: {
    APPLICATION: 'APPLICATION',
    RECORDING: 'RECORDING',
    SEARCH: 'SEARCH',
    ORDER: 'ORDER',
    LIST_VIEW: 'LIST_VIEW',
    PLAYBACK: 'PLAYBACK'
  },
  ACTIONS: {
    OPEN: 'OPEN',
    LOGIN: 'LOGIN',
    LOGOUT: 'LOGOUT',
    CLOSE: 'CLOSE',
    QUIT: 'QUIT',
    CHECK_FOR_UPDATES: 'CHECK_FOR_UPDATES'
  }
};
