import React from 'react';
import { connect } from 'react-redux';
import Page from 'app/components/pages/Page';
import Text from 'app/components/atoms/Text';
import EmptyView from 'app/components/molecules/EmptyView';
import { getIsOffline } from 'app/redux/reducers/googleSignin';
import Logo from '../molecules/Logo';
import Settings from '../organisms/Settings';
import styled from 'app/utils/theme';

interface OfflineProps {
  isOffline: Boolean;
}

const TopBarWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 60px 0 60px;
  align-items: center;
`;

@connect((state) => ({
  isOffline: getIsOffline(state)
}))
export class NoFileAccessPage extends Page<OfflineProps> {
  render() {
    return (
      <EmptyView>
        <TopBarWrapper>
          <Logo xxl opacity={1} />
          <Settings />
        </TopBarWrapper>
        <Text xxl active pTop={200} pBottom={10}>
          Ooops! &nbsp;&nbsp;
        </Text>
        <Text l veryLight pasive pBottom={7}>
          Seems you don't have access to this recording.
        </Text>
        <Text>&nbsp;</Text>
      </EmptyView>
    );
  }
}
