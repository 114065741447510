import * as React from 'react';
import { Progress as SemanticProgress } from 'semantic-ui-react';
import styled from 'app/utils/theme';

// import styled from 'app/utils/theme';
import Logo from 'app/components/molecules/Logo';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 300px;

  .ui.progress.attached {
    height: 5px !important;
    border-radius: 10px !important;
  }
  .ui.progress {
    width: 100%;
    .bar {
      background-color: ${(props) => props.theme.text.colors.orange};
      height: 5px !important;
      border-radius: 10px !important;
      .progress {
        display: none;
      }
    }
  }
`;

export class Progress extends React.Component<{speed?: number, active?: boolean}> {
  static Wrapper: any;
  state = { progress: 0, interval: null };
  interval: any;
  componentDidMount() {
    this.interval = setInterval(this.incrementProgress, this.props.speed || 15);
  }

  incrementProgress = () => {
    this.setState({ progress: this.state.progress + 1 });
  };

  componentWillUnmount() {
    setTimeout(() => {
      clearInterval(this.interval);
    }, 1000);
  }

  render() {
    const { progress } = this.state;

    return <Progress.Wrapper active={this.props.active}>
        <SemanticProgress progress="percent" total="100" value={progress} attached="bottom" />
      </Progress.Wrapper>;
  }
}

Progress.Wrapper = styled.div<{ active?: boolean }>`
  width: 100%;
  .ui.progress.attached {
    height: 5px !important;
    border-radius: 10px !important;
  }
  .ui.progress {
    width: 100%;
    .bar {
      background-color: ${(props) =>
        props.active ? props.theme.text.colors.orange : props.theme.modal.backgroundColor};
      height: 5px !important;
      border-radius: 10px !important;
      .progress {
        display: none;
      }
    }
  }
`;

const ProgressWithLogo = () => (
  <Wrapper>
    <Logo />
    <Progress active />
  </Wrapper>
);

export default ProgressWithLogo;
