import 'semantic-ui-css/semantic.min.css';
import 'rc-tooltip/assets/bootstrap.css';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';

import { createGlobalStyle } from 'app/utils/theme';

import montserratFont from './montserratFont';
import singPainterFont from './signPainterFont';

export const GlobalStyle: any = createGlobalStyle<any>`
  html,
  body,
  #root {
    width: 100%;
    height: 100%;
    background: ${(props) => props.theme.notification.backgroundColor} !important;
    margin: 0;
    //${(props) => props.isRecordingNow && `overflow: hidden;`}
    ${montserratFont}
    ${singPainterFont}
  }
 .rc-tooltip-inner {
   background-color: ${(props) => props.theme.tooltip.backgroundColor} !important;
  }
 .rc-tooltip-arrow {
   color: ${(props) => props.theme.tooltip.backgroundColor} !important;
  }


  ::-webkit-scrollbar {
    width: 0px; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }
  /* Optional: show position indicator in red */
  ::-webkit-scrollbar-thumb {
    background: #ff0000;
  }
`;
