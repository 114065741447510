import { AnyAction } from 'redux';
import { StoreModel, UploadingStatus } from 'app/models/StoreModel';
import { ActionTypes } from 'app/redux/actionTypes/index';
import {
  VIDEO_MIME,
  AUDIO_MIME,
  IMAGE_MIME,
  FOLDER_MIME,
  SORT_TYPE,
  DRIVER_FOLDER_NAME,
  SELECTED_RECORDING_OPTIONS
} from 'app/config';

import moment from 'moment';
import get from 'lodash/get';
import set from 'lodash/set';
export const STATE_KEY = 'recordings';
import _ from 'lodash';

import { STATE_KEY as UPLOAD_STATE_KEY } from './uploading';
import { formatDuration, formatSize } from './formatSeparator';

export type ListResponse = {
  etag: string;
  incompleteSearch: boolean;
  items: Array<any>;
  kind: string;
  selfLink: string;
};

const initialState: StoreModel.RecordingsState = {
  isLoading: false,
  page: 0,
  pageCount: 1,
  total: 0,
  count: 9,
  data: [],
  sharedWithMe: [],
  error: null,
  rootFolder: null,
  playRecording: null,
  searchText: null,
  sortOption: SORT_TYPE.BY_DATE_DESC,
  shareRecordFileId: null,
  isLightMode: false,
  isFavoritesFilter: false,
  selectedVisibleRecordingOption: SELECTED_RECORDING_OPTIONS[0].id
};

export const getAccessToken = (action: any) => {
  return get(action, 'data.api_token') || get(action, 'meta.previousAction.data.api_token');
};

export const reducer = (state: StoreModel.RecordingsState = initialState, action: AnyAction) => {
  switch (action.type) {
    case `${ActionTypes.GOOGLE_SIGN_OUT}`:
      return {
        ...state,
        page: 1,
        data: [],
        count: 0
      };
    case ActionTypes.GET_RECORDINGS:
      return {
        ...state,
        page: action.payload.data.page,
        isLightMode: state.isLightMode,
        isFavoritesFilter: state.isFavoritesFilter,
        isLoading: true,
        rootFolder: state.rootFolder
      };
    case `${ActionTypes.GET_RECORDINGS}_SUCCESS`: {
      const { count, data, page, pageCount, total } = get(action, 'payload.data', []);
      const isFirstPage = page === 1;
      const hasOnlySharedRecordings = !!state.sharedWithMe.length && !data.length;

      return {
        ...state,
        count,
        page,
        pageCount,
        total,
        isLoading: false,
        data: [
          ...(isFirstPage ? [] : state.data), // on first page reset everything
          ...data.filter((f: any) => [VIDEO_MIME, AUDIO_MIME, IMAGE_MIME].includes(f.mimeType))
        ],
        rootFolder: data.find(
          (f: any) => f.mimeType === FOLDER_MIME && f.name === DRIVER_FOLDER_NAME
        ),
        selectedVisibleRecordingOption: hasOnlySharedRecordings
          ? SELECTED_RECORDING_OPTIONS[1].id
          : SELECTED_RECORDING_OPTIONS[0].id
      };
    }
    case `${ActionTypes.GET_RECORDINGS}_FAIL`:
      return { ...state, isLoading: false, error: action.error };

    case `${ActionTypes.UPLOAD_RECORDING}_SUCCESS`: {
      const recording = action.payload.data;

      return {
        ...state,
        data: [recording, ...state.data]
      };
    }

    case `${ActionTypes.UPDATE_RECORDING_NAME}_FAIL`: {
      const updatedRecording = state.data.find(
        (r) => r.id === get(action, 'meta.previousAction.payload.data.fileId')
      ) as any;

      updatedRecording.isUpdating = false;

      return { ...state, data: [...state.data] };
    }
    case ActionTypes.UPDATE_RECORDING_NAME: {
      const updatedRecording = state.data.find((r) => r.id === action.payload.data.fileId) as any;

      updatedRecording.isUpdating = true;

      return { ...state, data: [...state.data] };
    }
    case `${ActionTypes.UPDATE_RECORDING_NAME}_SUCCESS`: {
      const updatedRecording = state.data.find(
        (r) => r.id === get(action, 'meta.previousAction.payload.data.fileId')
      ) as any;

      set(updatedRecording, 'name', action.payload.data.name);

      updatedRecording.isUpdating = false;

      return { ...state, data: [...state.data] };
    }

    case `${ActionTypes.UPDATE_RECORDING_ACCESS_TYPE}_SUCCESS`: {
      const updatedRecording = state.data.find((r) => r.id === action.payload.data.id) as any;

      set(updatedRecording, 'accessType', action.payload.data.accessType);

      return { ...state, data: [...state.data] };
    }

    case ActionTypes.REMOVE_RECORDING: {
      return {
        ...state,
        data: state.data.filter((f) => f.id !== get(action, 'payload.data.fileId'))
      };
    }
    // case `${ActionTypes.REMOVE_RECORDING}_FAIL`: {
    //   const deletingRecording = state.data.find(
    //     (f) => f.id === get(action, 'meta.previousAction.payload.data.fileId')
    //   ) as any;

    //   set(deletingRecording, 'deleting', {
    //     id: uniqid(),
    //     title: deletingRecording.title,
    //     status: UploadingStatus.FAILED,
    //     error: action.error
    //   });

    //   deletingRecording.isUpdating = false;

    //   return { ...state, data: [...state.data] };
    // }
    case `${ActionTypes.CREATE_DRIVE_FOLDER}_SUCCESS`:
      return { ...state, rootFolder: action.payload.data };
    case `${ActionTypes.REMOVE_RECORDING}_SUCCESS`:
      return {
        ...state,
        data: state.data.filter(
          (f) => f.id !== get(action, 'meta.previousAction.payload.data.fileId')
        )
      };
    case `${ActionTypes.UNSUBSCRIBE_RECORDING}_SUCCESS`:
      return {
        ...state,
        sharedWithMe: state.sharedWithMe.filter(
          (f) => f.id !== get(action, 'meta.previousAction.payload.data.fileId')
        )
      };
    case ActionTypes.PLAY_RECORDING: {
      return { ...state, playRecording: action.payload.data };
    }
    case ActionTypes.SEARCH_RECORDINGS: {
      return { ...state, searchText: action.payload.data.text };
    }
    case ActionTypes.SET_RECORDINGS_SORT_OPTION: {
      return { ...state, sortOption: action.payload.data.sortOption };
    }
    case `${ActionTypes.SHARE_FILE}`: {
      const { fileId } = get(action, 'payload.data');
      const updatedRecording = state.data.find((r) => r.id === fileId) as any;

      updatedRecording.isUpdating = true;

      return { ...state, data: [...state.data] };
    }
    case `${ActionTypes.SHARE_FILE}_FAIL`: {
      const { fileId } = get(action, 'meta.previousAction.payload.data');
      const updatedRecording = state.data.find((r) => r.id === fileId) as any;

      updatedRecording.isUpdating = false;

      return { ...state, data: [...state.data] };
    }
    case `${ActionTypes.SHARE_FILE}_SUCCESS`: {
      const { fileId, sharedWith } = get(action, 'meta.previousAction.payload.data');
      const updatedRecording = state.data.find((r) => r.id === fileId) as any;

      set(updatedRecording, 'sharedWith', sharedWith);
      updatedRecording.isUpdating = false;

      return { ...state, data: [...state.data] };
    }

    case ActionTypes.TOGGLE_SHARED_RECORD_MODAL:
      return { ...state, shareRecordFileId: action.payload.data.fileId };
    case ActionTypes.UPDATE_RECORDING_PROPERTIES: {
      const updatedRecording = state.data.find((r) => r.id === action.payload.data.fileId) as any;

      updatedRecording.isUpdating = true;

      return { ...state, data: [...state.data] };
    }
    case `${ActionTypes.UPDATE_RECORDING_PROPERTIES}_SUCCESS`: {
      const updatedRecording = state.data.find((r) => r.id === action.payload.data.id) as any;

      updatedRecording.isUpdating = false;
      Object.entries(get(action, 'meta.previousAction.payload.request.data', {}))
        .filter(([key]) => key !== 'meta')
        .forEach(([key, value]) => {
          set(updatedRecording, key, value);
        });

      return { ...state, data: [...state.data] };
    }

    case ActionTypes.TOGGLE_LIGHT_MODE:
      localStorage.setItem('isLightMode', (!state.isLightMode).toString());
      return { ...state, isLightMode: !state.isLightMode };
    case ActionTypes.TOGGLE_FAVORITES_FILTER:
      return { ...state, isFavoritesFilter: !state.isFavoritesFilter };

    case ActionTypes.GET_SHARED_FILE:
      return { ...state, isLoading: true };
    case `${ActionTypes.GET_SHARED_FILE}_SUCCESS`:
      return {
        ...state,
        data: [
          ...state.data.filter((item) => item.id !== action.payload.data.id),
          action.payload.data
        ],
        isLoading: false
      };
    case `${ActionTypes.GET_SHARED_FILES}_SUCCESS`: {
      const hasOnlySharedRecordings = !!action.payload.data.length && !state.data.length;

      return {
        ...state,
        sharedWithMe: [...action.payload.data.data],
        // isLoading: false,
        selectedVisibleRecordingOption: hasOnlySharedRecordings
          ? SELECTED_RECORDING_OPTIONS[1].id
          : SELECTED_RECORDING_OPTIONS[0].id
      };
    }
    case `${ActionTypes.GET_SHARED_FILE}_FAIL`:
      return { ...state, isLoading: false };
    case ActionTypes.SELECT_VISIBLE_RECORDING_OPTION:
      return { ...state, selectedVisibleRecordingOption: action.payload.data.option };
    default:
      return state;
  }
};

export const hasRecordings = (state: StoreModel) => {
  return !!state[STATE_KEY].data.length || !!state[STATE_KEY].sharedWithMe.length;
};

export const getRecordings = (state: StoreModel) => {
  const searchRegExp = new RegExp(state[STATE_KEY].searchText || '', 'gi');
  const isFavoritesFilter = state[STATE_KEY].isFavoritesFilter;
  const recordings = state[STATE_KEY].data
    .filter((r) => !r.uploading || r.uploading.status === UploadingStatus.COMPLETED)
    .filter((r) => searchRegExp.test(r.name));

  const uploadAsRecordings = getUploadsAsRecordings(state);

  const orderedRecordings: Array<StoreModel.RecordingModel> = (() => {
      if (state[STATE_KEY].sortOption === SORT_TYPE.BY_NAME_ASC) {
        return recordings.sort((r1, r2) => r1.name.localeCompare(r2.name));
      }

      if (state[STATE_KEY].sortOption === SORT_TYPE.BY_NAME_DESC) {
        return recordings.sort((r2, r1) => r1.name.localeCompare(r2.name));
      }

      if (state[STATE_KEY].sortOption === SORT_TYPE.BY_DURATION_ASC) {
        return recordings.sort((r1, r2) =>
          r1.meta.momentDuration > r2.meta.momentDuration ? 1 : -1
        );
      }

      if (state[STATE_KEY].sortOption === SORT_TYPE.BY_DURATION_DESC) {
        return recordings.sort((r2, r1) =>
          r1.meta.momentDuration > r2.meta.momentDuration ? 1 : -1
        );
      }

      if (state[STATE_KEY].sortOption === SORT_TYPE.BY_DATE_ASC) {
        return recordings.sort((r1, r2) => (r1.meta.createdAt > r2.meta.createdAt ? 1 : -1));
      }

      if (state[STATE_KEY].sortOption === SORT_TYPE.BY_DATE_DESC) {
        return recordings.sort((r2, r1) => (r1.meta.createdAt > r2.meta.createdAt ? 1 : -1));
      }

  return [];
  })() || [];

  return [...uploadAsRecordings, ...orderedRecordings].filter((r) => !isFavoritesFilter || get(r, 'isFavorite', false));
};

export const getUploadsAsRecordings = (state: StoreModel) => {
  const uploads = state[UPLOAD_STATE_KEY];

  return uploads.map((upload) => ({
    ...upload,
    createdAt: moment(upload.createdAt).format('MMMM Do YYYY'),
    notes: [],
    comments: [],
    sharedUrl: null,
    meta: {
      createdAt: upload.createdAt,
      formatedDuration: formatDuration(upload.duration),
      formatedSize: formatSize(upload.size)
    },
    downloadUrl: null,
    ownerEmail: null,
    uploading: upload
    //
    // thumbnail: ???,
    // url: ???,
    // sharedUrl: `${clientRootUrl}/details/${apiFile.id}`,
    // size: ???,
    // downloadUrl: ???,
  }));
};

export const getRecordingById = (state: StoreModel, id: string) => {
  const searchRegExp = new RegExp(state[STATE_KEY].searchText || '', 'gi');
  const recordings = state[STATE_KEY].data
    .filter((r) => !r.uploading || r.uploading.status === UploadingStatus.COMPLETED)
    .filter((r) => searchRegExp.test(r.name));

  return recordings.find((r) => r.id == id);
};

export const getMyRecordings = (state: StoreModel) => {
  return getRecordings(state);
};

export const getSharedRecordings = (state: StoreModel) => {
  const searchRegExp = new RegExp(state[STATE_KEY].searchText || '', 'gi');
  const recordings = state[STATE_KEY].sharedWithMe.filter((r) => searchRegExp.test(r.name));
  return recordings;
};

export const isLoadingRecordings = (state: StoreModel) => state[STATE_KEY].isLoading;

export const getParentFolderId = (state: StoreModel) => {
  const folder = state[STATE_KEY].rootFolder;

  return folder ? folder.id : null;
};

export const getPlayRecording = (state: StoreModel) => state[STATE_KEY].playRecording;

export const getDetailsPageRecording = (state: StoreModel) => {
  const recordingId = window.location.pathname.split('/').pop();
  const recordings = state[STATE_KEY].data;

  return recordings.find((recording: StoreModel.RecordingModel) => recording.id == recordingId);
};

export const getDurationInSeconds = (state: StoreModel) => {
  return state[STATE_KEY].playRecording?.duration;
};

export const getRemoving = (state: StoreModel) => {
  return state[STATE_KEY].data
    .filter(
      (r) =>
        r.deleting &&
        (r.deleting.status === UploadingStatus.IN_PROGRESS ||
          r.deleting.status === UploadingStatus.PENDING)
    )
    .map((r) => r.uploading);
};

export const getSortOption = (state: StoreModel) => state[STATE_KEY].sortOption;

export const getSharedRecording = (state: StoreModel) => {
  const fileId = state[STATE_KEY].shareRecordFileId;
  if (!fileId) {
    return [];
  }

  const file = state[STATE_KEY].data.find((r) => r.id === fileId);

  return file;
};

export const getSharedEmails = (state: StoreModel) => {
  const fileId = state[STATE_KEY].shareRecordFileId;
  if (!fileId) {
    return [];
  }

  const file = state[STATE_KEY].data.find((r) => r.id === fileId);
  if (!file) {
    return [];
  }

  return file.sharedWith.map(({ email }) => email);
};

export const getAllEmails = (state: StoreModel) => {
  const files = state[STATE_KEY].data;

  const emails = _(files)
    .reduce((result: string[], file) => {
      return [...result, ..._.get(file, 'sharedWith').map((p: any) => p.email)];
    }, [])
    .filter((e: any) => !!e);

  const uniqEmails = _.uniq(emails);

  return uniqEmails;
};

export const isShareRecordModalVisible = (state: StoreModel) =>
  !!state[STATE_KEY].shareRecordFileId;

export const getSharedFileId = (state: StoreModel) => state[STATE_KEY].shareRecordFileId;

export const getRecordingsField = (field: string, state: StoreModel) => {
  return get(state, `[${STATE_KEY}].${field}`);
};

export const isScreenshot = (state: StoreModel) =>
  get(state, `[${STATE_KEY}].playRecording.mimeType`) === IMAGE_MIME;

export const getRecordingStateData = (state: StoreModel) => state[STATE_KEY];
