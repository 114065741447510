export type AsyncActionSteps = {
  STARTED: string;
  COMPLETED: string;
  FAILED: string;
  PROGRESS: string;
};

const buildAsyncTypes = (actionType: string): AsyncActionSteps => ({
  STARTED: `${actionType}_STARTED`,
  COMPLETED: `${actionType}_COMPLETED`,
  FAILED: `${actionType}_FAIL`,
  PROGRESS: `${actionType}_PROGRESS`
});

export namespace ActionTypes {
  export const SELECT_RECORD_TYPE = 'SELECT_RECORD_TYPE';
  export const START_RECORD = buildAsyncTypes('START_RECORD');
  export const PAUSE_RECORD = buildAsyncTypes('PAUSE_RECORD');
  export const STOP_RECORD = buildAsyncTypes('STOP_RECORD');
  export const CANCEL_RECORD = buildAsyncTypes('CANCEL_RECORD');
  export const RESUME_RECORDING = buildAsyncTypes('RESUME_RECORDING');
  export const WINDOW_ACTION = buildAsyncTypes('WINDOW_ACTION');
  export const GET_RECORDINGS = 'GET_RECORDINGS';
  export const REMOVE_RECORDING = 'REMOVE_RECORDING';
  export const CREATE_RECORDING = 'CREATE_RECORDING';
  export const CANCEL_CREATE_RECORDING = 'CANCEL_CREATE_RECORDING';
  export const DOWNLOAD_RECORDING = 'DOWNLOAD_RECORDING';
  export const SHARE_RECORDING = 'SHARE_RECORDING';
  export const NAVBAR_SELECT = 'NAVBAR_SELECT';
  export const SORT_RECORDINGS = 'SORT_RECORDINGS';
  export const SEARCH_RECORDINGS = 'SEARCH_RECORDINGS';
  export const UPDATE_RECORDING_NAME = 'UPDATE_RECORDING_NAME';
  export const UPDATE_RECORDING_NOTES = 'UPDATE_RECORDING_NOTES';
  export const ADD_RECORDING_COMMENT = 'ADD_RECORDING_COMMENT';
  export const DELETE_RECORDING_COMMENT = 'DELETE_RECORDING_COMMENT';
  export const PLAY_RECORDING = 'PLAY_RECORDING';
  export const CLOSE_PLAY_RECORDING = 'CLOSE_PLAY_RECORDING';
  export const GOOGLE_SIGN_IN = 'GOOGLE_SIGN_IN';
  export const GOOGLE_SIGN_OUT = 'GOOGLE_SIGN_OUT';
  export const GOOGLE_EXCHANGE_CODE = 'GOOGLE_EXCHANGE_CODE';
  export const GET_RECORDING_SOURCES = buildAsyncTypes('GET_RECORDING_SOURCES');
  export const FILTER_RECORDING_SOURCES = 'FILTER_RECORDING_SOURCES';
  export const SET_RECORDING_SETTINGS = 'SET_RECORDING_SETTINGS';
  export const UPDATE_ACTIVE_RECORDING = 'UPDATE_ACTIVE_RECORDING';
  export const TOGGLE_TIMER_MODAL = 'TOGGLE_TIMER_MODAL';
  export const INCREMENT_RECORDING_TIME = 'INCREMENT_RECORDING_TIME';
  export const UPLOAD_RECORDING = 'UPLOAD_RECORDING';
  export const CREATE_DRIVE_FOLDER = 'CREATE_DRIVE_FOLDER';
  export const MINIMIZE_UPLOAD = 'MINIMIZE_UPLOAD';
  export const CANCEL_UPLOAD = 'CANCEL_UPLOAD';
  export const STOP_UPLOAD = 'STOP_UPLOAD';
  export const RETRY_UPLOAD = 'RETRY_UPLOAD';
  export const REFRESH_ACCESS_TOKEN = 'REFRESH_ACCESS_TOKEN';
  export const CHECK_FOR_UPDATES = buildAsyncTypes('CHECK_FOR_UPDATES');
  export const GET_LOCAL_SERVER_PORT = buildAsyncTypes('GET_LOCAL_SERVER_PORT');
  export const SET_RECORDINGS_SORT_OPTION = 'SET_RECORDINGS_SORT_OPTION';
  export const TOGGLE_OFFLINE_MODE = 'TOGGLE_OFFLINE_MODE';
  export const SHARE_FILE = 'SHARE_FILE';
  export const UNSHARE_FILE = 'UNSHARE_FILE';
  export const TOGGLE_SHARED_RECORD_MODAL = 'TOGGLE_SHARED_RECORD_MODAL';
  export const GET_USER_PROFILE = 'GET_USER_PROFILE';
  export const UPDATE_RECORDING_PROPERTIES = 'UPDATE_RECORDING_PROPERTIES';
  export const TOGGLE_LIGHT_MODE = 'TOGGLE_LIGHT_MODE';
  export const TOGGLE_FAVORITES_FILTER = 'TOGGLE_FAVORITES_FILTER';
  export const GET_NUMBER_OF_DISPLAYS = buildAsyncTypes('GET_NUMBER_OF_DISPLAYS');
  export const TAKE_SCREENSHOT = buildAsyncTypes('TAKE_SCREENSHOT');
  export const SEND_SHARE_EMAIL = buildAsyncTypes('SEND_SHARE_EMAIL');
  export const TOGGLE_SEND_MESSAGE = 'TOGGLE_SEND_MESSAGE';
  export const SEND_MESSAGE = 'SEND_MESSAGE';
  export const CREATE_FILE_API = 'CREATE_FILE_API';
  export const UPDATE_FILE_API = 'UPDATE_FILE_API';
  export const DELETE_FILE_API = 'DELETE_FILE_API';
  export const WHERE_TO_STORE_FILE = 'WHERE_TO_STORE_FILE';
  export const UPDATE_RECORDING_ACCESS_TYPE = 'UPDATE_RECORDING_ACCESS_TYPE';
  export const GET_SHARED_FILE = 'GET_SHARED_FILE';
  export const GET_SHARED_FILES = 'GET_SHARED_FILES';
  export const UNSUBSCRIBE_RECORDING = 'UNSUBSCRIBE_RECORDING';
  export const RESET_ACTIVE_RECORDING = 'RESET_ACTIVE_RECORDING';
  export const UPDATE_SCREENSHOT = 'UPDATE_SCREENSHOT';
  export const SELECT_VISIBLE_RECORDING_OPTION = 'SELECT_VISIBLE_RECORDING_OPTION';
}
