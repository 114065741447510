import * as React from 'react';
import Image from 'app/components/atoms/Image';
import Icon from 'app/components/atoms/Icon';

import styled from 'app/utils/theme';

export interface EmptyViewProps {
  children?: any;
  loading?: boolean;
  image?: string;
  style?: object;
}

const EmptyView = ({ style = {}, children, loading, image }: EmptyViewProps) => (
  <EmptyView.Wrapper>
    <Image style={style} src={image || 'no'} />
    {children}
    {loading && <Icon icon="spinner" spin active size="3x" />}
  </EmptyView.Wrapper>
);

EmptyView.Wrapper = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;

  * {
    z-index: 1;
  }
  > ${Image} {
    position: absolute;
    z-index: 0;
    top: 0%;
    left: 0;
    pointer-events: none;
    background-position: bottom;
    background-size: contain;
    background-color: ${(props) => props.theme.modal.contentColor};
  }

  svg [data-icon='spinner'] {
    font-size: 20em;
    opacity: 0.3;
    position: absolute;
  }
`;

export default EmptyView;
