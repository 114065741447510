import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import Page from 'app/components/pages/Page';
import Text from 'app/components/atoms/Text';
import EmptyView from 'app/components/molecules/EmptyView';
import { getIsOffline } from 'app/redux/reducers/googleSignin';

interface OfflineProps {
  isOffline: Boolean;
}

@connect((state) => ({
  isOffline: getIsOffline(state)
}))
export class OfflinePlage extends Page<OfflineProps> {
  render() {
    if (!this.props.isOffline) {
      return <Redirect to="/" />;
    }

    return (
      <EmptyView>
        <Text xxl active pBottom={10}>
          Ooops! &nbsp;&nbsp;
        </Text>
        <Text l active pBottom={7}>
          No Internet Connection Found
        </Text>
        <Text l active pBottom={20}>
          The app requires internet access to work correctly.
        </Text>
        <Text>&nbsp;</Text>
      </EmptyView>
    );
  }
}
