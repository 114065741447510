/* eslint-disable class-methods-use-this, react/sort-comp */
import React from 'react';
import PropTypes from 'prop-types';
// https://stackoverflow.com/a/19102495/1254597

class WithInfiniteScroll extends React.Component<{ onBottomScroll: Function; offset: number }> {
  static propTypes = {
    onBottomScroll: PropTypes.func.isRequired,
    children: PropTypes.element.isRequired,
    offset: PropTypes.number
  };

  static defaultProps = {
    bottomOffset: 0,
    offset: 100
  };

  componentDidMount() {
    window.addEventListener('scroll', this.onBottomScroll, false);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onBottomScroll, false);
  }

  // taken from http://james.padolsey.com/javascript/get-document-height-cross-browser/
  getDocHeight() {
    return Math.max(
      document.body.scrollHeight,
      document.documentElement.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.offsetHeight,
      document.body.clientHeight,
      document.documentElement.clientHeight
    );
  }

  // below taken from http://www.howtocreate.co.uk/tutorials/javascript/browserwindow
  getScrollXY() {
    let scrOfX = 0;
    let scrOfY = 0;
    if (typeof window.pageYOffset === 'number') {
      // Netscape compliant
      scrOfY = window.pageYOffset;
      scrOfX = window.pageXOffset;
    } else if (document.body && (document.body.scrollLeft || document.body.scrollTop)) {
      // DOM compliant
      scrOfY = document.body.scrollTop;
      scrOfX = document.body.scrollLeft;
    } else if (
      document.documentElement &&
      (document.documentElement.scrollLeft || document.documentElement.scrollTop)
    ) {
      // IE6 standards compliant mode
      scrOfY = document.documentElement.scrollTop;
      scrOfX = document.documentElement.scrollLeft;
    }
    return { scrOfX, scrOfY };
  }
  onBottomScroll = () => {
    const scrollNode = document.scrollingElement || document.documentElement;
    const scrollContainerBottomPosition = Math.round(scrollNode.scrollTop + window.innerHeight);
    const scrollPosition = Math.round(scrollNode.scrollHeight);

    if (scrollPosition <= scrollContainerBottomPosition + this.props.offset) {
      this.props.onBottomScroll();
    }
  };

  render() {
    return (
      <div style={{ position: 'relative', width: '100%', height: '100%' }}>
        {this.props.children}
      </div>
    );
  }
}

export default WithInfiniteScroll;
