import * as React from 'react';
import { Switch } from 'react-router';
import Route from 'app/components/organisms/Route';

import { ListPage } from 'app/components/pages/List';
import { GoogleLoginPage } from 'app/components/pages/Login';
import { OfflinePlage } from 'app/components/pages/Offline';
import { AboutPage } from 'app/components/pages/About';
import { RecordingControls } from 'app/components/pages/RecordingControls';
import RefreshAccessToken from 'app/components/organisms/RefreshAccessToken';

import { hot } from 'react-hot-loader';
import RecordingDetailsPage from './components/pages/RecordingDetailsPage';
import { NoFileAccessPage } from './components/pages/NoFileAccessPage';

// window.addEventListener('unhandledrejection', function(e) {
//   e.stopPropagation();
// });
// window.onerror = function(message, file, line, col, error) {
//   return false;
// };
// window.addEventListener('error', function(e) {
//   e.stopPropagation();
//   return false;
// });

export const App = hot(module)(() => (
  <RefreshAccessToken>
    <Switch>
      <Route path="/details/:id" component={(props: any) => <RecordingDetailsPage {...props} />} />
      <Route path="/recording-controls" component={RecordingControls} isPrivate />
      <Route path="/login" component={GoogleLoginPage} />
      <Route path="/offline" component={OfflinePlage} />
      <Route path="/about" component={AboutPage} />
      <Route path="/no-access" component={NoFileAccessPage} />
      <Route path="/" component={ListPage} isPrivate />
    </Switch>
  </RefreshAccessToken>
));
