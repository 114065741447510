import * as React from 'react';
import { pure } from 'recompose';
import styled, { css } from 'app/utils/theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faMicrophone,
  faMicrophoneSlash,
  faVideo,
  faDownload,
  faShareSquare,
  faTrashAlt,
  faTimesCircle,
  faInfoCircle,
  faPlay,
  faPause,
  faStop,
  faPlayCircle,
  faSort,
  faSortAlphaDown,
  faSortAlphaUp,
  faSortNumericDown,
  faSortNumericUp,
  faSortAmountUp,
  faSortAmountDown,
  faPencilAlt,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronUp,
  faVolumeUp,
  faSpinner,
  faSync,
  faBroadcastTower,
  faRedo,
  faStopCircle,
  faRedoAlt,
  faDesktop,
  faEllipsisV,
  faStar,
  faLightbulb,
  faEye,
  faArrowAltCircleLeft,
  faCamera,
} from '@fortawesome/free-solid-svg-icons';

library.add({
  faMicrophone,
  faMicrophoneSlash,
  faVideo,
  faDownload,
  faShareSquare,
  faTrashAlt,
  faTimesCircle,
  faInfoCircle,
  faPlay,
  faPause,
  faStop,
  faPlayCircle,
  faSort,
  faSortAlphaDown,
  faSortAlphaUp,
  faSortNumericDown,
  faSortNumericUp,
  faSortAmountUp,
  faSortAmountDown,
  faPencilAlt,
  faCheck,
  faCheckCircle,
  faChevronDown,
  faChevronUp,
  faVolumeUp,
  faSpinner,
  faSync,
  faBroadcastTower,
  faRedo,
  faStopCircle,
  faRedoAlt,
  faDesktop,
  faEllipsisV,
  faStar,
  faLightbulb,
  faEye,
  faArrowAltCircleLeft,
  faCamera,
});

const color = (props: IconProps) => css`
  color: ${props.theme.icons.regular};
  ${props.active && `color: ${props.theme.icons.active};`};
  ${props.pasive && `color: ${props.theme.icons.pasive};`};
  ${props.success && `color: ${props.theme.icons.success};`};
  ${props.error && `color: ${props.theme.icons.error};`};
  ${props.orange && `color: ${props.theme.text.colors.orange} !important;`};
`;

const padding = (props: IconProps) => css`
  ${props.pLeft && `padding-left: ${props.pLeft}px`};
  ${props.pRight && `padding-right: ${props.pRight}px`};
  ${props.pTop && `padding-top: ${props.pTop}px`};
  ${props.pBottom && `padding-bottom: ${props.pBottom}px`};
  ${props.p && `padding: ${props.p}px`};
`;

const margin = (props: IconProps) => css`
  ${props.mLeft && `margin-left: ${props.mLeft}px`};
  ${props.mRight && `margin-right: ${props.mRight}px`};
  ${props.mTop && `margin-top: ${props.mTop}px`};
  ${props.mBottom && `margin-bottom: ${props.mBottom}px`};
  ${props.m && `margin: ${props.m}px`};
`;

export const iconTypes = [
  'microphone',
  'microphone-slash',
  'video',
  'download',
  'share-square',
  'trash-alt',
  'times-circle',
  'times-square',
  'info-circle',
  'play',
  'pause',
  'stop',
  'play-circle',
  'sort',
  'sort-alpha-down',
  'sort-alpha-up',
  'sort-numeric-down',
  'sort-numeric-up',
  'sort-amount-up',
  'sort-amount-down',
  'pencil-alt',
  'check',
  'check-circle',
  'chevron-up',
  'chevron-down',
  'volume-up',
  'spinner',
  'sync',
  'broadcast-tower',
  'redo',
  'stop-circle',
  'redo-alt',
  'desktop',
  'ellipsis-v',
  'star',
  'lightbulb',
  'eye',
  'arrow-alt-circle-left',
  'camera'
];

export type IconType =
  | 'microphone'
  | 'microphone-slash'
  | 'video'
  | 'download'
  | 'share-square'
  | 'trash-alt'
  | 'times-circle'
  | 'times-square'
  | 'info-circle'
  | 'play'
  | 'pause'
  | 'stop'
  | 'play-circle'
  | 'sort'
  | 'sort-alpha-down'
  | 'sort-alpha-up'
  | 'sort-numeric-down'
  | 'sort-numeric-up'
  | 'sort-amount-up'
  | 'sort-amount-down'
  | 'pencil-alt'
  | 'check'
  | 'check-circle'
  | 'chevron-up'
  | 'chevron-down'
  | 'volume-up'
  | 'spinner'
  | 'sync'
  | 'broadcast-tower'
  | 'redo'
  | 'stop-circle'
  | 'redo-alt'
  | 'desktop'
  | 'ellipsis-v'
  | 'star'
  | 'lightbulb'
  | 'eye'
  | 'arrow-alt-circle-left'
  | 'screenshot'
  | 'camera';

export interface IconProps {
  //colors
  active?: boolean;
  pasive?: boolean;
  success?: boolean;
  error?: boolean;
  orange?: boolean;

  //
  theme?: any;
  children?: {
    theme: any;
  };
  // padding
  pLeft?: number;
  pRight?: number;
  pTop?: number;
  pBottom?: number;
  p?: number;
  // margin
  mLeft?: number;
  mRight?: number;
  mTop?: number;
  mBottom?: number;
  m?: number;
  //
  icon: IconType;
  //size
  size?: 'xs' | 'sm' | 'lg' | '2x' | '3x' | '4x' | '5x' | '7x' | '10x';
  spin?: boolean;
  pulse?: boolean;
  onClick?: Function;
  action?: boolean;

  //opacity:
  opacity?: any;
  style?: any;
}

const Icon = ({ onClick, ...props }: IconProps) => {
  return <FontAwesomeIcon onClick={onClick as any} {...props} icon={props.icon as any}/>
};

const StyledIcon = styled(pure(Icon))<IconProps>`
  ${color}
  ${padding}
  ${margin}
  ${(props) => props.opacity && `opacity: ${props.opacity}`};

  .fa-20x {
    font-size: 20em;
  }
`;

export default StyledIcon;
