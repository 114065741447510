// import faker from 'faker';

import { AnyAction } from 'redux';
import { StoreModel } from 'app/models/StoreModel';
import { ActionTypes } from 'app/redux/actionTypes/index';
import { get } from 'lodash';

export const STATE_KEY = 'activeRecording';

const initialState = {
  id: '',
  state: StoreModel.ActiveRecordingState.STOPPED,
  name: '',
  time: 0,
  loading: false,
  error: null,
  showTimerModal: false,
  blob: null,
  thumbnail: null,
  toggleEditScreenshot: false,
  size: 0,
};

export const reducer = (state: StoreModel.ActiveRecording = initialState, action: AnyAction) => {
  switch (action.type) {
    case ActionTypes.RESET_ACTIVE_RECORDING:
      return {
        ...initialState,
      };
    case ActionTypes.START_RECORD.STARTED:
      return {
        ...state,
        id: action.payload.params.id,
        error: null,
        name: `${getRecordingName(action)} ${new Date().toLocaleDateString()}`,
        state: StoreModel.ActiveRecordingState.STARTED,
        loading: true,
        time: 0,
      };
    case ActionTypes.TAKE_SCREENSHOT.STARTED:
      return {
        ...state,
        error: null,
        id: action.payload.params.id,
        name: `${getRecordingName(action)} ${new Date().toLocaleDateString()}`,
        state: StoreModel.ActiveRecordingState.STARTED,
        loading: true,
        time: 0,
      };
    case ActionTypes.TAKE_SCREENSHOT.COMPLETED:
      return {
        ...state,
        loading: false,
        state: StoreModel.ActiveRecordingState.STOPPED,
        thumbnail: action.payload.data.snapshot
      };
    case ActionTypes.START_RECORD.COMPLETED:
      return { ...state, loading: false };
    case ActionTypes.PAUSE_RECORD.COMPLETED:
      return { ...state, state: StoreModel.ActiveRecordingState.PAUSED };
    case ActionTypes.STOP_RECORD.COMPLETED:
      return {
        ...state,
        thumbnail: action.payload.data.snapshot,
        state: StoreModel.ActiveRecordingState.STOPPED,
        blob: action.payload.data
      };
    case ActionTypes.CANCEL_RECORD.COMPLETED:
      return { ...state, state: StoreModel.ActiveRecordingState.CANCELED };
    case ActionTypes.RESUME_RECORDING.COMPLETED:
      return { ...state, state: StoreModel.ActiveRecordingState.STARTED };
    case ActionTypes.START_RECORD.FAILED:
      return {
        ...state,
        state: StoreModel.ActiveRecordingState.FAILED,
        error: action.payload.error,
        loading: false
      };
    case ActionTypes.PAUSE_RECORD.FAILED:
      return {
        ...state,
        state: StoreModel.ActiveRecordingState.FAILED,
        error: action.payload.error,
        loading: false
      };
    case ActionTypes.CANCEL_RECORD.FAILED:
      return {
        ...state,
        state: StoreModel.ActiveRecordingState.FAILED,
        error: action.payload.error,
        loading: false
      };
    case ActionTypes.STOP_RECORD.FAILED:
      return {
        ...state,
        state: StoreModel.ActiveRecordingState.FAILED,
        error: action.payload.error,
        loading: false
      };
    case ActionTypes.TOGGLE_TIMER_MODAL:
      return { ...state, showTimerModal: !state.showTimerModal };
    case ActionTypes.UPDATE_ACTIVE_RECORDING:
      return { ...state, ...action.payload };
    case ActionTypes.INCREMENT_RECORDING_TIME:
      // don't increment duration while paused
      if (state.state === StoreModel.ActiveRecordingState.PAUSED) {
        return state;
      }

      return { ...state, time: state.time + 1 };
    case ActionTypes.UPDATE_SCREENSHOT:
      return {
        ...state,
        thumbnail: action.payload.data.snapshot,
        toggleEditScreenshot: action.payload.data.show
      };
    case ActionTypes.TAKE_SCREENSHOT.COMPLETED: {

    }
    default:
      return state;
  }
};

export const isTimerModalVisible = (state: StoreModel) => state[STATE_KEY].showTimerModal;

export const getActiveRecording = (state: StoreModel) => state[STATE_KEY];

export const isRecordingNow = (state: StoreModel) =>
  state[STATE_KEY].state === StoreModel.ActiveRecordingState.STARTED;
function getRecordingName(action: AnyAction) {
  return 'Record'; //get(action, 'payload.params.name', faker.lorem.word());
}

export const getActiveRecordingField = (field: string, state: StoreModel) => get(state[STATE_KEY], field, null);
