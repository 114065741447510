import { AnyAction } from 'redux';
import get from 'lodash/get';
import { StoreModel } from 'app/models/StoreModel';
import { ActionTypes } from 'app/redux/actionTypes/index';
import { VIDEO_MIME, AUDIO_MIME, IMAGE_MIME, RecordActionType } from 'app/config';

export const STATE_KEY = 'recordingSelector';

const initialState = {
  show: false,
  sources: [],
  isLoading: false,
  filter: '',
  settings: {
    showSettingsModal: false,
    sound: true,
    source: null,
    type: '',
    devices: []
  },
  numberOfDisplays: 1,
};

export const reducer = (state: StoreModel.RecordingSelector = initialState, action: AnyAction) => {
  switch (action.type) {
    case ActionTypes.RESET_ACTIVE_RECORDING:
      return {
        ...initialState
      };
    case `${ActionTypes.SELECT_RECORD_TYPE}`:
      return { ...state, show: !!action.payload.data.show };
    case ActionTypes.GET_RECORDING_SOURCES.STARTED:
      return { ...state, isLoading: true };
    case ActionTypes.GET_RECORDING_SOURCES.COMPLETED:
      return { ...state, isLoading: false, sources: action.payload.data };
    case ActionTypes.GET_RECORDING_SOURCES.FAILED:
      return { ...state, isLoading: false, sources: [], error: action.payload.error };
    case ActionTypes.FILTER_RECORDING_SOURCES:
      return { ...state, filter: action.payload.filter };
    case ActionTypes.SET_RECORDING_SETTINGS: {
      return { ...state, settings: { ...state.settings, ...action.payload.settings } };
    }
    case ActionTypes.GET_NUMBER_OF_DISPLAYS.COMPLETED:
      return { ...state, isLoading: false, numberOfDisplays: action.payload.data };
    default:
      return state;
  }
};

export const isSourceSelectorVisible = (state: StoreModel) => state[STATE_KEY].show;
export const getRecordingType = (state: StoreModel) => get(state, `[${STATE_KEY}].settings.type`);
export const getSources = (state: StoreModel) =>
  state[STATE_KEY].sources.filter((source) => {
    return !state[STATE_KEY].filter || source.name.match(new RegExp(state[STATE_KEY].filter, 'ig'));
  });
export const getReccordingSettings = (state: StoreModel) => state[STATE_KEY].settings;
export const isVideoRecording = (state: StoreModel) => get(state[STATE_KEY], 'settings.source.id');
export const isLoadingSources = (state: StoreModel) => state[STATE_KEY].isLoading;
export const getNumberOfDisplays = (state: StoreModel) => state[STATE_KEY].numberOfDisplays;
export const getRecordingMimeType = (state: StoreModel) => {
  const type = get(state[STATE_KEY], 'settings.type');

  if (type === RecordActionType.VIDEO || type === RecordActionType.VIDEOANDWEBCAM || type === RecordActionType.WEBCAM) {
    return VIDEO_MIME;
  }

  if (type === RecordActionType.AUDIO) {
    return AUDIO_MIME;
  }

  if (type === RecordActionType.SCREENSHOT) {
    return IMAGE_MIME;
  }

  return AUDIO_MIME;
};

export const getSettingsField = (field: string, state: StoreModel) => state[STATE_KEY].settings[field];

