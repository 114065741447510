import * as styledComponents from 'styled-components';
import { ThemedStyledComponentsModule } from 'styled-components';

interface ThemeInterface {
  isDarkMode: boolean;
  // backgroundImage: string;
  text: {
    fonts: {
      default: string;
      logo: string;
    };
    colors: {
      active: string;
      pasive: string;
      regular: string;
      success: string;
      [key: string]: string;
    };
  };
  tooltip: {
    backgroundColor: string;
  };
  notification: {
    backgroundColor: string;
    hover: string;
  };
  card: {
    shadow: string;
    backgroundColor: string;
    recording: {
      backgroundColor: string;
    };
  };
  modal: {
    backgroundColor: string;
    contentColor: string;
    border: string;
  };
  topBar: {
    backgroundColor: string;
  };
  search: {
    backgroundColor: string;
    borderColor: string;
  };
  icons: {
    active: string;
    passive: string;
    regular: string;
    success: string;
    error: string;
  };
  dropdown: {
    backgroundColor: string;
    borderColor: string;
  };
  button: {
    active: {
      backgroundColor: string;
      text: string;
    };
    regular: {
      backgroundColor: string;
      text: string;
    };
  };
  topArea: {
    backgroundColor: string;
  };
}

const {
  default: styled,
  css,
  createGlobalStyle,
  keyframes,
  ThemeProvider
} = styledComponents as ThemedStyledComponentsModule<ThemeInterface>;

export { css, createGlobalStyle, keyframes, ThemeProvider };
export default styled;

const colors = {
  darkBlue: '#222E37',
  lightBlue: '#536A7A',
  lightGrey: '#CACACA',
  white: '#FAFAFA',
  darkGrey: '#8A8A8E',
  boxShadow:
    '0px 4px 5px rgba(0, 0, 0, 0.08), 0px 3px 14px rgba(0, 0, 0, 0.05), 0px 8px 10px rgba(0, 0, 0, 0.03);',
  green: 'green',
  darkGrey2: 'rgba(207,207,207, .35)',
  red: 'red',
  orange: '#FF8E3C',
  border: 'rgba(0, 0, 0, 0.1)', // '#EDEDED',
  realWhite: 'white',
  black: '#000000'
};

export const lightTheme: ThemeInterface = {
  isDarkMode: false,
  icons: {
    passive: colors.lightGrey,
    active: colors.black,
    regular: colors.lightGrey,
    success: colors.orange,
    error: colors.red
  },
  text: {
    fonts: { default: 'montserratextralight', logo: 'signpainter_housescriptRg' },
    colors: {
      regular: colors.lightGrey,
      active: colors.black,
      pasive: colors.lightGrey,
      success: colors.green,
      ...colors
    }
  },
  topBar: { backgroundColor: '#2F3136' },
  topArea: { backgroundColor: colors.white },
  tooltip: { backgroundColor: colors.white },
  notification: { backgroundColor: colors.white, hover: '#CACACA' },
  dropdown: { backgroundColor: colors.white, borderColor: colors.border },
  card: {
    shadow: colors.boxShadow,
    backgroundColor: colors.white,
    recording: { backgroundColor: colors.lightGrey }
  },
  modal: {
    backgroundColor: colors.lightGrey,
    contentColor: colors.realWhite,
    border:
      '0px 4px 5px rgba(0, 0, 0, 0.08), 0px 3px 14px rgba(0, 0, 0, 0.05), 0px 8px 10px rgba(0, 0, 0, 0.03)'
  },
  search: { backgroundColor: '#FAFAFA', borderColor: '#CACACA' },
  button: {
    active: { backgroundColor: colors.orange, text: 'white' },
    regular: { backgroundColor: 'white', text: colors.darkGrey }
  }
};

export const darkTheme: ThemeInterface = {
  isDarkMode: true,
  icons: {
    passive: colors.lightGrey,
    active: colors.darkGrey,
    regular: colors.lightGrey,
    success: colors.orange,
    error: colors.red
  },
  text: {
    fonts: { default: 'montserratextralight', logo: 'signpainter_housescriptRg' },
    colors: {
      regular: 'rgba(255, 255, 255, 0.6)',
      active: 'rgba(255, 255, 255, 0.9)',
      pasive: colors.lightGrey,
      success: colors.green,
      ...colors
    }
  },
  topBar: { backgroundColor: '#2F3136' },
  topArea: { backgroundColor: '#202225' },
  tooltip: { backgroundColor: '#4E525B' },
  notification: {
    backgroundColor: 'linear-gradient(180deg, #32353A 0%, #292B2F 100%)',
    hover: '#CACACA'
  },
  dropdown: { backgroundColor: '#4E525B', borderColor: '#616468' },
  card: {
    shadow:
      '0px 4px 5px rgba(0, 0, 0, 0.1), 0px 3px 14px rgba(0, 0, 0, 0.12), 0px 8px 10px rgba(0, 0, 0, 0.06);',
    backgroundColor: '#3E4148',
    recording: { backgroundColor: '#36393F' }
  },
  modal: {
    backgroundColor:  'rgba(0, 0, 0, 0.3)',
    contentColor: '#36393F',
    border:
      '0px 4px 5px rgba(0, 0, 0, 0.08), 0px 3px 14px rgba(0, 0, 0, 0.05), 0px 8px 10px rgba(0, 0, 0, 0.03)'
  },
  search: { backgroundColor: '#2D3035', borderColor: 'rgba(255, 255, 255, 0.1)' },
  button: {
    active: { backgroundColor: colors.orange, text: 'white' },
    regular: { backgroundColor: 'white', text: colors.darkGrey }
  }
};
