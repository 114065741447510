import { AnyAction } from 'redux';
import { StoreModel } from 'app/models/StoreModel';
import { ActionTypes } from 'app/redux/actionTypes/index';
import uuid from 'uuid/v1';
import get from 'lodash/get';

export const STATE_KEY = 'googleSignIn';

const initialState = {
  isAuthenticated: false,
  isLoading: false,
  redirectUri: document.location.origin,
  isOffline: false,
  profile: null,
  clientId: uuid(), // should be set only once per install,
  accessToken: null,
  apiToken: null,
  showSendMessageModal: false
};

export const reducer = (state: StoreModel.GoogleSignIn = initialState, action: AnyAction) => {
  switch (action.type) {
    case `${ActionTypes.GOOGLE_SIGN_OUT}`:
      return {
        ...initialState,
        isAuthenticated: false,
        accessToken: null,
        clientId: state.clientId || uuid()
      };
    case `${ActionTypes.GOOGLE_SIGN_IN}`:
      return { ...state, isLoading: true, isAuthenticated: false };
    case `${ActionTypes.GOOGLE_SIGN_IN}_SUCCESS`:
      return {
        ...state,
        isLoading: false,
        isAuthenticated: true,
        ...action.payload.data
      };
    case `${ActionTypes.GOOGLE_SIGN_IN}_FAIL`:
      const newUserSignInFailure = get(action, 'error.response.data.newUserSignInFailure');
      return {
        ...state,
        isLoading: false,
        isAuthenticated: false,
        error: action.error,
        newUserSignInFailure,
      };
    case ActionTypes.REFRESH_ACCESS_TOKEN:
      return { ...state, isLoading: true };
    case `${ActionTypes.REFRESH_ACCESS_TOKEN}_SUCCESS`:
      return { ...state, ...action.payload.data, isAuthenticated: true, isLoading: false };
    case `${ActionTypes.REFRESH_ACCESS_TOKEN}_FAIL`:
      return {
        ...state,
        error: action.error,
        isAuthenticated: false,
        isLoading: false,
        accessToken: null,
        apiToken: null
      };
    case ActionTypes.GET_LOCAL_SERVER_PORT.COMPLETED:
      return { ...state, redirectUri: action.payload.data };
    case ActionTypes.TOGGLE_OFFLINE_MODE:
      return { ...state, isOffline: action.payload.data };
    case `${ActionTypes.GET_USER_PROFILE}_SUCCESS`:
      return { ...state, profile: action.payload.data };
    case ActionTypes.TOGGLE_SEND_MESSAGE:
      return { ...state, showSendMessageModal: !state.showSendMessageModal }
    default:
      return state;
  }
};

export const isAuthenticated = (state: StoreModel) => state[STATE_KEY].isAuthenticated;
export const getAuthToken = (state: StoreModel) => state[STATE_KEY].accessToken;
export const getApiToken = (state: StoreModel) => state[STATE_KEY].apiToken;
export const getUserInfo = (state: StoreModel) => state[STATE_KEY];
export const getNewUserSignInFailure = (state: StoreModel): string =>
         state[STATE_KEY].newUserSignInFailure;
export const getIsOffline = (state: StoreModel) => false; // state[STATE_KEY].isOffline; - disable for now
export const getUserProfile = (state: StoreModel) => state[STATE_KEY].profile;
export const getClientId = (state: StoreModel) => state[STATE_KEY].clientId;
export const isShowSendMessageModal = (state: StoreModel) => state[STATE_KEY].showSendMessageModal;
export const isAuthLoading = (state: StoreModel) => state[STATE_KEY].isLoading;
export const getStateProp = (state: StoreModel, key: string) => state[STATE_KEY][key];
