import { isWebBuild } from 'app/config';
import { StoreModel } from 'app/models/StoreModel';

/**
 * on web builds we mock the desktopCapturer
 */
const desktopCapturer = !isWebBuild
  ? // needs to by window.require to avoid conflict with browserify require
    // https://github.com/electron/electron/issues/7300
    (<any>window).require('electron').desktopCapturer
  : {
      getSources: navigator.mediaDevices.enumerateDevices, // (options: any, cb: Function) => cb(Promise.reject('is web build'))
    };

export const getRecordingSources = async (): Promise<any> => {
  return desktopCapturer
    .getSources({
      types: ['screen', 'window', 'audio'],
      thumbnailSize: {
        width: 800,
        height: 800 * (9 / 16)
      }
    })
    .then((sources: any) => {
      const result: Array<StoreModel.RecordingSource> = sources.map((source: any) => ({
        id: source.id,
        name: source.name,
        thumbnail: source.thumbnail.toDataURL()
      }));

      return result;
    });
};
