import { isWebBuild } from "app/config";

const ipcRenderer = !isWebBuild
  ? // needs to by window.require to avoid conflict with browserify require
    // https://github.com/electron/electron/issues/7300
    (<any>window).require('electron').ipcRenderer
  : {
      send: () => Promise.reject('is web build')
    };

export const ipcSendEvent = ({ eventName, params }: any) => ipcRenderer.send(eventName, params);
