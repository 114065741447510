import { combineReducers } from 'redux';
import { reducer as toastrReducer } from 'react-redux-toastr';

import * as googleSignin from './googleSignin';
import * as recordings from './recordings';
import * as recordingSelector from './recordingSelector';
import * as activeRecording from './activeRecording';
import * as uploads from './uploading';
import * as thumbnails from './thumbnails';

import { StoreModel } from 'app/models/StoreModel';

export default combineReducers<StoreModel>({
  [googleSignin.STATE_KEY]: googleSignin.reducer,
  [recordings.STATE_KEY]: recordings.reducer,
  [recordingSelector.STATE_KEY]: recordingSelector.reducer,
  [activeRecording.STATE_KEY]: activeRecording.reducer,
  [uploads.STATE_KEY]: uploads.reducer,
  [thumbnails.STATE_KEY]: thumbnails.reducer,
  toastr: toastrReducer
});
