import { GIF_MIME } from "app/config";

const GIFEncoder = require('../../plugins/GIFEncoder').default;
const encode64 = require('../../plugins/b64').default;

export default async function getGiFfromBlob(blob: Blob, duration: number) {
  const video = document.createElement('video') as HTMLVideoElement;
  const canvas = document.createElement('canvas');

  // document.body.appendChild(video);
  // document.body.appendChild(canvas);

  console.time('CreateVideoBlobTime');
  video.src = URL.createObjectURL(blob);
  video.muted = true;
  console.timeEnd('CreateVideoBlobTime');

  console.time('LoadVideoMetaDataTime');
  await new Promise((res) => video.addEventListener('loadedmetadata', res));
  console.timeEnd('LoadVideoMetaDataTime');

  const width = 600; // * (window.innerWidth/window.innerHeight);
  const height = 400;
  canvas.width = width;
  canvas.height = height;

  console.time('CreateEncoderAndPlayVideo');
  const encoder = new GIFEncoder(width, height);

  encoder.setRepeat(0);
  encoder.setDelay(200);
  encoder.setQuality(20);
  encoder.start();

  video.play();
  console.timeEnd('CreateEncoderAndPlayVideo');

  console.time('WaitingForVideoToStartPlaying');
  await new Promise((res: Function) =>
    video.addEventListener('play', () => {
      video.pause();
      res();
    })
  );
  console.timeEnd('WaitingForVideoToStartPlaying');

  console.time('SleepingFor1Second');
  await new Promise((res: Function) =>
    setTimeout(() => {
      console.log('slept for one second');
      res();
    }, 1000)
  );
  console.timeEnd('SleepingFor1Second');

  console.time('GeneratingGifFrames');
  const GIF_FRAME_CADENCE = 0.2;
  const GIF_TIME_FRAME = 5;
  // take snapshots for n-times once at m-seconds
  const maxGifDuration = Math.min(duration, GIF_TIME_FRAME);
  for (let time = 0; time <= maxGifDuration; time += GIF_FRAME_CADENCE) {
    video.currentTime = video.currentTime + time;
    (canvas.getContext('2d') as any).drawImage(video, 0, 0, width, height);
    await new Promise((res: Function) =>
      window.requestAnimationFrame(() => {
        res();
      })
    );
    encoder.addFrame(canvas.getContext('2d'));
  }
  console.timeEnd('GeneratingGifFrames');

  console.time('ExtractingGifFromStream');
  // assemble snapshots into .gif
  var readableStream = encoder.stream();
  var binary_gif = readableStream.getData();
  var b64Str = 'data:' + GIF_MIME + ';base64,' + encode64(binary_gif);

  // cleanup
  video.removeAttribute('src');

  const blobGif = await fetch(b64Str).then((res) => res.blob());
  console.timeEnd('ExtractingGifFromStream');

  return blobGif;
}
