import moment from "moment";

const formatSeparator = (number: String, addSeparator: Boolean) => {
  return addSeparator ? `${number}:` : number;
};
const formatNumber = (number: Number, addSeparator: Boolean = true) => {
  if (!number) {
    return formatSeparator('00', addSeparator);
  }

  if (number < 10) {
    return formatSeparator(`0${number}`, addSeparator);
  }

  return formatSeparator(number.toString(), addSeparator);
};
export const formatDuration = (durationInSeconds: any) => {
  const duration = moment.duration(durationInSeconds, 'seconds');
  const hours = formatSeparator(duration.hours().toString(), true);
  const minutes = formatNumber(duration.minutes());
  const seconds = formatNumber(duration.seconds(), false);

  return `${hours}${minutes}${seconds}`;
};


export const formatSize = (bytes: any, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};